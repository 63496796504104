import React from "react";
import { FlexboxGrid, Form, Input, Modal,Button,DatePicker,Popover,Dropdown,ButtonGroup,IconButton,Whisper, SelectPicker } from "rsuite";
import ArrowDownIcon from '@rsuite/icons/ArrowDown';
import { Schema } from 'rsuite';
import { AlertHelper } from "../../../Helpers/AlertHelper";
import Apis from "../../../Helpers/Apis";
import { AuthorizationHeader } from "../../../manager/AuthorizationHeader";
import { DoEventIfHavingPrivilge } from "../../../manager/IsHavingPrivilege";
import ToWaitingModal from "./ToWaitingModal";
const dateSelector = React.forwardRef((props, ref) => <DatePicker size="md" format="dd-MM-yyyy"    {...props}  ref={ref}  block />);
const timeSelector = React.forwardRef((props, ref) => <DatePicker size="md" format="hh:mm aa"   {...props}  ref={ref}  block />);
const textArea = React.forwardRef((props, ref) => <Input size="md" as="textarea"  style={{height:"165px"}}  {...props}  ref={ref}  block />);
const dataSelector = React.forwardRef((props, ref) => <SelectPicker  size="md" placeholder="Type Offer" valueKey="id" labelKey="name" {...props}  ref={ref}  block />);
const { StringType, NumberType,DateType } = Schema.Types;
const model = Schema.Model({
    date: DateType().isRequired("Date is required"),
    time: DateType().isRequired("Time is required"),
    description : StringType().isRequired("Description is Required"),
    typeOfferId : StringType().isRequired("Type Offer is Required")
});

const ToUpdateModal =(props) =>
{

    const btn =
    {
         backgroundColor : "#F0E4E6",
         color :"#DD0E2D",
         fontWeight : "bold"
    }
    const [date,setDate] = React.useState(null);
    const [time,setTime] = React.useState(null);
    const [description,setDescription] = React.useState("");
    const [typeOfferId,setTypeOfferId] = React.useState();
    const [allTypes,setAllTypes] = React.useState([]);
    const [isLoading,setIsLoading] = React.useState(false);
    const [isFinished,setIsFinished] = React.useState(false);
    const [IsWaitingModalVisible,setIsWaitingModalVisible] = React.useState(false);
    const formRef = React.useRef();
    const formValue = {
        date : date,
        time : time,
        description : description,
        typeOfferId : typeOfferId
    }
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        color :"#89919B",
        backgroundColor : "#D2D2D2",
        fontWeight : "bold"
    }
    const onOpen =()=>
    {
        fetch(Apis.LIST_TYPE_OFFERS,{headers:AuthorizationHeader()})
        .then(response=>{if(!response.ok) throw new Error(response.status); return response.json()})
        .then(data=>setAllTypes(data))
        .catch(err=>{
            if(err.toString().indexOf("403")!==-1)
            AlertHelper.ShowErrour("You must have the privilege of List Types of Offers to update this content");
            else
            AlertHelper.ShowErrour("Something went wrong");
        })
        setIsFinished(false);
        setDate(null);
        setTime(null);
        setTypeOfferId(null);
        setDescription("");
        setIsLoading(false);
        setIsFinished(true);
    }
    const onClose=(isValidated)=>
    {
        setIsFinished(true);
        if(isValidated===true)
            props.onSave();
        else
            props.onCloseModal(isValidated);
    }
    const onSave=()=>
    {
        if(!formRef.current.check())
            return ;
        setIsLoading(true);
        var data =
        {
            appointmentId : props.id,
            date : date.toExactDate(),
            time : time.toExactDate(),
            description : description,
            typeOfferId : typeOfferId
        };
        fetch(Apis.UPDATE_APPOINTMENT_TO_UPDATED,
        {
            headers: AuthorizationHeader(),
            method : "PUT",
            body : JSON.stringify(data)
        })
        .then(response=>{if(!response.ok) throw new Error("err"); return response})
        .then(data=>onClose(true))
        .catch(err=>{AlertHelper.ShowErrour("Something went wrong");setIsLoading(false)});
    }
    const toWaiting=()=>
    {
        setIsWaitingModalVisible(true);
    }
    const onCloseWaiting=(isValidated)=>
    {
        setIsWaitingModalVisible(false);
        if(isValidated===true)
        {
            onClose(true);
        }
    }
    const renderMenu = ({ onClose, left, top, className }, ref) => {
        const handleSelect = eventKey => {
          onClose();
          console.log(eventKey);
        };
        return (
          <Popover ref={ref} className={className} style={{ left, top }} full>
            <Dropdown.Menu >
              <Dropdown.Item onClick={()=>props.onCloseModal()} eventKey={1}>Close</Dropdown.Item>
              <Dropdown.Item onClick={e=>DoEventIfHavingPrivilge("APPOINTMENT_ADD",()=>{toWaiting()},true)} eventKey={2}>Switch to Waiting</Dropdown.Item>
            </Dropdown.Menu>
          </Popover>
        );
      };
    return (<>
        <Modal open={props.isUpdateModalVisible} onEntered={onOpen} backdrop="static" onClose={e=>onClose(false)} className="custom-modal" >
            <Modal.Header>
                <h5>Update an Offer</h5>
            </Modal.Header>
            <Modal.Body>
                <Form fluid model={model} formValue ={formValue} ref={formRef}>
                    <Form.Group controlId="typeOfferId">
                                <Form.ControlLabel>Type Offer</Form.ControlLabel>
                                    <Form.Control name="typeOfferId" accepter={dataSelector} data={allTypes} value={typeOfferId} onChange={setTypeOfferId} block/>
                                </Form.Group>
                    <Form.Group>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={15}>
                                <Form.Group controlId="date">
                                    <Form.ControlLabel>Date</Form.ControlLabel>
                                    <Form.Control name="date" accepter={dateSelector} value={date} onChange={setDate} block/>
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={1}> </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={8}>
                                <Form.Group controlId="time">
                                    <Form.ControlLabel>Time</Form.ControlLabel>
                                    <Form.Control name="time" accepter={timeSelector} value={time} onChange={setTime} block/>
                                </Form.Group>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel controlId="description">Description</Form.ControlLabel>
                        <Form.Control name="description" accepter={textArea} value = {description} placeholder="Description" onChange={setDescription} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={7} className="to-left">
                                        <ButtonGroup  size="md" style={cancelButton}>
                                        <Button style={btn}>Options</Button>
                                        <Whisper style={btn} placement="bottomStart" trigger="click" disabled={isLoading} speaker={renderMenu}>
                                            <IconButton style={btn} icon={<ArrowDownIcon />} />
                                        </Whisper>
                                    </ButtonGroup>
                                        {/* <Button appearance="default" size="md" style={cancelButton} disabled={isLoading} onClick={e=>onClose(false)}>CANCEL</Button>     */}
                                        </FlexboxGrid.Item>
                                        
                  
                                        <FlexboxGrid.Item colspan={17}>
                                        <Button color="red" appearance="primary" block size="md" style={saveButton} onClick={onSave} loading={isLoading}>
                                            UPDATE
                                        </Button>   
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Footer>
        </Modal>
        <ToWaitingModal IsWaitingModalVisible={IsWaitingModalVisible} id = {props.id} onClose={onCloseWaiting}/>
        </>
    )
}
export default ToUpdateModal;