import { error } from 'jquery';
import React, { useEffect } from 'react';
import Apis from '../../Helpers/Apis';
import { AuthorizationHeader } from '../../manager/AuthorizationHeader';
import './PrintPreviewInvoice.css'
export default class PrintPreviewInvoice extends React.Component
{
    constructor(props){
        super(props);
        this.state={
            appointment : {},
            invoice : {},
            invoiceDetails : [],
            products : [],
            hasError : false
        };
        
    }
    componentDidMount()
    {
        document.getElementsByClassName("side-bar")[0].style.display="none";
        document.getElementsByClassName("header-div")[0].style.display="none";
        this.renderAppointment();
    }
    componentDidUpdate()
    {
        if(this.state.invoiceDetails.length>0&&this.state.products.length>0)
            window.print();
    }
    renderInvoice=()=>
    {
        fetch(Apis.FIND_INVOICE+"?id="+this.state.appointment.invoiceId,{headers:AuthorizationHeader()})
        .then(responseInvoice=>{if(!responseInvoice.ok) throw new Error(1);return responseInvoice.json()})
        .then(dataInvoice=>{
                    this.setState({
                        invoice : dataInvoice,
                        invoiceDetails : dataInvoice.details
                    },this.renderProducts)
        })
        .catch(err=>alert("ss"));
    }
    renderAppointment=()=>
    {
        fetch(Apis.FIND_APPOINTMENT_VIEWMODEL+"?id="+this.props.match.params.id,{headers:AuthorizationHeader()})
        .then(response=>{if(!response.ok) throw new Error(0);return response.json()})
        .then(data=> {this.setState({appointment : data},this.renderInvoice)})
        .catch(err=>{
            if(err===0)
                alert("something went wrong");
            else
            alert("invoice not found");
                this.setState({hasError : true});            
        })
    }
    renderProducts=()=>
    {
        fetch(Apis.LIST_PRODUCTS,{headers:AuthorizationHeader()})
        .then(response=>{if(!response.ok) throw new Error(0);return response.json()})
        .then(data=> {this.setState({products : data})})
        .catch(err=>{
            if(err===0)
                alert("something went wrong");
            else
            alert("invoice not found");
                this.setState({hasError : true});            
        })
    }
    readProductName=(productId)=>
    {
        try
        {
            return this.state.products.filter(m=>m.id===productId)[0].name;
        }
        catch
        {
            return "";
        }
    }
    fillEmptySpace=()=>
    {
        const rowsCount = 28;
        const total = this.state.invoiceDetails.length;
        const numberOfPages =parseInt((total / rowsCount) +1);
        
        const newTotal = rowsCount * numberOfPages;
        const empty=[];
        for(var i = 0;i<newTotal-total;i++)
        {
            empty.push({n:total+i+1});
        }
            
            
        return (<>
            {empty.map(m=>
            <tr key={m.n}>
                    <td>{m.n}</td>
                    <td className="pd"></td>
                    <td className="tc qte"></td>
                    <td className="tc qte"></td>
                    <td className="tc qte gr"></td>
                    <td className="tc qte" style={{fontWeight:"bold"}}></td>
                    <td></td>
                </tr>)}
            </>       )
    
    }
    
render(){
    return(
        <div className="pg">
        <div className="hd">
            <table>
                <thead>
                <tr>
                    <td className='lgo'>
                        <img src="invoiceLogo.png" />
                    </td>
                    <td>
                        <h1 className="op">OPERATION N °: </h1>
                    </td>
                    <td style={{borderBottom: "1px solide black"}}>
                        <h3 className="dts"> .............</h3>
                    </td>
                </tr>
            </thead>
             
            </table>
            
            
        </div>
        <table className="table-invoice-header">
            <thead>
            <tr>
                <td className="wd">
                    N° Bon :
                </td>
                <td className="cnt txt">
                    <p className="und ">
                        {this.state.appointment.bonSortie}
                    </p>
                </td>
                <td className="wd">
                    Date :
                </td>
                <td className="txt">
                    {new Date(this.state.invoice.date).dateToInput()}
                </td>
            </tr>
            </thead>
        </table>
        <table className="table-invoice-header">
            <thead>
            <tr>
                <td className="wd">
                    Client :
                </td>
                <td className="cnt txt ">
                    <p className='to-ellipsis' style={{width:"500px"}}>
                    {this.state.appointment.clientName}
                    </p>
                </td>
                <td className="wd">
                    Heure : 
                </td>
                <td className='txt'>
                {new Date(this.state.invoice.date).timeToInput()}
                </td>
            </tr>
            </thead>
        </table>
        <table className="table-invoice-header">
            <thead>
            <tr>
                <td className="wd">
                    Agent :
                </td>
                <td className="cnt txt">
                    {this.state.appointment.agent}
                </td>
                <td className="wd">
                    Resp :
                </td>
                <td className='txt'>
                    {this.state.appointment.responsable}
                </td>
            </tr>
            </thead>
        </table>
        <table className="table-invoice-details">
            <thead>
                <tr>
                    <td className="tc">N°</td>
                    <td className="pd">Désignation</td>
                    <td><p className="big">Qt</p><p className="small">sortie</p></td>
                    <td><p className="big">Qt</p><p className="small">Entrée</p></td>
                    <td className='gr'><p className="big">Qt</p><p className="small">Consomé</p></td>
                    {/* <td><p className="big">Qt</p><p className="small">Validé</p></td> */}
                    <td className="pd">Observation</td>
                </tr>
            </thead>
            <tbody>
                {this.state.invoiceDetails.map((detail,index)=>
                <tr key={detail.id}>
                    <td>{index+1}</td>
                    <td className="pd">{this.readProductName(detail.productId)}</td>
                    <td className="tc qte">{detail.qteSortie}</td>
                    <td className="tc qte">{this.state.appointment.isInvoiceValidated?detail.qteSortie-detail.qteConsomee:""}</td>
                    <td className="tc qte gr">{this.state.appointment.isInvoiceValidated?detail.qteConsomee:""}</td>
                    {/* <td className="tc qte"  style={{fontWeight:"bold"}}>{this.state.appointment.isInvoiceValidated?detail.qteValide:""}</td> */}
                    <td style={{paddingLeft :"5px",width:"350px"}}>{detail.observation}</td>
                </tr>
             )}
             {/* {this.fillEmptySpace()} */}
                
            </tbody>
        </table>
        <table className='table-invoice-footer'>
            <thead>
                <tr>
                    <td style={{width:"15%"}}>
                        Verificateur :
                    </td>
                    <td className='txt' style={{width:"45%"}}>
                        {this.state.appointment.verificateur}
                    </td>
                    <td style={{width:"10%",textAlign:"center"}} >
                        Heure :
                    </td>
                    <td style={{width:"15%"}} className='txt'>
                        {this.state.appointment.dateFinish===null?"": new Date(this.state.appointment.dateFinish).dateToInput() +" - "+ new Date(this.state.appointment.dateFinish).timeToInput()}
                    </td>
                </tr>
            </thead>
        </table>
    </div>
    )
}
}
