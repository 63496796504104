import React from "react";
import { IconButton } from "rsuite";
import { Button } from "rsuite";
import './AddButton.css'
const AddButton =(props)=>
{
    
    return (
  
      <Button color="blue" appearance="primary" className="gradian add-button" onClick={props.onButtonClick} block>
      {/* <img src="alert.svg" />+ ADD */}+ ADD
        </Button>
    )
}
export default AddButton;