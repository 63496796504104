export class ExpandHelper
{
    static Read()
    {
        try
        {
            return localStorage.getItem("expand");
        }
        catch
        {
            return false;
        }
    }
    static Write(value)
    {
        localStorage.setItem("expand",value);
    }
}