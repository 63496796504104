import { Row } from 'rsuite';
import { Col } from 'rsuite';
import { Button } from 'rsuite';
import { Checkbox } from 'rsuite';
import React from 'react';
import './TableHeader.css';
const UserTableHeader=(props)=>
{
  const onSelectAll= (value,e)=>{
    props.onSelectAll(e);
  }
    return (
      <Row className="user-table-header rounded">
        <Col xs={1}><Checkbox onChange={onSelectAll}  /></Col> 
        <Col xs={14}>User Name</Col>
        <Col xs={9}>Default Store</Col>
        {/* <Col xs={4}>Email</Col>
        <Col xs={4}>Phone</Col>
        <Col xs={4}>Default Store</Col>
        <Col xs={7}>Adresse</Col> */}
      </Row>
    )
}
export default UserTableHeader;