import './Sidebar/SidebarDiv.css';
import './Header/HeaderDiv.css'
import './Content/ContentDiv.css'
import React,{ Component } from "react";
import { Container, Header, Content, Footer, Sidebar, Sidenav } from 'rsuite';
import  SidebarDiv  from './Sidebar/SidebarDiv';
import { HeaderDiv } from './Header/HeaderDiv';
export class TechLayout extends Component
{
    static displayName = TechLayout.name;
 
    render()
    {
        return(
            <div className="show-container">
                <Container>
                    <Sidebar style={{flex : "none"}} className="side-bar" >
                        <Sidenav expanded={true}>
                        <SidebarDiv />
                        </Sidenav>
                    </Sidebar>
                    <Container style={{width:"1px"}}>
                        <Header className="header-div">
                            <HeaderDiv />
                        </Header>
                        <Content className='content-div'>
                            {this.props.children}
                        </Content>
                        {/* <Footer style={{width:"100%",height:"15px",background : "red"}}>Footer</Footer> */}
                    </Container>
                </Container>
            </div>
        )
    }
}