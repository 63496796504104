import React,{ Component } from "react";
import { Col, Row } from "rsuite";
import AddButton from "../Common/AddButton";
import GroupTools from '../Common/GroupTool';
const ToolBar =(props)=>
{
 
    return (
        <Row className="show-grid">
        <Col xs={21}>
        <GroupTools canPrint={props.canPrint} canDelete={props.canDelete} onUpdateClick = {props.onUpdateClick} onDeleteClick ={props.onDeleteClick} onPrintClick={props.onPrintClick} onRefrechClick = {props.onRefrechClick}/>
        </Col>
        <Col xs={3} className="to-right" >
        <AddButton onButtonClick ={props.onCreateClick}/>
        </Col>
    </Row>
    )
}
export default ToolBar;