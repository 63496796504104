import React,{ Component } from "react";
import { Redirect, Route } from "react-router-dom"
import { IsHavingPrivilge } from "../../manager/IsHavingPrivilege";
import { IsUserLoggedIn } from "../../manager/IsUserLoggedIn";
export default class PrivilegeRoute extends Component
{   constructor(props)
    {
        super(props);
    }
    componentDidMount(){
        
    }
    render()
    {
        let Content;
        if(IsUserLoggedIn()===true)
        {
            if(IsHavingPrivilge("USERS_ADD"))
               Content =  <Route path={this.props.path} component={this.props.component} />
            else Content  = (<Redirect to="/Login" />)
        }
        else Content = (<Redirect to="/Login" />)
        return (<>{Content}</>)
    }
}