import React from "react";
import { Modal,Form,FlexboxGrid,Button, SelectPicker, InputNumber,Loader } from "rsuite";
import {AuthorizationHeader} from '../../../manager/AuthorizationHeader';
import Apis from "../../../Helpers/Apis";
import { Schema } from 'rsuite';
import { AlertHelper } from "../../../Helpers/AlertHelper";

const inputNumber = React.forwardRef((props, ref) => <InputNumber style={{width:"100%"}} min={0} max={10}  {...props}  ref={ref}  block />);

const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  name: StringType().isRequired('This field is required.'),
  color : StringType().isRequired('This field is required.'),
});

const CreateUpdateTypeOfferModal =(props)=>
{
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        color :"#89919B",
        backgroundColor : "#D2D2D2",
        fontWeight : "bold"
    }
    const [name,setName] = React.useState("");
    const [color,setColor] = React.useState("");
    const [isLoading,setIsLoading] = React.useState(false);
    const [isFinished,setIsFinished] = React.useState(false);
    const formRef = React.useRef();
    const formValue = 
    {
        name : name,
        color : color,
    }
    const initiaNewTypeOffer =()=>
    {
        setName("");
        setColor("");
    }
    const findSelectedTypeOffer=()=>
    {
        fetch(Apis.FIND_TYPE_OFFERS+"?id="+props.id,{headers:AuthorizationHeader()})
        .then(response=>response.json())
        .then(data=>{
            setName(data.name);
            setColor(data.color);
        });
    }
    const onModalOpen=()=>
    {
        setIsFinished(false);
        setIsLoading(false);
        if(props.id===0)
        {
            initiaNewTypeOffer();
        }
        else
        {
            findSelectedTypeOffer();
        }
        setIsFinished(true);
    }
    const colors = [
        {id:"red",label:"Red"},
        {id:"blue",label:"Blue"},
        {id:"green",label:"Green"},
        {id:"orange",label:"orange"},
        {id:"yellow",label:"yellow"},
        {id:"silver",label:"silber"},
        {id:"black",label:"black"},
    ]
    const onSaveTypeOffer=()=>
    {
        if(!formRef.current.check())
            return;
        setIsLoading(true);
        const api = props.id===0? Apis.CREATE_TYPE_OFFERS : Apis.UPDATE_TYPE_OFFERS;
        const method = props.id===0?"POST" : "PUT";
        const data = JSON.stringify({
            id:props.id,
            name,
            color
        })
        fetch(api,{
            method:method,
            headers : AuthorizationHeader(),
            body:data
        })
        .then(response=>{if(!response.ok) throw new Error(response) ;return response;})
        .then(data =>{
            props.onSave();
        })
        .catch(err=>{AlertHelper.ShowErrour("Something went wrong");setIsLoading(false)});
    }
    const onClose=()=>
    {
        setIsFinished(false);
        props.onCloseModal();
    }
    return(
        <Modal open={props.isModalOpen} onEntered={onModalOpen} onClose={onClose} size="xs" backdrop="static" className="custom-modal">
            <Modal.Header>
                <h5>{props.id===0?"Add a type Offer": "Update Type Offer"}</h5>
            </Modal.Header>
            <Modal.Body>
            {isFinished===false?(<div style={{ textAlign: 'center' }}><Loader size="md" /></div>) : (  
                <Form fluid ref={formRef} formValue={formValue} model={model}>
                            <Form.Group controlId="name" block>
                            <Form.ControlLabel>Name</Form.ControlLabel>
                                        <Form.Control name="name" size="md" placeholder="Name" value={name} onChange={e=>setName(e)} block autoComplete="off" />
                            </Form.Group>
                            <Form.Group controlId="color" block>
                                
                            <Form.ControlLabel>Color</Form.ControlLabel>
                            <SelectPicker  data={colors} valueKey="id" size="md" lableKey="label" cleanable={false} placeholder="Color" value={color} onChange={e=>setColor(e)} block />
                            </Form.Group>
                </Form>
            )}
            </Modal.Body>
            <Modal.Footer>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={11} className="to-right">
                                        <Button appearance="default" size="md" block style={cancelButton} disabled={isLoading} onClick={onClose}>BACK</Button>    
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={1} > 

                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={12}>
                                        <Button color="red" appearance="primary" block size="md" style={saveButton} loading={isLoading} onClick={onSaveTypeOffer}>
                                            {props.id===0?"ADD":"SAVE"}
                                        </Button>   
                </FlexboxGrid.Item>
            </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    )
}
export default CreateUpdateTypeOfferModal;