import React from "react";
import { Checkbox, Row } from "rsuite";
import { Col } from "rsuite";
import { Button } from "rsuite";
import './TableDetails.css'
const TableDetail =(props)=>
{   
    const onChange=(value,e)=>{
        props.onSelectionChanged(value,e);
    }
    const noPadding = {
        paddingRight:0,
        paddingLeft:0
    }
    const readProductName=(id)=>
    {
        try
        {
            return props.allProducts.filter(m=>m.id===id)[0].name;
        }
        catch
        {
            return "";
        }
    }
    return (
        <div className="invoice-detail-table-container">
                        {props.details.map(detail=>
                <Row className="invoice-detail-table-detail rounded" style={noPadding}>
                    {/* <Col xs={1} className="invoice-detail-table-detail-checker"><Checkbox name="ch1" value={detail.id} checked={detail.checked}  onChange={onChange} /></Col> */}
                    <Col xs={9}>{readProductName(detail.productId)}</Col>
                    <Col xs={5}>{detail.qteSortie}</Col>
                    <Col xs={5}>{detail.price}</Col>
                    <Col xs={5}><a href="#" onClick={(e)=>props.onUpdateProduct(e,detail)}>Edit</a> | <a  href="#" onClick={(e)=>props.onDeleteProduct(e,detail.productId)}>Delete</a></Col>
                </Row>
            )}
        </div>
    )
}
export default TableDetail;