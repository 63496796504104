import React, { useState } from 'react';
import {Button, FlexboxGrid, Modal, Sidenav} from 'rsuite';
import FEmployee from '../Employees/FEmployee';
import { Nav } from 'rsuite';
import { Container } from 'rsuite';
import './SettingsModal.css';
import FetchSections from '../Stores/FetchSections';
import FetchScores from '../Scores/FetchScores';
import FetchUsers from '../Users/FetchUsers';
import FetchFonctions from '../Fonctions/FetchFonctions';
import FetchProducts from '../Products/FetchProducts';
import { IsAccessDenied } from '../../manager/IsAccessDenied';
import FetchTypeOffers from '../TypeOffers/FetchTypeOffers';
import FetchTypeAppointments from '../TypeOffersAppointments/FetchTypeAppointment';
const SettingsModal =(props)=>
{
    const [component,setComponenet] = useState(IsAccessDenied("EMPLOYEE_LIST",<FEmployee />))
    const onButtonClick=(e)=>
    {
        if(e.target.id==="em")
            setComponenet(IsAccessDenied("EMPLOYEE_LIST",<FEmployee />));
        else if(e.target.id==="st")
            setComponenet(IsAccessDenied("STORE_LIST",<FetchSections />));
        else if(e.target.id==="sc")
            setComponenet(IsAccessDenied("SCORE_LIST",<FetchScores />));
        else if(e.target.id==="us")
            setComponenet(IsAccessDenied("USERS_LIST",<FetchUsers />));
        else if(e.target.id==="rl")
            setComponenet(IsAccessDenied("FONCTION_LIST",<FetchFonctions />)); 
        else if(e.target.id==="pr")
            setComponenet(IsAccessDenied("PRODUCT_LIST",<FetchProducts/>))
        else if(e.target.id==="of")
            setComponenet(IsAccessDenied("TYPE_OFFER_LIST",<FetchTypeOffers/>))
        else if(e.target.id==="af")
            setComponenet(IsAccessDenied("TYPE_APPOINTMENT_LIST",<FetchTypeAppointments/>))
    }
    return (
        <Modal open={props.isSettingsOpen} full backdrop="static" onClose={props.onClose}  >
            <Modal.Header>Settings</Modal.Header>
            <Modal.Body style={{maxHeight:"60%",height : "60%"}} >
            <FlexboxGrid >
                <FlexboxGrid.Item colspan={3}>
                    <Button color="red" appearance="subtle" block className="button-with-image blured" id="pr" name="bnav" onClick={onButtonClick}>
                        <img src="clients.svg"/>
                        Products
                    </Button>
                    <Button color="red" appearance="subtle" block className="button-with-image blured" id="em" name="bnav" onClick={onButtonClick}>
                        <img src="clients.svg"/>
                        Employees
                    </Button>
                    <Button color="red" appearance="subtle" block className="button-with-image blured" id="st" name="bnav" onClick={onButtonClick}>
                        <img src="clients.svg"/>
                        Stores
                    </Button>
                    <Button color="red" appearance="subtle" block className="button-with-image blured" id="sc" name="bnav" onClick={onButtonClick}>
                        <img src="clients.svg"/>
                        Score Settings
                    </Button>
                    <Button color="red" appearance="subtle" block className="button-with-image blured" id="rl" name="bnav" onClick={onButtonClick}>
                        <img src="clients.svg"/>
                        Roles
                    </Button>
                    <Button color="red" appearance="subtle" block className="button-with-image blured" id="af" name="bnav" onClick={onButtonClick}>
                        <img src="clients.svg"/>
                        Appointment's Types
                    </Button>
                    <Button color="red" appearance="subtle" block className="button-with-image blured" id="of" name="bnav" onClick={onButtonClick}>
                        <img src="clients.svg"/>
                        Offer's Types
                    </Button>
                    <Button color="red" appearance="subtle" block className="button-with-image blured" id="us" name="bnav" onClick={onButtonClick}>
                        <img src="clients.svg"/>
                        Users
                    </Button>
                    <div style={{height:"200px",width:"100%"}}></div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={1}>

                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={20}>
                    <div className='setting-Container'>
                        {component}
                        </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            </Modal.Body>
            <Modal.Footer>
                <Button color='red' appearance='primary' size="md" onClick={props.onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}
export default SettingsModal;