import { Form, Modal,FlexboxGrid,Button,Input } from "rsuite";
import React, { useState } from "react";
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
const ConfirmCancelation =(props)=>
{
    const [reason,setReason] = useState("");
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        color :"#89919B",
        backgroundColor : "#D2D2D2",
        fontWeight : "bold"
    }
    const note={
        height:150
    }
    const header=
    {
        width:"100%",
        textAlign : "center"
    }
    const icon =
    {
        width :"30px",
        height :"30px",
        marginLeft : "260px",
        marginBottom : "15px",
        textAlign : "center"
    }
    const onCancel =(e)=>
    {
        onClose(true,reason);
    }
    const onOpen=()=>
    {
        setReason("");
    }
    const onClose=(isValidated,cancelReason)=>
    {
        props.onCloseModal(isValidated,cancelReason);
    }
    return(
        <Modal open={props.IsCancelationModalOpen} className="custom-modal" onClose={onClose} onOpen={onOpen} backdrop="static">
            <Modal.Header>
            </Modal.Header>
            <Modal.Body>
                <Form fluid>
                    <CloseOutlineIcon  style={icon} color="green" spin />
                <h5 style={header}>Cancel The Appointment?</h5>
                <h5 style={header}>Leave here a note about the reason?</h5>
                <br/>
                    <Form.Group controlId="time-1">
                    <Input size="lg" style={note} as="textarea" value={reason} onChange={e=>setReason(e)} placeholder="Note" />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={11} className="to-right">
                                    <Button appearance="default" size="lg" block style={cancelButton} onClick={onClose}>BACK</Button>    
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={2} > 

                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={11}>
                                    <Button color="red" appearance="primary" block size="lg" style={saveButton} onClick={onCancel}>
                                        CANCEL
                                    </Button>   
                </FlexboxGrid.Item>
            </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    )
}
export default ConfirmCancelation;