import { Col, Row,Button ,IconButton, SelectPicker} from "rsuite"
import React from "react";
import ArrowLeftIcon from '@rsuite/icons/ArrowLeft';
import './CalendarNavigation.css';
const CalendarNavigation =(props)=>
{
    const center={
        paddingTop:"5px",
        textAlign : "center",
        fontSize :"16px"
    }
    const [store,setStore] = React.useState(-1);
    const getCurrentDate =()=>
    {
        var current = props.date;
        var next = new Date(current);
        next.setDate(next.getDate()+5);
        if(current.getMonth()===next.getMonth())
        {
            return current.toMonthName() + " " + current.getDate() + " - " + next.getDate();
        }
        else
        {
            return current.toMonthName() + " " + current.getDate() + " - " + next.toMonthName() + " " + next.getDate();
        }
    }
    return(
        <Row>
            <Col xs={8}>
                <Button className="day-nav-button day-left-nav-button" color="white" appearance="subtle" onClick={props.previous}>
                    <img src="left-arrow.svg" />
                    Previous
                </Button> 
            </Col>
            <Col xs={8} className="to-center dzd">
                <SelectPicker size="sm"  value={props.storeId} onChange={props.onStoreChanged} placeholder="All Sotres" style={{width:"150px"}} data={props.stores} valueKey="id" labelKey="name" />
                <span className="day-nav-button"  style={center}>{getCurrentDate()}</span>
            </Col>
            <Col xs={8} className="to-right">
                <Button className="day-nav-button day-right-nav-button" color="white" appearance="subtle" onClick={props.next}>
                    Next
                    <img src="right-arrow.svg" />
                </Button> 
            </Col>
        </Row>
    )
}
export default CalendarNavigation