import './HeaderDiv.css';
import React,{ Component } from "react";
import FlexboxGrid from 'rsuite/FlexboxGrid';
import Input from 'rsuite/Input';
import InputGroup from 'rsuite/InputGroup';
import MaskedInput from 'rsuite/MaskedInput';
import IconButton from 'rsuite/IconButton';
import ButtonToolbar from 'rsuite/ButtonToolbar';
import SearchIcon from '@rsuite/icons/Search';
import { Badge } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import PageIcon from '@rsuite/icons/Page';
import FolderFillIcon from '@rsuite/icons/FolderFill';
import DetailIcon from '@rsuite/icons/Detail';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import { Dropdown } from 'rsuite';
import {useHistory} from 'react-router-dom';
import Apis from '../../../Helpers/Apis';
import { AuthorizationHeader } from '../../../manager/AuthorizationHeader';
import { DoEventIfHavingPrivilge } from '../../../manager/IsHavingPrivilege';
import { IsUserLoggedIn } from '../../../manager/IsUserLoggedIn';
import BackupModal from "../../Maintenances/BackupModal";
import RestoreModal from "../../Maintenances/RestoreModal";
import { IsHavingPrivilge } from '../../../manager/IsHavingPrivilege';
import UserInfoIcon from '@rsuite/icons/UserInfo';
import ArowBackIcon from '@rsuite/icons/ArowBack';
import CodeIcon from '@rsuite/icons/Code';
import { AlertHelper } from '../../../Helpers/AlertHelper';
export class HeaderDiv extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
            userInfo: {},
            IsBackupModalVisible : false,
            IsRestoreModalVisible : false,
        }
    }
    componentDidMount()
    {
        if(IsUserLoggedIn())
            this.fetchUserInfo();
    }
    fetchUserInfo=()=>
    {
        fetch(Apis.GET_CURRENT_USSER_INFO,{headers:AuthorizationHeader()})
        .then(response=>{if(!response.ok) throw new Error("Err"); return response.json()})
        .then(data=>this.setState({userInfo : data}))
        .catch(err=>alert(err));
    }
    onCloseModals=()=>
    {
        this.setState({
            IsBackupModalVisible:false,  
            IsRestoreModalVisible : false,
        })
    }
    onOpenBackupModal =()=>
    {
        this.setState({
            IsBackupModalVisible:true,  
        })
    }
    onOpenRestoreModal =()=>
    {
        this.setState({
            IsRestoreModalVisible:true,  
        })
    }
     renderUserButton = (props, ref) => {
        return (
          <IconButton className="header-side-button" {...props} ref={ref} icon={<img src="user.svg"/>} circle  appearance="subtle" />
        );
      };
     renderNotifButton = (props, ref) => {
        return (
          <IconButton className="header-side-button" {...props} ref={ref} icon={<img src="alert.svg"/>} circle  appearance="subtle" />
        );
      };
      logout=()=>
      {
          localStorage.removeItem("token");
          localStorage.removeItem("claims");
          window.location.reload();
      }
      renderBackupButton=()=>
      {
          if(IsHavingPrivilge("BACKUP"))
            return (<Dropdown.Item icon={<PageIcon />} onClick={this.onOpenBackupModal}>Backup your data</Dropdown.Item>)
      }
      renderRestoreButton=()=>
      {
          if(IsHavingPrivilge("RESTORE"))
            return (<Dropdown.Item icon={<PageIcon />} onClick={this.onOpenRestoreModal}>Restore Data</Dropdown.Item>)
      }
      goToUpdatePassword=()=>
      {
          var a = document.createElement("a");
          a.href="/updatePassword";
          a.click();
      }
      updateDatabase=()=>
      {
          fetch(Apis.UPDATE_DATABASE,{headers : AuthorizationHeader(),method:"POST"})
          .then(response=>{if(!response.ok) throw new Error; return response})
          .then(data=>AlertHelper.ShowSuccess("Database updated Successfully"))
          .catch(err=>AlertHelper.ShowErrour("Something went wrong"))
      }
      exportToCsv=()=>
      {
            var a = document.createElement('a');
            fetch(Apis.EXPORT_CSV,
            {
                headers:AuthorizationHeader(),
                method:"POST"
            })
            .then(response=>{if(!response.ok) throw new Error("Error while exporting to csv, please try again");return response.blob()})
            .then(blob=>{
                const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = "Appointments.csv";
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
            })
            .catch(err=>{
                AlertHelper.ShowErrour(err);
            })
      }
    render()
    {
        return(
            <div className="show-grid">
                <div className="header-body">
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={10}>
                        <h2 className='page-title' id='title'></h2>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={10}>
                        <InputGroup inside size="lg" className='home-search' >
                            <InputGroup.Addon>
                                <SearchIcon/>
                            </InputGroup.Addon>
                            <Input placeholder="Search by client's name..." size="lg" className='gray-control' id="searchbox" />
                        </InputGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={1}></FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={3} className="to-right">
                    {/* <Badge color="red"> */}
                    <Dropdown renderToggle={this.renderNotifButton} placement="bottomEnd">

                    </Dropdown>
                    {/* </Badge> */}
                    <Dropdown renderToggle={this.renderUserButton} placement="bottomEnd">
                        <Dropdown.Item icon={<UserInfoIcon />}>{this.state.userInfo.name}</Dropdown.Item>
                        <Dropdown.Item divider />
                        <Dropdown.Item icon={<CodeIcon />} onClick={this.goToUpdatePassword}>Update Password</Dropdown.Item>
                        {/* {this.renderBackupButton()}
                        {this.renderRestoreButton()} */}
                        <Dropdown.Item divider />
                        <Dropdown.Item icon={<ArowBackIcon />} onClick={this.updateDatabase}>Update Database</Dropdown.Item>
                        <Dropdown.Item divider />
                        <Dropdown.Item icon={<ArowBackIcon />} onClick={this.exportToCsv}>Export Data to CSV</Dropdown.Item>
                        <Dropdown.Item divider />
                        <Dropdown.Item icon={<ArowBackIcon />} onClick={this.logout}>Log out</Dropdown.Item>
                    </Dropdown>
                    </FlexboxGrid.Item> 
                </FlexboxGrid>
                </div>
                <BackupModal IsBackupModalVisible={this.state.IsBackupModalVisible} onClose={this.onCloseModals} />
                <RestoreModal IsRestoreModalVisible={this.state.IsRestoreModalVisible} onClose={this.onCloseModals}/>
            </div>
        )
    }
}