import React,{ Component } from "react";

export class TechHeader extends Component
{
    constructor(props)
    {
        super(props);
    }
    render()
    {
        return (
            <>
            <div className="row">
                <div className="col">
                    <h2 className="nav-header-title">Appointments</h2>
                </div>
                <div className="col">
                    <input type="text" className="form-control gray-control borderless" placeholder="Search"/>
                </div>
                <div className="col">
                    <button className="btn btn-light">
                        <img src="alert.svg"/>
                    </button>
                    <button className="btn btn-light">
                        <img src="user.svg"/>
                    </button>
                </div>
            </div>
            </>
        )
    }
}