import { Row } from 'rsuite';
import { Col } from 'rsuite';
import { Button } from 'rsuite';
import { Checkbox } from 'rsuite';
import React from 'react';
import './TableHeader.css';
const TableHeader=(props)=>
{
  const onSelectAll= (value,e)=>{
    props.onSelectAll(e);
  }
    return (
      <Row className="invoice-table-header rounded">
        {/* <Col xs={1}><Checkbox onChange={onSelectAll}  /></Col>  */}
        <Col xs={9}>Product</Col>
        <Col xs={5}>Qte Sortie</Col>
        <Col xs={5}>Price</Col>
        <Col xs={5}></Col>
      </Row>
    )
}
export default TableHeader;