import React, { useState } from 'react';
import {Modal, Timeline, Loader, FlexboxGrid} from 'rsuite';
import { AlertHelper } from '../../../Helpers/AlertHelper';
import Apis from '../../../Helpers/Apis';
import { AuthorizationHeader } from '../../../manager/AuthorizationHeader';
import WaitIcon from '@rsuite/icons/Wait';
import CheckIcon from '@rsuite/icons/Check';
import SpeakerIcon from '@rsuite/icons/Speaker';
import CloseIcon from '@rsuite/icons/Close';
import PeoplesCostomizeIcon from '@rsuite/icons/PeoplesCostomize';
import '../../Common/customTimeLine.css'
import CreateUpdateInvoiceModal from '../../Invoices/Modals/CreateUpdateInvoiceModal';
const TimeLineModal =(props)=>
{
    const [appointment,setAppointment] = useState({});
    const [isFinished,setIsFinished] = useState(false);
    const [reports,setReports] = useState([]);
    const [updates,setUpdates] = useState([]);
    const [isAdvanced,setIsAdvanced] = useState(true);
    const invoiceRef = React.useRef();
    const onOpen=()=>
    {
        
        setIsFinished(false);
        setIsFinished(false);
        setAppointment({});
        fetch(Apis.FIND_APPOINTMENT_VIEWMODEL+"?id="+props.id,{headers:AuthorizationHeader()})
        .then(response=>{if(!response.ok) throw new Error("err"); return response.json()})
        .then(data=>{
            setAppointment(data);
            setReports(data.reports);
            setUpdates(data.updates);
        })
        .catch(err=>AlertHelper.ShowErrour("Something went wrong"));
        setIsFinished(true);
    }
    const onClose = ()=>
    {
        setIsFinished(false);
        setAppointment({});
        setReports([]);
        setUpdates([]);
        props.onClose();
    }
    const isOffer=(advenced)=>
    {
        if(appointment.startedAs==="5")
        {
            return (<Timeline.Item dot={<PeoplesCostomizeIcon />}>
                            <p>[Offer]</p>
                            <table>
                                <tbod>
                                    {/* <tr>
                                        <td>Date :</td>
                                        <td>{new Date(appointment.dateOffer).dateToInput()} - {new Date(appointment.dateOffer).timeToInput()}</td>
                                    </tr> */}
                                    <tr>
                                        <td>Client's Name :</td>
                                        <td>{appointment.clientName}</td>
                                    </tr>
                                    <tr>
                                        <td>Client's Phone :</td>
                                        <td>{appointment.clientPhone}</td>
                                    </tr>
                                    <tr>
                                        <td>Client's Adresse :</td>
                                        <td>{appointment.clientAdresse}</td>
                                    </tr>
                                    <tr>
                                        <td>Store :</td>
                                        <td>{appointment.sectionName}</td>
                                    </tr>
                                    <tr>
                                        <td>Type :</td>
                                        <td>{appointment.name}</td>
                                    </tr>
                                    <tr>
                                        <td>description :</td>
                                        <td>{appointment.description}</td>
                                    </tr>
                                    <tr>
                                        <td>Priority :</td>
                                        <td>{appointment.priority}</td>
                                    </tr>
                                    <tr>
                                        <td>Date Created :</td>
                                        <td>{new Date(appointment.dateCreated).dateToInput() + "-" + new Date(appointment.dateCreated).timeToInput()}</td>
                                    </tr>
                                    <tr>
                                        <td>Created By :</td>
                                        <td>{appointment.offeredBy}</td>
                                    </tr>
                                    <tr>
                                        <td>Updated By :</td>
                                        <td>{appointment.updatedBy==="" ? "/" : appointment.updatedBy}</td>
                                    </tr>
                                    <tr>
                                        <td>Date Updated :</td>
                                        <td>{appointment.dateUpdated===null? "/" : new Date(appointment.dateUpdated).dateToInput() + "-" + new Date(appointment.dateUpdated).timeToInput()} </td>
                                    </tr>
                                </tbod>
                            </table>
            </Timeline.Item>)
        }
        else
        return (<></>)
    }
    const isWaiting=(advenced)=>
    {
       
            if(appointment.startedAs==="5")
            {
                if(appointment.dateWatiting!==null&&appointment.dateWatiting!==undefined)
                {
                    return (
                        <Timeline.Item dot={<WaitIcon />}>
                                    <p>[Waiting]</p>
                                    <table>
                                        <tbod>
                                            <tr>
                                                <td>Date :</td>
                                                <td>{new Date(appointment.date).dateToInput()} - {new Date(appointment.date).timeToInput()}</td>
                                            </tr>
                                            <tr>
                                                <td>Date Created :</td>
                                                <td>{new Date(appointment.dateCreated).dateToInput() + "-" + new Date(appointment.dateCreated).timeToInput()}</td>
                                            </tr>
                                            <tr>
                                                <td>Created by :</td>
                                                <td>{appointment.waitedBy}</td>
                                            </tr>
                                        </tbod>
                                    </table>
                    </Timeline.Item>
                    )
                }
            }
            else
            { 
                if(appointment.dateWatiting!==null&&appointment.dateWatiting!==undefined)
                {
                return (
                    <Timeline.Item dot={<WaitIcon />}>
                                    <p>[Waiting]</p>
                                    <table>
                                        <tbod>
                                            <tr>
                                                <td>Date :</td>
                                                <td>{new Date(appointment.date).dateToInput()} - {new Date(appointment.date).timeToInput()}</td>
                                            </tr>
                                            <tr>
                                                <td>Client's Name :</td>
                                                <td>{appointment.clientName}</td>
                                            </tr>
                                            <tr>
                                                <td>Client's Phone :</td>
                                                <td>{appointment.clientPhone}</td>
                                            </tr>
                                            <tr>
                                                <td>Client's Adresse :</td>
                                                <td>{appointment.clientAdresse}</td>
                                            </tr>
                                            <tr>
                                                <td>Store :</td>
                                                <td>{appointment.sectionName}</td>
                                            </tr>
                                            <tr>
                                                <td>Type :</td>
                                                <td>{appointment.typeAppointmentName}</td>
                                            </tr>
                                            <tr>
                                                <td>description :</td>
                                                <td>{appointment.description}</td>
                                            </tr>
                                            <tr>
                                                <td>Priority :</td>
                                                <td>{appointment.priority}</td>
                                            </tr>
                                            <tr>
                                                <td>Date Created :</td>
                                                <td>{new Date(appointment.dateWatiting).dateToInput() + "-" + new Date(appointment.dateWatiting).timeToInput()}</td>
                                            </tr>
                                            <tr>
                                                <td>Created By :</td>
                                                <td>{appointment.createdBy}</td>
                                            </tr>
                                            <tr>
                                                <td>Updated By :</td>
                                                <td>{appointment.updatedBy===""? "/" : appointment.updatedBy}</td>
                                            </tr>
                                            <tr>
                                                <td>Date Updated :</td>
                                                <td>{appointment.dateUpdated===null? "/" : new Date(appointment.dateUpdated).dateToInput() + "-" + new Date(appointment.dateUpdated).timeToInput()} </td>
                                            </tr>
                                        </tbod>
                                    </table>
                    </Timeline.Item>
                )
            }
        }
        
    }
    const renderInvoiceHref=(bonSortie,appointmentId)=>
    {
        const href = Apis.SERVER+"/printInvoice/"+appointmentId;
        return (<><a href={href} onClick={(e)=>{e.preventDefault();window.open(href, '_blank').focus();}} >{bonSortie} - Click here to open Bon</a>{appointment.invoiceId===null?"":<a href='#' onClick={(e)=>{e.preventDefault();invoiceRef.current.initDataSource(appointment.invoiceId)}}> | Update</a>}</>)
    }
    const ifProgress=(advenced)=>
    {
        if(appointment.dateProgress!==null&&appointment.dateProgress!==undefined)
        {
            return (
                <Timeline.Item dot={<SpeakerIcon/>}>
                        <p>[In Progress]</p>
                        <table>
                            <tbod>
                                <tr>
                                    <td>Date :</td>
                                    <td>{new Date(appointment.dateProgress).dateToInput()} - {new Date(appointment.dateProgress).timeToInput()}</td>
                                </tr>
                                <tr>
                                    <td>Agent :</td>
                                    <td>{appointment.agent}</td>
                                </tr>
                                <tr>
                                    <td>Responsable :</td>
                                    <td>{appointment.responsable}</td>
                                </tr>
                                <tr>
                                    <td>N° Bon Sortie:</td>
                                    <td>{appointment.invoiceId===null?"None":renderInvoiceHref(appointment.bonSortie,appointment.id)}</td>
                                </tr>
                                <tr>
                                    <td>N° Bon Décalage:</td>
                                    <td>{appointment.invoiceId===null?"None":appointment.nBonDécalageInvoice}</td>
                                </tr>
                                <tr>
                                    <td>Observation Bon Décalage:</td>
                                    <td>{appointment.invoiceId===null?"None":appointment.observationInvoice}</td>
                                </tr>
                                <tr>
                                    <td>Progressed By :</td>
                                    <td>{appointment.progressedBy}</td>
                                </tr>

                            </tbod>
                        </table>
                </Timeline.Item>
            )
        }
    }
    const ifFinish=(advenced)=>
    {
        
        if(appointment.dateFinish!==null&&appointment.dateFinish!==undefined)
        {
            return (
                <Timeline.Item dot={<CheckIcon style={{ background: '#15b215', color: '#fff' }} />}>
                        <p>[Solved]</p>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Date :</td>
                                    <td>{new Date(appointment.dateFinish).dateToInput()} - {new Date(appointment.dateFinish).timeToInput()}</td>
                                </tr>
                                <tr>
                                    <td>Score :</td>
                                    <td>{appointment.score}</td>
                                </tr>
                                <tr>
                                    <td>Client Call Date :</td>
                                    <td>{new Date(appointment.clientCallDate).dateToInput()} - {new Date(appointment.clientCallDate).timeToInput()}</td>
                                </tr>
                                <tr>
                                    <td>Client Mark :</td>
                                    <td>{appointment.clientNote}</td>
                                </tr>
                                <tr>
                                    <td>Solved By :</td>
                                    <td>{appointment.solvedBy}</td>
                                </tr>
                            </tbody>
                        </table>
                </Timeline.Item>
            )
        }
    }
    const isCanceled =(advenced)=>
    {
        if(appointment.dateCancel!==null&&appointment.dateCancel!==undefined)
        {
            return (
                <Timeline.Item dot={<CloseIcon style={{ background: '#ff6868', color: '#fff' }} />}>
                        <p>[Canceled]</p>
                        <table>
                            <tr>
                                <td>Date :</td>
                                <td>{new Date(appointment.dateCancel).dateToInput()} - {new Date(appointment.dateCancel).timeToInput()}</td>
                            </tr>
                            <tr>
                                <td>Reason :</td>
                                <td>{appointment.cancelReason}</td>
                            </tr>
                            <tr>
                                <td>Canceled By :</td>
                                <td>{appointment.canceledBy}</td>
                            </tr>
                        </table>
                </Timeline.Item>
            )
        }
    }
    const isReported =(advenced)=>
    {
        return (
        <>
        {reports.map(report=>
            <Timeline.Item >
                <p>[Reported]</p>
                <table>
                    <tr>
                        <td>Date : </td>
                        <td>{new Date(report.date).dateToInput()} - {new Date(report.time).timeToInput()}</td>
                    </tr>
                    <tr>
                        <td>Note : </td>
                        <td>{report.note}</td>
                    </tr>
                </table>
            </Timeline.Item>
        )}
        </>)
    }
    const isUpdated =(advenced)=>
    {
        return (
        <>
        {updates.map(update=>
            <Timeline.Item >
                <p>[{update.typeOfferName}]</p>
                <table>
                    <tr>
                        <td>Date : </td>
                        <td>{new Date(update.date).dateToInput()} - {new Date(update.time).timeToInput()}</td>
                    </tr>
                    <tr>
                        <td>Description : </td>
                        <td>{update.description}</td>
                    </tr>
                    <tr>
                        <td>Created by : </td>
                        <td>{update.createdBy}</td>
                    </tr>
                </table>
            </Timeline.Item>
        )}
        </>)
    }
    const normalHistory=(isAdvanced)=>
    {
        return(   
            <>
            {isOffer(isAdvanced)}
            {isUpdated(isAdvanced)}
            {isWaiting(isAdvanced)}
            {isReported(isAdvanced)}
            {ifProgress(isAdvanced)}
            {ifFinish(isAdvanced)}
            {isCanceled(isAdvanced)}
            </>
        )
    }
    const onInvoiceUpdated=async(id)=>
    {
        // var fetchInvoices = await fetch(Apis.LIST_INVOICES,{headers:AuthorizationHeader()});
        // var jsonInvoices = await fetchInvoices.json();
        // setInvoices(jsonInvoices.filter(m=>m.isValidated===false));
        // setInvoiceId(id);
    }
    return (<>
        <Modal onEntered={onOpen} open={props.IsTimeLineModalVisible} onClose={onClose} size="sm">
            <Modal.Header>
                <h5>About this Appointment</h5>
            </Modal.Header>
            <Modal.Body>
            {isFinished===false?(<div style={{ textAlign: 'center' }}><Loader size="md" /></div>) : (              
                <Timeline className="custom-timeline">
                    {normalHistory(isAdvanced)}
                </Timeline>
            )}
            </Modal.Body>
        </Modal>
        <CreateUpdateInvoiceModal ref={invoiceRef} onInvoiceUpdated={onInvoiceUpdated} />
        </>
    )
}
export default TimeLineModal;