import { Row } from 'rsuite';
import { Col } from 'rsuite';
import { Button } from 'rsuite';
import { Checkbox } from 'rsuite';
import React from 'react';
import './SolvedTableHeader.css';
const SolvedTableHeader=(props)=>
{
  const onSelectAll= (value,e)=>{
    props.onSelectAll(e);
  }
    return (
      <Row className="solved-table-header rounded">
                    <Col xs={1}><Checkbox onChange={onSelectAll}  /></Col> 
                    <Col xs={1}></Col>
                    <Col xs={2} className="to-ellipsis">Status</Col>
                    <Col xs={1}></Col>
                    <Col xs={3} className="to-ellipsis">Group</Col>
                    <Col xs={3} className="to-ellipsis">Store</Col>
                    <Col xs={3} className="to-ellipsis">Client's Name</Col>
                    <Col xs={3} className="to-ellipsis">Client's Phone</Col>
                    <Col xs={4} className="to-ellipsis">Calling date</Col>
                    <Col xs={3} className="to-ellipsis">Client's Note</Col>
      </Row>
    )
}
export default SolvedTableHeader;