import React,{ Component } from "react";
import { Button, Col, FlexboxGrid, Grid, Input, Row } from "rsuite";
import DeleteConfirmation from "../Common/DeleteConfirmation";
import ClientDetail from "./ClientDetail";
import ClientHistory from "./ClientHistory";
import CreateUpdateClientModal from "./Modals/CreateUpdateClientModal";
import { AuthorizationHeader } from "../../manager/AuthorizationHeader";
import { DoEventIfHavingPrivilge } from "../../manager/IsHavingPrivilege";
import Apis from "../../Helpers/Apis";
import './ClientDetail.css';
export  class FClients extends Component
{
    constructor(props)
    {
        super(props);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.state={
            word:"",
            isDeleteModalVisible:false,
            isLoading : false,
            isUpdateCreateClientModalOpen : false,
            clients : [],
            clientId:0,
            appointments : []
        }
    }
    componentDidMount()
    {
        document.getElementsByClassName("side-bar")[0].style.display="initial";
        document.getElementsByClassName("header-div")[0].style.display="initial";
        document.getElementById("title").innerText = "clients";
        document.getElementById("title").style.color = "#DD0E2D";
        this.fetchClients();
    }
    onCreateClient=()=>
    {
        DoEventIfHavingPrivilge("CLIENT_ADD",()=>{
        this.setState({
            clientId : 0,
            isUpdateCreateClientModalOpen : true})},true);
    }
    onSelectionChanged=(value,e)=>
    {

            const all = this.state.clients;
            all.forEach(m=>{
                if(m.id!==value)
                    m.checked=false;
                else
                    m.checked=e;
            });
            this.setState({clients:all,clientId:e===true?all.filter(m=>m.checked===true)[0].id:0},this.fetchAppointmentsByClient);
    }
    fetchAppointmentsByClient()
    {
        if(this.state.clients.filter(m=>m.checked===true).length===1)
        {
        fetch(Apis.FIND_APPOINTMENTs_BY_CLIENT+"?clientId="+this.state.clients.filter(m=>m.checked===true)[0].id,{headers:AuthorizationHeader()})
        .then(response=>response.json())
        .then(data=>{
            this.setState({appointments:data})    
        });
        }
        else
        {
            this.setState({appointments:[]})
        }
    }
    onUpdateClient =(e)=>
    {
        DoEventIfHavingPrivilge("CLIENT_EDIT",()=>{
        this.setState({
            clientId:e.target.getAttribute("data-item"),
            isUpdateCreateClientModalOpen:true})},true); 
    }
    onCloseCreateUpdateModal=()=>
    {
        this.setState({isUpdateCreateClientModalOpen : false});
    }
    async fetchClients(word)
    {
        if(word==null)
            word = "";
        await fetch(Apis.LIST_CLIENTS+"?word="+this.state.word,{headers:AuthorizationHeader()})
        .then(response => response.json())
        .then(data=>{data.forEach(m=>{m.checked=false});this.setState({clients:data,isLoading:false})});
    }
    onSaveClient=()=>
    {
        this.setState({isUpdateCreateClientModalOpen:false},this.fetchClients);

    }

    onShowDeleteModal=(e)=>
    {
        DoEventIfHavingPrivilge("CLIENT_DELETE",()=>{
        fetch(Apis.FIND_CLEINT+"?id="+e.target.getAttribute("data-item"),{headers:AuthorizationHeader()})
        .then(response=>response.json())
        .then(data=>{
            this.setState({modalClient:data,isDeleteModalVisible:true})    
        })},true);
    }
    onDelete=()=>
    {
        fetch(Apis.DELETE_CLIENT_ONE+"?id="+this.state.modalClient.id,{method:"DELETE",headers:AuthorizationHeader()})
        .then(response=>response)
        .then(data=>{
            this.setState({isDeleteModalVisible:false},this.fetchClients)    
        });
    }
    onDeleteModalClose=()=>
    {
        this.setState({isDeleteModalVisible:false}) 
    }
    onSearchChange=(e)=>
    {
        this.setState({word:e},this.fetchClients);
    }

    render()
    {
        return(
        <>
        <Row>
            <Col xs={10}>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={18}>
                        <Input placeholder="Search" className="gray-control borderless" value={this.state.word} onChange={this.onSearchChange}></Input>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={1}></FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={5}>
                        <Button  className="gradian" appearance="default" block onClick={this.onCreateClient}>ADD</Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <div className="client-details-container">
                {
                    this.state.clients.map(client=>
                    <ClientDetail onUpdateClient={this.onUpdateClient} onDeleteClient={this.onShowDeleteModal} onSelectionChanged = {this.onSelectionChanged} client={client} />
                    
                )}
                </div>
            </Col>
            <Col xs={14}>
                <h5>History</h5>
                <ClientHistory id={this.state.clientId} appointments={this.state.appointments} />
            </Col>
        </Row>
        <CreateUpdateClientModal id={this.state.clientId} onCloseModal={this.onCloseCreateUpdateModal} onSaveClient={this.onSaveClient} isUpdateCreateClientModalOpen={this.state.isUpdateCreateClientModalOpen}  />
        <DeleteConfirmation isDeleteModalVisible={this.state.isDeleteModalVisible} title="Client" onClose={this.onDeleteModalClose} onDelete ={this.onDelete}/>
        </>
        )
    }
}