import React, { useImperativeHandle } from 'react';
import {Modal,FlexboxGrid,Button, CheckTree,Loader} from 'rsuite';
import { AlertHelper } from '../../../Helpers/AlertHelper';
import Apis from '../../../Helpers/Apis';
import { AuthorizationHeader } from '../../../manager/AuthorizationHeader';
const UpdateUserPrivileges=React.forwardRef((props,ref)=>{

    useImperativeHandle(ref,()=>({
        initDataSource : initDataSource
    }))
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        color :"#89919B",
        backgroundColor : "#D2D2D2",
        fontWeight : "bold"
    }
    const [isFinished,setIsFinished] = React.useState(false);
    const [allPrivilges,setAllPrivileges] = React.useState([]);
    const [userPrivilges,setUserPrivileges] = React.useState([]);
    const [expand,setExpand] = React.useState(true);
    const [expanded,setExpanded] = React.useState([]);
    const [id,setId] = React.useState("");
    const [isOpen,setIsOpen] = React.useState(false);
    const initDataSource=async(userId)=>
    {
        setId(userId);
        setIsFinished(false);
        setExpand(false);
        fetch(Apis.LIST_PRIVILEGES,{headers : AuthorizationHeader()})
        .then(response=>response.json())
        .then(data=>{setAllPrivileges(data);setExpanded(data)});
        setIsOpen(true);
        setExpand(true);
    }
    const onOpen=()=>
    {
        

        fetch(Apis.GET_USER_CLAIMS+"?id="+id,{headers : AuthorizationHeader()})
        .then(response=>response.json())
        .then(data=>setUserPrivileges(data));
        setIsFinished(true);
    }
    const onSave=()=>
    {
        var data=
        {
            userId : id,
            claims : userPrivilges
        };
        fetch(Apis.COMMIT_CLAIMS,{
            headers : AuthorizationHeader(),
            method : "POST",
            body : JSON.stringify(data)
        })
        .then(response=>{if(!response.ok)throw new Error;return response})
        .then(data=>setIsOpen(false))
        .catch(err=>AlertHelper.ShowErrour("Something went wrong"));
    }
    return (
        <Modal open={isOpen} onEntering={onOpen} onClose={()=>setIsOpen(false)} backdrop="static" className='custom-modal'>
            <Modal.Header>Update User Privileges</Modal.Header>
            <Modal.Body>
            {isFinished===false?(<div style={{ textAlign: 'center' }}><Loader size="md" /></div>) : (  
                <CheckTree cascade={false} value={userPrivilges} onChange={setUserPrivileges} data={allPrivilges} labelKey="name" valueKey='id' defaultExpandAll={expand} showIndentLine   />
                )}
            </Modal.Body>
            <Modal.Footer>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={11} className="to-right">
                                            <Button appearance="default" size="lg" block style={cancelButton} onClick={()=>setIsOpen(false)}>BACK</Button>    
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={1} > 

                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={12}>
                                            <Button color="red" appearance="primary" block size="lg" style={saveButton} onClick={onSave}>
                                                SAVE
                                            </Button>   
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    )
})
export default UpdateUserPrivileges;