import React, { useEffect } from "react";
import { Row } from "rsuite";
import { Col } from "rsuite";
import { Stack } from "rsuite";
import { Button } from "rsuite";
import { SelectPicker } from "rsuite";
import { DateRangePicker } from "rsuite";
import './AppointmentFilter.css'
import { AuthorizationHeader } from "../../manager/AuthorizationHeader";
import Apis from "../../Helpers/Apis";
const AppointmentFilter =(props)=>
{
  
    const [sections, setSections] = React.useState([]);
    const [isSectionsLoaded,setIsSectionsLoaded]=React.useState(false);
    const all =
    {
      backgroundColor :"#46C6FF",
      color : "white",
      fontWeight : 600
    }
    const waiting =
    {
      backgroundColor :"#D8A311",
      color : "white",
      fontWeight : 600
    }
    const reported=
    {
      backgroundColor :"#ff6b5452",
      color : "gray",
      fontWeight : 600
    }
    const progress = 
    {
      backgroundColor : "#0CA7B2",
      color: "white",
      fontWeight : 600
    }
    const canceled = 
    {
      backgroundColor : "#DD0E2D",
      color : "white",
      fontWeight : 600
    }
    const normal=
    {
      fontWeight : 600
    }
    const onStorePickerOpen =async(e)=>
    {
      const fetchSections = await fetch(Apis.LIST_SECTIONS,{headers:AuthorizationHeader()});
      const resultSections = await fetchSections.json();
      setSections(resultSections);
      setIsSectionsLoaded(true);
    }
    const onSectionChanged=(e)=>
    {
      if(e===null)
        props.onChangeSectionFilter(-1);
      else
        props.onChangeSectionFilter(e);
    }
    const onDateRangeChanged=(e)=>
    {
      if(e===null)
      {

      }
      else{
        alert(e[0]);
        alert(e[1]);
      }
    
    }
    return (
    <Row className="show-grid">
      <Col xs={12} className="">
        <div className="filter-state gray-control rounded filter-appointment filter-margin-right">
          <Row>
            <Col xs={4} style={{width:"20%"}}><Button appearance="primary" size="sm" block onClick={e=>props.onChangeStateFilter(-1)} style={props.stateIndex===-1?all:normal}>All</Button></Col>
            <Col xs={4} style={{width:"20%"}}><Button appearance="primary" size="sm" block onClick={e=>props.onChangeStateFilter(0)} style={props.stateIndex===0?waiting:normal}>Waiting</Button></Col>
            <Col xs={4} style={{width:"20%"}}><Button appearance="primary" size="sm" block onClick={e=>props.onChangeStateFilter(4)} style={props.stateIndex===4?reported:normal}>Reported</Button></Col>
            <Col xs={4} style={{width:"20%"}}><Button appearance="primary" size="sm" block onClick={e=>props.onChangeStateFilter(1)} style={props.stateIndex===1?progress:normal}>In Progress</Button></Col>
            <Col xs={4} style={{width:"20%"}}><Button appearance="primary" size="sm" block onClick={e=>props.onChangeStateFilter(3)} style={props.stateIndex===3?canceled:normal}>Canceled</Button></Col>
          </Row>
        </div>
      </Col>
      <Col xs={6} className="">
        <div className="filter-shops gray-control rounded filter-appointment filter-margin-right">
          <SelectPicker size="sm" placeholder={isSectionsLoaded===true?"All Shops" : 'Current Shop'} block value={props.sectionIndex} data={sections} valueKey="id" labelKey="name" onOpen={onStorePickerOpen} onChange={onSectionChanged}  />
        </div>
      </Col>
      <Col xs={6} className="">
        <div className="filter-date gray-control rounded filter-appointment">
        <DateRangePicker size="sm" placeholder="Select Date Range" block placement="auto" value={props.dateRange} onChange={props.onChangeDateFilter} />
        </div>
      </Col>
    </Row>
    )
}
export default AppointmentFilter;