import React from 'react';
import {Form, Modal, Button,Input,FlexboxGrid,Loader,SelectPicker} from 'rsuite';
import { Schema } from 'rsuite';
import { AlertHelper } from '../../../Helpers/AlertHelper';
import Apis from '../../../Helpers/Apis';
import { AuthorizationHeader } from '../../../manager/AuthorizationHeader';
import SecurityCodeModal from './SecurityCodeModal';
import UpdateUserClaimsModal from './UpdateUserClaimsModal';
import UpdateUserPrivileges from './UpdateUserPrivilegesModal';
import { IsHavingPrivilge } from '../../../manager/IsHavingPrivilege';
const textArea = React.forwardRef((props, ref) => <Input as="textarea" size="md"    {...props}  ref={ref}  block />);
const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  loginName: StringType().isRequired('This field is required.'),
  password: StringType().isRequired('This field is required.').minLength(5,"Password must be 5 letters long or more"),
  confirmPassword: StringType().isRequired('This field is required.').minLength(5,"Password must be 5 letters long or more"),
});
const model2 = Schema.Model({
    loginName: StringType().isRequired('This field is required.'),
  });
const CreateUpdateUser = (props)=>
{
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        color :"#89919B",
        backgroundColor : "#D2D2D2",
        fontWeight : "bold"
    }
    const [loginName,setLoginName] = React.useState("");
    const [defaultStore,setDefaultStore] = React.useState();
    const [defaultEmployee,setDefaultEmployee] = React.useState();
    const [password,setPassword] = React.useState("");
    const [confirmPassword,setConfirmPassword] = React.useState("");
    const [isLoading,setIsLoading] = React.useState(false);
    const [isFinished,setIsFinished] = React.useState(false);
    const [allStores,setAllStores] = React.useState([]);
    const [allEmployees,setAllEmployees] = React.useState([]);
    const [IsUpdateUserClaimsModalVisible,setIsUpdateUserClaimsModalVisible] = React.useState(false);
    const formRef = React.useRef();
    const restoreRef = React.useRef();
    const privilegeRef = React.useRef();
    const [hasError,setHasError] = React.useState(false);
    const [errors,setErrors] = React.useState("");
    const formValue = props.id == null || props.id.trim() === ''?
    {
        loginName : loginName,
        password : password,
        confirmPassword : confirmPassword
    }:
    {
        loginName : loginName
    };
    const onSave=()=>
    {
        if(hasError)
        {
            AlertHelper.ShowErrour(errors);
            return;
        }
        if(!formRef.current.check())
        {
            console.log(formValue);
            return;
        }
        if(password!=confirmPassword)
        {
            AlertHelper.ShowErrour("password and cofirm password doesn't match");
            return;
        }
        setIsLoading(true);
        const data =
        {
            id:props.id,
            loginName,
            defaultStore,
            defaultEmployee,
            password,
            confirmPassword
        }
        var api = props.id == null || props.id.trim() === ''?Apis.REGISTER : Apis.UPDATE_USER
        fetch(api,{
            headers: AuthorizationHeader(),
            method:"POST",
            body : JSON.stringify(data)
        })
        .then(response=>{if(!response.ok) throw new Error("err"); return response})
        .then(data=>props.onSave())
        .catch(err=>{AlertHelper.ShowErrour("Something went wrong, check if this user doesn't exists and try again");setIsLoading(false)});
    }
    const onOpen=()=>
    {
        setIsFinished(false);
        setIsLoading(false);
        fetch(Apis.LIST_SECTIONS)
        .then(reponse=> reponse.json())
        .then(data=>setAllStores(data));

        fetch(Apis.LIST_EMPLOYEES,{headers:AuthorizationHeader()})
        .then(response=>{if(!response.ok)throw new Error(response.status);return response.json()})
        .then(data=>setAllEmployees(data))
        .catch(err=>{
            if(err.toString().indexOf("403")!==-1)
            {
                AlertHelper.ShowErrour("You must have the privilege of Employees List to update this content"); 
                setHasError(true);
                setErrors("You must have the privilege of Employees List to update this content");
            }
            else
                AlertHelper.ShowErrour("Something went wrong");
        })

        if(props.id == null || props.id.trim() === '')
        {
            setLoginName("");
            setDefaultStore(null);
            setDefaultEmployee(null);
            setPassword("");
            setConfirmPassword("");
        }
        else
        {
            fetch(Apis.GET_USER_INFO+"?id="+props.id,{headers:AuthorizationHeader()})
            .then(response=>response.json())
            .then(data=>{
                setLoginName(data.email);
                setDefaultStore(data.defaultStoreId);
                setDefaultEmployee(data.defaultEmployeeId);
                setPassword("");
                setConfirmPassword("");
            })
        }
        setIsFinished(true);
    }
    return(
        <Modal open={props.IsCreateUpdateUserModalVisible} onEntering={onOpen} backdrop="static" className="custom-modal" onClose={props.onClose}>
            <Modal.Header>
                {props.id == null || props.id.trim() === ''?"Add a new User" : "Update User"}
            </Modal.Header>
            <Modal.Body>
            {isFinished===false?(<div style={{ textAlign: 'center' }}><Loader size="md" /></div>) : (  

                <Form ref={formRef} formValue={formValue} model={props.id == null || props.id.trim() === ''?model:model2} fluid>
                    <Form.Group controlId='loginName'>
                        <Form.ControlLabel>Login Name</Form.ControlLabel>
                        <Form.Control name="loginName" value={loginName} onChange={setLoginName} placeholder="Login Name" block autoComplete='off' />
                    </Form.Group>
                    <Form.Group controlId='defaultStore'>
                        <Form.ControlLabel>Default Store</Form.ControlLabel>
                        <SelectPicker name="defaultStore"   placeholder='Default Store' data={allStores} valueKey="id" labelKey='name' value={defaultStore} onChange={setDefaultStore} autoComplete='off' block/>
                    </Form.Group>
                    <Form.Group controlId='defaultEmployee'>
                        <Form.ControlLabel>Link with an Employee</Form.ControlLabel>
                        <SelectPicker name="defaultEmployee"   placeholder='Employee' data={allEmployees} valueKey="id" labelKey='name' value={defaultEmployee} onChange={setDefaultEmployee} autoComplete='off' block/>
                    </Form.Group>
                    {props.id == null || props.id.trim() === ''?(<>
                    <Form.Group controlId='password'>
                        <Form.ControlLabel>Password</Form.ControlLabel>
                        <Form.Control name="password" value={password} onChange={setPassword} type='Password' placeholder="Password" block autoComplete='off' />
                    </Form.Group>
                    <Form.Group controlId='confirmPassword'>
                        <Form.ControlLabel>Confim Password</Form.ControlLabel>
                        <Form.Control name="confirmPassword" value={confirmPassword} onChange={setConfirmPassword} type='Password' placeholder="Confirm Password" block autoComplete='off'/>
                    </Form.Group>
                    </>):<>
                    <br/>
                    {IsHavingPrivilge("CONTROL_CLAIMS")===false?<></>:(<>
                    <a href='#'  style={{color:'red',textDecoration:"underline"}} onClick={(e)=>{e.preventDefault();privilegeRef.current.initDataSource(props.id)}}>Update User Privileges</a>
                    <br/>
                    <br/>
                    </>)}
                    <a href="#" style={{color:'red',textDecoration:"underline"}} onClick={(e)=>{e.preventDefault();restoreRef.current.initDataSource(props.id)}}>Restore User password</a>
                    </>}
                </Form>
            )}
            </Modal.Body>
            <Modal.Footer>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={11} className="to-right">
                                        <Button appearance="default" size="md" block style={cancelButton} disabled={isLoading} onClick={props.onClose}>BACK</Button>    
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={1} > 

                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={12}>
                                        <Button color="red" appearance="primary" block size="md" style={saveButton} loading={isLoading} onClick={onSave}>
                                            {props.id===0?"ADD":"SAVE"}
                                        </Button>   
                </FlexboxGrid.Item>
            </FlexboxGrid>
            </Modal.Footer>
            <UpdateUserClaimsModal id={props.id} IsUpdateUserClaimsModalVisible = {IsUpdateUserClaimsModalVisible} onSave={()=>setIsUpdateUserClaimsModalVisible(false)} onClose={()=>setIsUpdateUserClaimsModalVisible(false)} />
            <UpdateUserPrivileges ref={privilegeRef} />
            <SecurityCodeModal ref={restoreRef} />
        </Modal>
    )
}
export default CreateUpdateUser;