import React from "react";
import { Badge, Checkbox, Row } from "rsuite";
import { Col } from "rsuite";
import { Button } from "rsuite";
import './SolvedTableDetails.css'
const SolvedTableDetails =(props)=>
{
    const renderStateButton=(app)=>{
        if(app.clientCallDate===null||app.clientCallDate===undefined)
            return (<Button className="table-detail-state blured" style={{color:"#59B20C"}} size="sm" data-item={app.id} onClick={props.onSolvedClicked} block>Solved</Button>)
        else
            return (<Button className="table-detail-state blured" style={{color:"#59B20C"}} size="sm" data-item={app.id} onClick={props.onSolvedClicked} block>Called</Button>)
    }
    const drawColor=(score)=>
    {
        
        var color ="black"
        const s = parseInt(score);
        for(let element of props.scores)
        {
            if(s>=element.minValue&&s<=element.maxValue)
            {               
                color = element.color;
                break;
            }
        };
        return (<Badge style={{ background: color }} />)
    }
    const drawDate=(app)=>
    {
        if(app.clientCallDate===null)
        {
            if(app.clientDidntAnswer===true)
                return "Not Answered";
            else
                return "Not yet";
        }
        else
        {
            if(app.clientDidntAnswer===true)
                return "Not Answered";
            else
                return (new Date(app.clientCallDate).toLocaleString())
        }
            
    }
    const onChange=(value,e)=>{
        props.onSelectionChanged(value,e);
    }
    const noPadding = {
        paddingRight:0,
        paddingLeft:0
    }
    return (
        <div className="solved-table-container">
                        {props.appointments.map(app=>
                <Row className="solved-table-detail rounded" style={noPadding}>
                    <Col xs={1} className="solved-table-detail-checker"><Checkbox name="ch1" value={app.id} checked={app.checked}  onChange={onChange} /></Col>
                    <Col xs={1} style={{maxWidth:"50px"}}><Button  size="sm" className="table-detail-state blured" data-item={app.id} onClick={props.onTimeLineClick}>i</Button></Col>
                    <Col xs={2} >{renderStateButton(app)}</Col>
                    <Col xs={1} className="to-ellipsis">{drawColor(app.score)}</Col>
                    <Col xs={3} className="to-ellipsis">{app.groupCount} Member(s)</Col>
                    <Col xs={3} className="to-ellipsis">{app.sectionName}</Col>
                    <Col xs={3} className="solved-table-detail-client-name to-ellipsis">{app.clientName}</Col>
                    <Col xs={3} className="solved-table-detail-client-name to-ellipsis">{app.clientPhone}</Col>
                    <Col xs={4} className="to-ellipsis">{drawDate(app)}</Col>
                    <Col xs={3} className="to-ellipsis">{app.clientNote}</Col>
                </Row>
            )}
        </div>
    )
}
export default SolvedTableDetails;