import './NotFound.css'
import React, { Component } from "react";
export class NotFound extends Component
{
    componentDidMount()
    {
        document.getElementsByClassName("side-bar")[0].style.display="none";
        document.getElementsByClassName("header-div")[0].style.display="none";
    }
    render(){
    return (
        <div className='notFound-div'>
            <div className='notFound-div-corners'>
                <img src='left-corner.svg' />
                <img src='right-corner.svg' />
            </div>
            <div className='notFound-form'>
            <img src='logo.svg'/>
            <h1 className='to-center'>PAGE NOT FOUND 404</h1>
            <a className='to-center' href='\appointments'>Back to home</a>
            </div>
            <div>
            
            </div>
        </div>
    )}
}
export default NotFound;