import React from "react";
import { Checkbox, Row } from "rsuite";
import { Col } from "rsuite";
import { Button } from "rsuite";
import './TableDetails.css'
const UserTableDetail =(props)=>
{   
    const onChange=(value,e)=>{
        props.onSelectionChanged(value,e);
    }
    const noPadding = {
        paddingRight:0,
        paddingLeft:0
    }
    return (
        <div className="user-table-container">
                        {props.users.map(emp=>
                <Row className="user-table-detail rounded" style={noPadding}>
                    <Col xs={1} className="user-table-detail-checker"><Checkbox name="ch1" value={emp.id} checked={emp.checked}  onChange={onChange} /></Col>
                    <Col xs={14} className="user-table-detail-client-name">{emp.name}</Col>
                    <Col xs={9} className="user-table-detail-client-name">{emp.defaultStore}</Col>
                </Row>
            )}
        </div>
    )
}
export default UserTableDetail;