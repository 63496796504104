import React from "react";
import { FlexboxGrid, Form, Input, Modal,Button,DatePicker } from "rsuite";
import { Schema } from 'rsuite';
import { AlertHelper } from "../../../Helpers/AlertHelper";
import Apis from "../../../Helpers/Apis";
import { AuthorizationHeader } from "../../../manager/AuthorizationHeader";

const dateSelector = React.forwardRef((props, ref) => <DatePicker size="md" format="dd-MM-yyyy"    {...props}  ref={ref}  block />);
const timeSelector = React.forwardRef((props, ref) => <DatePicker size="md" format="hh:mm aa"   {...props}  ref={ref}  block />);
const textArea = React.forwardRef((props, ref) => <Input size="md" as="textarea"  style={{height:"165px"}}  {...props}  ref={ref}  block />);

const { StringType, NumberType,DateType } = Schema.Types;
const model = Schema.Model({
    date: DateType().isRequired("Date is required"),
    time: DateType().isRequired("Time is required"),
});

const ToReportModal =(props) =>
{
    const [date,setDate] = React.useState(null);
    const [time,setTime] = React.useState(null);
    const [note,setNote] = React.useState("");
    const [isLoading,setIsLoading] = React.useState(false);
    const [isFinished,setIsFinished] = React.useState(false);
    const formRef = React.useRef();
    const formValue = {
        date : date,
        time : time,
    }
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        color :"#89919B",
        backgroundColor : "#D2D2D2",
        fontWeight : "bold"
    }
    const onOpen =()=>
    {
        setIsFinished(false);
        setDate(null);
        setTime(null);
        setNote("");
        setIsLoading(false);
        setIsFinished(true);
    }
    const onClose=(isValidated)=>
    {
        setIsFinished(true);
        props.onCloseModal(isValidated);
    }
    const onSave=()=>
    {
        if(!formRef.current.check())
            return ;
        setIsLoading(true);
        var data =
        {
            appointmentId : props.id,
            date : date.toExactDate(),
            time : date.toExactDate(),
            note : note
        };
        fetch(Apis.UPDATE_APPOINTMENT_TO_REPORTED,
        {
            headers: AuthorizationHeader(),
            method : "PUT",
            body : JSON.stringify(data)
        })
        .then(response=>{if(!response.ok) throw new Error("err"); return response})
        .then(data=>onClose(true))
        .catch(err=>{AlertHelper.ShowErrour("Something went wrong");setIsLoading(false)});
    }
    return (
        <Modal open={props.isReportModalVisible} onEntered={onOpen} backdrop="static" onClose={e=>onClose(false)} className="custom-modal" >
            <Modal.Header>
                <h5>Rporting an Appointment</h5>
            </Modal.Header>
            <Modal.Body>
                <Form fluid model={model} formValue ={formValue} ref={formRef}>
                    <Form.Group>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={15}>
                                <Form.Group controlId="date">
                                    <Form.ControlLabel>Date</Form.ControlLabel>
                                    <Form.Control name="date" accepter={dateSelector} value={date} onChange={setDate} block/>
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={1}> </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={8}>
                                <Form.Group controlId="time">
                                    <Form.ControlLabel>Time</Form.ControlLabel>
                                    <Form.Control name="time" accepter={timeSelector} value={time} onChange={setTime} block/>
                                </Form.Group>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Note</Form.ControlLabel>
                        <Form.Control accepter={textArea} value = {note} placeholder="Note" onChange={setNote} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={11} className="to-right">
                                        <Button appearance="default" size="md" style={cancelButton} disabled={isLoading} onClick={e=>onClose(false)}>CANCEL</Button>    
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={1} > 

                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={12}>
                                        <Button color="red" appearance="primary" block size="md" style={saveButton} onClick={onSave} loading={isLoading}>
                                            REPORT
                                        </Button>   
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    )
}
export default ToReportModal;