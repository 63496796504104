import { Row } from 'rsuite';
import { Col } from 'rsuite';
import { Button } from 'rsuite';
import { Checkbox } from 'rsuite';
import React from 'react';
import './TableHeader.css';
import ArrowDownIcon from '@rsuite/icons/ArrowDown';
import ArrowUpIcon from '@rsuite/icons/ArrowUp';
import Minus from '@rsuite/icons/Minus';
const TableHeader=(props)=>
{
  const onSelectAll= (value,e)=>{
    props.onSelectAll(e);
  }
  const renderSort=()=>
  {
    if(props.dateSort==="asc")
      return (<a href="#" onClick={(e)=>{e.preventDefault();props.onDateSort();}} ><ArrowDownIcon /> Date</a>);
    else if(props.dateSort==="desc")
      return (<a href="#" onClick={(e)=>{e.preventDefault();props.onDateSort();}} ><ArrowUpIcon /> Date</a>);
    else if(props.dateSort==="none")
      return (<a href="#" onClick={(e)=>{e.preventDefault();props.onDateSort();}} ><Minus /> Date</a>);
  }
    return (
      <Row className="table-header rounded">
        <Col xs={1}><Checkbox onChange={onSelectAll}  /></Col> 
        <Col xs={4} className="to-ellipsis">Client</Col>
        <Col xs={3} className="to-ellipsis">Priority</Col>
        <Col xs={4} className="to-ellipsis">{renderSort()}</Col>
        <Col xs={4} className="to-ellipsis">Adresse</Col>
        <Col xs={3} className="to-ellipsis">Phone Number</Col>
        <Col xs={2} className="to-ellipsis">Store</Col>
        <Col xs={2} className="to-center to-ellipsis">Status</Col>
        <Col xs={2} className="to-center to-ellipsis"></Col>
      </Row>
    )
}
export default TableHeader;