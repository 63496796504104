import React, { useImperativeHandle } from 'react';
import {Button, Modal} from 'rsuite';
import { AlertHelper } from '../../../Helpers/AlertHelper';
import Apis from '../../../Helpers/Apis';
import { AuthorizationHeader } from '../../../manager/AuthorizationHeader';
const SecurityCodeModal=React.forwardRef((props,ref)=>{

    useImperativeHandle(ref,()=>({
        initDataSource : initDataSource
    }))
    const [info,setInfo] = React.useState({});
    const [userId,setUserId] = React.useState(null);
    const [isOpen,setIsOpen] = React.useState(false);
    const [isLoading,setIsLoading] = React.useState(false);
    const initDataSource=(id)=>
    {
        setUserId(id);
        setIsLoading(true);
        fetch(Apis.GET_SECURITY_CODE+"?id="+id,{headers:AuthorizationHeader()})
        .then(response=>{if(!response.ok) throw new Error();return response.json()})
        .then(data=>setInfo(data))
        .catch(err=>AlertHelper.ShowErrour("Something went wrong while retrieving security code"));
        setIsLoading(false);
        setIsOpen(true);
    }
    return (
        <Modal open={isOpen} onClose={()=>setIsOpen(false)} backdrop="static">
            <Modal.Header>Restore Password</Modal.Header>
            <Modal.Body>
                <table>
                    <tr>
                        <td style={{width:"110px"}}>Security Code :</td>
                        <td style={{fontWeight:"bold"}}>{info.code}</td>
                    </tr>
                    <tr>
                        <td>Expire Date :</td>
                        <td>{new Date(info.expireDate).dateToInput()} - {new Date(info.expireDate).timeToInput()}</td>
                    </tr>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=>setIsOpen(false)} appearance="ghost">Close</Button>
            </Modal.Footer>
        </Modal>
    )
});
export default SecurityCodeModal;