
import React from "react";
import { Form, Col,Grid, Input,InputGroup, Row,Loader, SelectPicker, Modal,DatePicker, FlexboxGrid,ButtonToolbar,Button,ButtonGroup,Dropdown,Popover,IconButton,Whisper, CheckPicker} from 'rsuite'
import PlusIcon from '@rsuite/icons/Plus';
import EditIcon from '@rsuite/icons/Edit';
import ArrowDownIcon from '@rsuite/icons/ArrowDown';
import ConfirmCancelation from "./ConfirmCancelation";
import { AuthorizationHeader } from "../../../manager/AuthorizationHeader";
import { Schema } from 'rsuite';
import Apis from "../../../Helpers/Apis";
import { AlertHelper } from "../../../Helpers/AlertHelper";
import ToReportModal from "./ToReportModal";
import CreateUpdateInvoiceModal from "../../Invoices/Modals/CreateUpdateInvoiceModal";
import PrintInvoiceModal from "./PrintInvoiceModal";
import { DoEventIfHavingPrivilge } from "../../../manager/IsHavingPrivilege";

const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  agent: StringType().isRequired("Agent is required"),
  responsable: StringType().isRequired("Responsable is required")
});
const groupSelector = React.forwardRef((props, ref) => <CheckPicker size="md"   valueKey="id" labelKey='name'  {...props}  ref={ref}  block />);
const ToProgressModal = (props)=>
{
    const timeNow = React.forwardRef((props, ref) => <DatePicker placement="autoVerticalStart" size="md" {...props}  ref={ref} format="yyyy-MM-dd HH:mm:ss" block/>);
    const elmployeeSelector = React.forwardRef((props, ref) => <SelectPicker size="md" data={employees}  valueKey="id" labelKey='name'  {...props}  ref={ref}  block />);
    const invoiceSelector = React.forwardRef((props, ref) => <SelectPicker size="md" data={invoices}  valueKey="id" labelKey='code'  {...props}  ref={ref}  block />);

    const [date,setDate] = React.useState(new Date());
    const [agentId,setAgentId] = React.useState(0);
    const [responsableId,setResponsableId] = React.useState(0);
    const [invoiceId,setInvoiceId] = React.useState(0);
    const [IsCancelationModalOpen,setIsCancelationModalOpen] = React.useState(false);
    const [isReportModalVisible,setIsReportModalVisible] = React.useState(false);
    const [employees,setEmployees] = React.useState([]);
    const [group,setGroup] = React.useState([]);
    const formRef = React.useRef();
    const [isLoading,setIsLoading] = React.useState(false);
    const [isFinished,setIsFinished] = React.useState(false);
    const [invoices,setInvoices] = React.useState([]);
    const invoiceRef = React.useRef();
    const printRef = React.useRef();
    const [hasErrors,setHasErrors] = React.useState(false);
    const [errors,setErrors] = React.useState([]);
    const saveButton=
    {
        backgroundColor:"#0CA7B2",
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton =
    {
        width:"100%",
    }
    const btn =
    {
         backgroundColor : "#F0E4E6",
         color :"#DD0E2D",
         fontWeight : "bold"
    }
    const onInvoiceUpdated=async(id)=>
    {
        var fetchInvoices = await fetch(Apis.LIST_INVOICES,{headers:AuthorizationHeader()});
        var jsonInvoices = await fetchInvoices.json();
        setInvoices(jsonInvoices.filter(m=>m.isValidated===false));
        setInvoiceId(id);
    }
    const onSave=(e)=>
    {
        if(hasErrors)
        {
            errors.forEach(element => {
                AlertHelper.ShowErrour(element);
            });
            return ;
        }
        if(!formRef.current.check())
        {
            return;
        }
        else
        {
            setIsLoading(true);
            var invId = invoiceId;
            if(invId===0||invId==="0"||invId===undefined)
                invId = null;
            const data = {
                id : props.id,
                agentId,
                responsableId,
                invoiceId : invId,
                groupMembers : group
            }
            fetch(Apis.UPDATE_APPOINTMENT_TO_PROGRESS,
            {
                method:"PUT",
                headers : AuthorizationHeader(),
                body : JSON.stringify(data)
            })
            .then(response=>{if(!response.ok) throw new Error("err");return response})
            .then(data=>
                {
                    if(invoiceId!==null&&invoiceId!==undefined&&invoiceId!=="0"&&invoiceId!==0)
                    {
                        printRef.current.initDataSource(props.id);
                    }
                    else
                    {
                        props.onSave();
                    }
                })
                
            // .then(data=> props.onSave())
            .catch(err=>{AlertHelper.ShowErrour("Something went wrong");;setIsLoading(false)});
        }
    }

    const renderMenu = ({ onClose, left, top, className }, ref) => {
        const handleSelect = eventKey => {
          onClose();
          console.log(eventKey);
        };
        return (
          <Popover ref={ref} className={className} style={{ left, top }} full>
            <Dropdown.Menu >
              <Dropdown.Item onClick={e=>DoEventIfHavingPrivilge("APPOINTMENT_CANCEl",()=>{ setIsCancelationModalOpen(true)},true)} eventKey={1}>Cancel Appointment</Dropdown.Item>
              <Dropdown.Item onClick={e=>DoEventIfHavingPrivilge("APPOINTMENT_TO_REPORT",()=>{setIsReportModalVisible(true)},true)} eventKey={2}>Report to another Date</Dropdown.Item>
            </Dropdown.Menu>
          </Popover>
        );
      };
    const onCloseModal=(isValidated,reason)=>
    {
        setIsCancelationModalOpen(false);
        if(isValidated===true)
        {
            setIsLoading(true);
            const data = {
                id:props.id,
                note : reason
            }
            fetch(Apis.UPDATE_APPOINTMENT_TO_CANCEL,
            {
                method:"PUT",
                headers : AuthorizationHeader(),
                body : JSON.stringify(data)
            })
            .then(response=>{if(!response.ok) throw new Error("err");return response})
            .then(data=>{setIsFinished(false); props.onSave()})
            .catch(err=>{AlertHelper.ShowErrour("Something went wrong");setIsLoading(false)});
        }
      
    }
    const onCloseReportModal=(isValidated)=>
    {
        setIsReportModalVisible(false);
        if(isValidated===true)
        {
            props.onSave();
        }
    }
    const onModalOpen=async()=>
    {
        setIsFinished(false);
        setIsLoading(false);
        setAgentId();
        setResponsableId();
        setInvoiceId();
        setGroup([]);
        var fetchEmployees = await fetch(Apis.LIST_EMPLOYEES,{headers:AuthorizationHeader()});
        if(fetchEmployees.ok)
        {
            var resultEmployees = await fetchEmployees.json();
            setEmployees(resultEmployees);
        }
        else
        {
            if(fetchEmployees.status.toString().indexOf("403")!==-1)
            {
                setHasErrors(true);
                setErrors([...errors,"You must have the privilege of List Employees to update this content"]);
                AlertHelper.ShowErrour("You must have the privilege of List Employees to update this content");
            }
            else
            {
                AlertHelper.ShowErrour("Something went wrong");
            }
        }

        var fetchInvoices = await fetch(Apis.LIST_INVOICES,{headers:AuthorizationHeader()});
        var jsonInvoices = await fetchInvoices.json();
        setInvoices(jsonInvoices.filter(m=>m.isValidated===false));
        setIsFinished(true);
    }
    const onCloseM=()=>
    {
        setIsFinished(true);
        props.onCloseModal();
    }
    return(
    <>
        <Modal open={props.isProgressModalVisible} onClose={onCloseM} className="custom-modal" onEntered={onModalOpen} backdrop="static" >
            <Modal.Header>
                <h5>Bon De Sortie</h5>
            </Modal.Header>
            <Modal.Body>
            {isFinished===false?(<div style={{ textAlign: 'center' }}><Loader size="md" /></div>) : (
                <Form fluid model={model} ref={formRef}>
                
                    <Form.Group controlId="agent" block>
                                <Form.ControlLabel>Agent</Form.ControlLabel>
                                <Form.Control name="agent" size="md" accepter={elmployeeSelector} placeholder="Agent" value={agentId} onChange={e=>setAgentId(e)} block />
                    </Form.Group>
                    <Form.Group controlId="responsable">
                                <Form.ControlLabel>Responsable</Form.ControlLabel>
                                <Form.Control name="responsable" size="md" accepter={elmployeeSelector} placeholder="Responsable" value={responsableId} onChange={e=>setResponsableId(e)} block />
                    </Form.Group>
                    <Form.Group controlId="group">
                                <Form.ControlLabel>Group</Form.ControlLabel>
                                <Form.Control name="group" size="md" data={employees} accepter={groupSelector} placeholder="Group" value={group} onChange={e=>setGroup(e)} block />
                    </Form.Group>
                    <Form.Group >
                                <Form.ControlLabel>Invoice</Form.ControlLabel>
                                <InputGroup style={{width:"100%",border:"0px"}}>
                                <Form.Control name="group" size="md" accepter={invoiceSelector} style={{width :"97%"}} placeholder="N° Invoice" value={invoiceId} onChange={e=>setInvoiceId(e)} block />
                                 <InputGroup.Button style={{borderRadius:"30px",height:"35px",width:"35px",marginTop:"1px",padding:"8px",backgroundColor:"red"}} onClick={()=>invoiceRef.current.initDataSource()}>
                                        <PlusIcon style={{color :"white"}} color={"white"} />
                                </InputGroup.Button>
                                <InputGroup.Button disabled={invoiceId===null||invoiceId===""||invoiceId==undefined?true:false} style={{marginLeft:"5px",fontSize:"25px",borderRadius:"30px",height:"35px",width:"35px",marginTop:"1px",padding:"8px",backgroundColor:invoiceId===null||invoiceId===""||invoiceId==undefined?"rgb(210 210 210)":"blueviolet"}} onClick={()=>invoiceRef.current.initDataSource(invoiceId)}>
                                        <EditIcon style={{color :"white"}} color={"white"} />
                                </InputGroup.Button>
                                </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="time-1">
                                <Form.ControlLabel>Date and Time</Form.ControlLabel>
                                <Form.Control name="time"  size="md" accepter={timeNow} value={date} readonly />
                    </Form.Group>
                </Form>
            )}
            </Modal.Body>
            <Modal.Footer>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={7} className="to-right">
                                    <ButtonGroup  size="md" style={cancelButton}>
                                        <Button style={btn}>Options</Button>
                                        <Whisper style={btn} placement="bottomStart" trigger="click" disabled={isLoading} speaker={renderMenu}>
                                            <IconButton style={btn} icon={<ArrowDownIcon />} />
                                        </Whisper>
                                    </ButtonGroup>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={17}>
                                    <Button color="red" appearance="primary" block size="md" style={saveButton} loading={isLoading} onClick={onSave} >
                                       {props.id===0?"PROCEED":"SAVE"}
                                    </Button>   
                </FlexboxGrid.Item>
            </FlexboxGrid>
            </Modal.Footer>
        </Modal>
        <ConfirmCancelation id = {props.id} IsCancelationModalOpen={IsCancelationModalOpen} onCloseModal={onCloseModal}/>
        <ToReportModal id={props.id} isReportModalVisible = {isReportModalVisible} onCloseModal = {onCloseReportModal} />
        <CreateUpdateInvoiceModal ref={invoiceRef} onInvoiceUpdated={onInvoiceUpdated} />
        <PrintInvoiceModal ref={printRef} onClose={props.onSave} />
    </>
    )
}
export default ToProgressModal;