import React from 'react';
import {FlexboxGrid, Form, Modal, Checkbox,Button,Loader} from 'rsuite';
import { AlertHelper } from '../../../Helpers/AlertHelper';
import Apis from '../../../Helpers/Apis';
import { AuthorizationHeader } from '../../../manager/AuthorizationHeader';
const UpdateUserClaimsModal =(props)=>
{
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        color :"#89919B",
        backgroundColor : "#D2D2D2",
        fontWeight : "bold"
    }
    const [userName, setUserName] = React.useState("");
    const [addAppointment,setAddAppointment] = React.useState(false);
    const [editAppointment,setEditAppointment] = React.useState(false);
    const [deleteAppointment,setDeleteAppointment] = React.useState(false);
    const [toProgressAppointment,setToProgressAddAppointment] = React.useState(false);
    const [toSolvedAppointment,setToSolvedAppointment] = React.useState(false);
    const [toCalncelAppointment,setToCancelAppointment] = React.useState(true);
    const [finilizeAppointment,setFinilizeAppointment] = React.useState(false);
    const [addClient,setAddClient] = React.useState(false);
    const [editClient,setEditClient] = React.useState(false);
    const [deleteClient,setDeleteClient] = React.useState(false); 
    const [addSection,setAddSection] = React.useState(false);
    const [editSection,setEditSection] = React.useState(false);
    const [deleteSection,setDeleteSection] = React.useState(false);
    const [addEmployee,setAddEmployee] = React.useState(false);
    const [editEmployee,setEditEmployee] = React.useState(false);
    const [deleteEmployee,setDeleteEmployee] = React.useState(false);
    const [addProduct,setAddProduct] = React.useState(false);
    const [editProduct,setEditProduct] = React.useState(false);
    const [deleteProduct,setDeleteProduct] = React.useState(false);
    const [addScore,setAddScore] = React.useState(false);
    const [editScore,setEditScore] = React.useState(false);
    const [deleteScore,setDeleteScore] = React.useState(false);
    const [addUsers,setAddUsers] = React.useState(false);
    const [settingsAccess,setSettingsAccess] = React.useState(false);
    const [controlClaims,setControlClaims] = React.useState(false);
    const [isLoading,setIsLoading] = React.useState(false);
    const [isFinished,setIsFinished] = React.useState(false);
    const ref1 = React.useRef();
    const onOpen=()=>
    {
        setIsFinished(false);
        setIsLoading(false);
        fetch(Apis.GET_USER_INFO+"?id="+props.id,{headers:AuthorizationHeader()})
        .then(response=>{if(!response.ok) throw new Error("err"); return response.json()})
        .then(data=>setUserName(data.name));

        fetch(Apis.GET_USER_CLAIMS+"?id="+props.id,{headers:AuthorizationHeader()})
        .then(response=>{if(!response.ok) throw new Error("err"); return response.json()})
        .then(data=>{
            setAddAppointment(data.filter(m=>m.name==="APPOINTMENT_ADD")[0].value==="true"?true:false);
            setEditAppointment(data.filter(m=>m.name==="APPOINTMENT_EDIT")[0].value==="true"?true:false);
            setDeleteAppointment(data.filter(m=>m.name==="APPOINTMENT_DELETE")[0].value==="true"?true:false);
            setToProgressAddAppointment(data.filter(m=>m.name==="APPOINTMENT_TO_PROGRESS")[0].value==="true"?true:false);
            setToSolvedAppointment(data.filter(m=>m.name==="APPOINTMENT_TO_SOLVED")[0].value==="true"?true:false);
            setFinilizeAppointment(data.filter(m=>m.name==="APPOINTMENT_TO_FINILIZE")[0].value==="true"?true:false);
            setToCancelAppointment(data.filter(m=>m.name==="APPOINTMENT_CANCEl")[0].value==="true"?true:false);

            setAddClient(data.filter(m=>m.name==="CLIENT_ADD")[0].value==="true"?true:false);
            setEditClient(data.filter(m=>m.name==="CLIENT_EDIT")[0].value==="true"?true:false);
            setDeleteClient(data.filter(m=>m.name==="CLIENT_DELETE")[0].value==="true"?true:false);

            setAddEmployee(data.filter(m=>m.name==="EMPLOYEE_ADD")[0].value==="true"?true:false);
            setEditEmployee(data.filter(m=>m.name==="EMPLOYEE_EDIT")[0].value==="true"?true:false);
            setDeleteEmployee(data.filter(m=>m.name==="EMPLOYEE_DELETE")[0].value==="true"?true:false);

            setAddScore(data.filter(m=>m.name==="SCORE_ADD")[0].value==="true"?true:false);
            setEditScore(data.filter(m=>m.name==="SCORE_EDIT")[0].value==="true"?true:false);
            setDeleteScore(data.filter(m=>m.name==="SCORE_DELETE")[0].value==="true"?true:false);

            setAddSection(data.filter(m=>m.name==="STORE_ADD")[0].value==="true"?true:false);
            setEditSection(data.filter(m=>m.name==="STORE_EDIT")[0].value==="true"?true:false);
            setDeleteSection(data.filter(m=>m.name==="STORE_DELETE")[0].value==="true"?true:false);

            setAddUsers(data.filter(m=>m.name==="USERS_ADD")[0].value==="true"?true:false);
            setSettingsAccess(data.filter(m=>m.name==="SETTINGS_ACCESS")[0].value==="true"?true:false);
            setControlClaims(data.filter(m=>m.name==="CONTROL_CLAIMS")[0].value==="true"?true:false);
            
            setAddUsers(data.filter(m=>m.name==="PRODUCT_ADD")[0].value==="true"?true:false);
            setSettingsAccess(data.filter(m=>m.name==="PRODUCT_EDIT")[0].value==="true"?true:false);
            setControlClaims(data.filter(m=>m.name==="PRODUCT_DELETE")[0].value==="true"?true:false);
        })
        .catch(err=>AlertHelper.ShowErrour("Something went wrong"));
        setIsFinished(true);
    }
    const onSave = ()=>
    {
        setIsLoading(true);
        var data ={
            userId :props.id,
            claims : [
                {name:"APPOINTMENT_ADD",value:addAppointment.toString()},
                {name:"APPOINTMENT_EDIT",value:editAppointment.toString()},
                {name:"APPOINTMENT_DELETE",value:deleteAppointment.toString()},
                {name:"APPOINTMENT_TO_PROGRESS",value:toProgressAppointment.toString()},
                {name:"APPOINTMENT_TO_SOLVED",value:toSolvedAppointment.toString()},
                {name:"APPOINTMENT_TO_FINILIZE",value:finilizeAppointment.toString()},
                {name:"APPOINTMENT_CANCEl",value:toCalncelAppointment.toString()},

                {name:"CLIENT_ADD",value:addClient.toString()},
                {name:"CLIENT_EDIT",value:editClient.toString()},
                {name:"CLIENT_DELETE",value:deleteClient.toString()},

                {name:"EMPLOYEE_ADD",value:addEmployee.toString()},
                {name:"EMPLOYEE_EDIT",value:editEmployee.toString()},
                {name:"EMPLOYEE_DELETE",value:deleteEmployee.toString()},

                {name:"SCORE_ADD",value:addScore.toString()},
                {name:"SCORE_EDIT",value:editScore.toString()},
                {name:"SCORE_DELETE",value:deleteScore.toString()},

                {name:"STORE_ADD",value:addSection.toString()},
                {name:"STORE_EDIT",value:editSection.toString()},
                {name:"STORE_DELETE",value:deleteSection.toString()},

                {name:"USERS_ADD",value:addUsers.toString()},
                {name:"CONTROL_CLAIMS",value:controlClaims.toString()},
                {name:"SETTINGS_ACCESS",value:settingsAccess.toString()},

                {name:"PRODUCT_ADD",value:addProduct.toString()},
                {name:"PRODUCT_EDIT",value:editProduct.toString()},
                {name:"PRODUCT_DELETE",value:deleteProduct.toString()},
            ]
        }
        fetch(Apis.COMMIT_CLAIMS,
        {
            headers:AuthorizationHeader(),
            method:"POST",
            body : JSON.stringify(data)
        }).then(response=>{if(!response.ok) throw new Error("err");return response})
        .then(data=>props.onSave())
        .catch(err=>{AlertHelper.ShowErrour("Something went wrong");setIsLoading(false)});
    }
    const onClose=()=>
    {
        setIsFinished(false);
        props.onClose();
    }
    return(
        <Modal open={props.IsUpdateUserClaimsModalVisible} backdrop="static" onEntered={onOpen} onClose={onClose} ref={ref1} className="custom-modal">
            <Modal.Header>
                <h5>Update Privileges | {userName}</h5>
            </Modal.Header>
            <Modal.Body>
                    {isFinished===false?(<div style={{ textAlign: 'center' }}><Loader size="md" /></div>) : (  
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <Checkbox checked={addAppointment} onChange={(v,e)=>setAddAppointment(e)}>Add Appointment</Checkbox>
                            <Checkbox checked={editAppointment} onChange={(v,e)=>setEditAppointment(e)}>Edit Appointment</Checkbox>
                            <Checkbox checked={deleteAppointment} onChange={(v,e)=>setDeleteAppointment(e)}>Delete Appointment</Checkbox>
                            <Checkbox checked={toProgressAppointment} onChange={(v,e)=>setToProgressAddAppointment(e)}>Change Appointment to Progress</Checkbox>
                            <Checkbox checked={toSolvedAppointment} onChange={(v,e)=>setToSolvedAppointment(e)}>Change Appointment to Solved</Checkbox>
                            <Checkbox checked={toCalncelAppointment} onChange={(v,e)=>setToCancelAppointment(e)}>Change Appointment to Candel </Checkbox>
                            <Checkbox checked={finilizeAppointment} onChange={(v,e)=>setFinilizeAppointment(e)}>Change Appointment after Solved state </Checkbox>
                            <br />
                            <br />
                            <Checkbox checked={addClient} onChange={(v,e)=>setAddClient(e)} style={{display:"block"}}>Add Client</Checkbox>
                            <Checkbox checked={editClient} onChange={(v,e)=>setEditClient(e)} style={{display:"block"}}>Edit Client</Checkbox>
                            <Checkbox checked={deleteClient} onChange={(v,e)=>setDeleteClient(e)} style={{display:"block"}}>Delete Client</Checkbox>

                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2}></FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={10}>
                            <Checkbox checked={settingsAccess} onChange={(v,e)=>setSettingsAccess(e)} style={{display:"block"}}>Access to Settings</Checkbox>
                            <Checkbox checked={addUsers} onChange={(v,e)=>setAddUsers(e)} style={{display:"block"}}>Add Users</Checkbox>
                            <Checkbox checked={controlClaims} onChange={(v,e)=>setControlClaims(e)} style={{display:"block"}}>Control Privileges</Checkbox>
                            <br/>
                            <Checkbox checked={addEmployee} onChange={(v,e)=>setAddEmployee(e)} style={{display:"block"}}>Add Employee</Checkbox>
                            <Checkbox checked={editEmployee} onChange={(v,e)=>setEditEmployee(e)} style={{display:"block"}}>Edit Employee</Checkbox>
                            <Checkbox checked={deleteEmployee} onChange={(v,e)=>setDeleteEmployee(e)} style={{display:"block"}}>Edit Employee</Checkbox>
                            <br/>
                            <Checkbox checked={addSection} onChange={(v,e)=>setAddSection(e)} style={{display:"block"}}>Add Store</Checkbox>
                            <Checkbox checked={editSection} onChange={(v,e)=>setEditSection(e)} style={{display:"block"}}>Edit Store</Checkbox>
                            <Checkbox checked={deleteSection} onChange={(v,e)=>setDeleteSection(e)} style={{display:"block"}}>Edit Store</Checkbox>
                            <br/>
                            <br/>
                            <Checkbox checked={addProduct} onChange={(v,e)=>setAddProduct(e)} style={{display:"block"}}>Add Product</Checkbox>
                            <Checkbox checked={editProduct} onChange={(v,e)=>setEditProduct(e)} style={{display:"block"}}>Edit Product</Checkbox>
                            <Checkbox checked={deleteProduct} onChange={(v,e)=>setDeleteProduct(e)} style={{display:"block"}}>Edit Product</Checkbox>
                            <br/>
                            <Checkbox checked={addScore} onChange={(v,e)=>setAddScore(e)} style={{display:"block"}}>Add Score</Checkbox>
                            <Checkbox checked={editScore} onChange={(v,e)=>setEditScore(e)} style={{display:"block"}}>Edit Score</Checkbox>
                            <Checkbox checked={deleteScore} onChange={(v,e)=>setDeleteScore(e)} style={{display:"block"}}>Edit Score</Checkbox>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    )}
            </Modal.Body>
            <Modal.Footer>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={11} className="to-right">
                                        <Button appearance="default" size="lg" block style={cancelButton} disabled={isLoading} onClick={onClose}>BACK</Button>    
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={1} > 

                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={12}>
                                        <Button color="red" appearance="primary" block size="lg" style={saveButton} loading={isLoading} onClick={onSave} >
                                            SAVE
                                        </Button>   
                </FlexboxGrid.Item>
            </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    )
}
export default UpdateUserClaimsModal;