import React, { useEffect } from "react";
import { Row } from "rsuite";
import { Col } from "rsuite";
import { Stack } from "rsuite";
import { Button } from "rsuite";
import { SelectPicker } from "rsuite";
import { DateRangePicker } from "rsuite";
import './AppointmentFilter.css'
import { AuthorizationHeader } from "../../manager/AuthorizationHeader";
import Apis from "../../Helpers/Apis";
import GroupTools from "../Common/GroupTool";
const OfferAppointmentFilter =(props)=>
{
  
    const [sections, setSections] = React.useState([]);
    const [offers,setOffers] = React.useState([]);
    const [isSectionsLoaded,setIsSectionsLoaded]=React.useState(false);
    const [isOfferLoaded,setAllLoaded] = React.useState(false);
    const all =
    {
      backgroundColor :"#46C6FF",
      color : "white",
      fontWeight : 600
    }
    const waiting =
    {
      backgroundColor :"#D8A311",
      color : "white",
      fontWeight : 600
    }
    const reported=
    {
      backgroundColor :"#ff6b5452",
      color : "gray",
      fontWeight : 600
    }
    const progress = 
    {
      backgroundColor : "#0CA7B2",
      color: "white",
      fontWeight : 600
    }
    const canceled = 
    {
      backgroundColor : "#DD0E2D",
      color : "white",
      fontWeight : 600
    }
    const normal=
    {
      fontWeight : 600
    }
    const onStorePickerOpen =async(e)=>
    {
      const fetchSections = await fetch(Apis.LIST_SECTIONS,{headers:AuthorizationHeader()});
      const resultSections = await fetchSections.json();
      setSections(resultSections);
      setIsSectionsLoaded(true);
    }
    const onOfferPickerOpen =async(e)=>
    {
      const newOffers =[{id:0,name:"Offers"}];
      const fetchOffers = await fetch(Apis.LIST_TYPE_OFFERS,{headers:AuthorizationHeader()});
      const resultOffers = await fetchOffers.json();
      resultOffers.forEach(element => {
        newOffers.push(element);
      });
      setOffers(newOffers);
      //setIsSectionsLoaded(true);
    }
    const onSectionChanged=(e)=>
    {
      if(e===null)
        props.onChangeSectionFilter(-1);
      else
        props.onChangeSectionFilter(e);
    }
    const onOfferChanged=(e)=>
    {
      if(e===null)
        props.onChangeOfferFilter(-1);
      else
        props.onChangeOfferFilter(e);
    }
    const onDateRangeChanged=(e)=>
    {
      if(e===null)
      {

      }
      else{
        alert(e[0]);
        alert(e[1]);
      }
    
    }
    return (
    <Row className="show-grid">
      <Col xs={9} className="">
        <div style={{marginTop:"15px"}}>
        <GroupTools canPrint={props.canPrint} canDelete={props.canDelete} onUpdateClick = {props.onUpdateClick} onDeleteClick ={props.onDeleteClick} onPrintClick={props.onPrintClick} onRefrechClick = {props.onRefrechClick}/>

        </div>
        {/* <div className="filter-state gray-control rounded filter-appointment filter-margin-right">
          <Row>
            <Col xs={8} style={{width:"33.33%"}}><Button appearance="primary" size="sm" block onClick={e=>props.onChangeStateFilter(-2)} style={props.stateIndex===-2?all:normal}>All</Button></Col>
            <Col xs={8} style={{width:"33.33%"}}><Button appearance="primary" size="sm" block onClick={e=>props.onChangeStateFilter(5)} style={props.stateIndex===5?waiting:normal}>Offer</Button></Col>
            <Col xs={8} style={{width:"33.33%"}}><Button appearance="primary" size="sm" block onClick={e=>props.onChangeStateFilter(6)} style={props.stateIndex===6?progress:normal}>Updated</Button></Col>
          </Row>
        </div> */}
      </Col>
      <Col xs={5} className="">
        <div className="filter-shops gray-control rounded filter-appointment filter-margin-right">
          <SelectPicker size="sm" placeholder="All Types of offer" block value={props.offerIndex} data={offers} valueKey="id" labelKey="name" onOpen={onOfferPickerOpen} onChange={onOfferChanged}  />
        </div>
      </Col>
      <Col xs={5} className="">
        <div className="filter-shops gray-control rounded filter-appointment filter-margin-right">
          <SelectPicker size="sm" placeholder={isSectionsLoaded===true?"All Shops" : 'Current Shop'} block value={props.sectionIndex} data={sections} valueKey="id" labelKey="name" onOpen={onStorePickerOpen} onChange={onSectionChanged}  />
        </div>
      </Col>
      <Col xs={5} className="">
        <div className="filter-date gray-control rounded filter-appointment">
        <DateRangePicker size="sm" placeholder="Select Date Range" block placement="auto" value={props.dateRange} onChange={props.onChangeDateFilter} />
        </div>
      </Col>
    </Row>
    )
}
export default OfferAppointmentFilter;