import React from "react";
import { Checkbox, Row } from "rsuite";
import { Col } from "rsuite";
import { Button } from "rsuite";
import './TableDetails.css'
const TableDetail =(props)=>
{   
    const onChange=(value,e)=>{
        props.onSelectionChanged(value,e);
    }
    const noPadding = {
        paddingRight:0,
        paddingLeft:0
    }
    return (
        <div className="score-table-container">
                        {props.scoreSettings.map(score=>
                <Row className="score-table-detail rounded" style={noPadding}>
                    <Col xs={1} className="score-table-detail-checker"><Checkbox name="ch1" value={score.id} checked={score.checked}  onChange={onChange} /></Col>
                    <Col xs={8} className="score-table-detail-client-name">{score.name}</Col>
                    <Col xs={5} className="score-table-detail-client-phone">{score.minValue}</Col>
                    <Col xs={5} className="score-table-detail-client-name">{score.maxValue}</Col>
                    <Col xs={5} className="score-table-detail-client-name">{score.color}</Col>
                </Row>
            )}
        </div>
    )
}
export default TableDetail;