import React, { Component, useState } from 'react';
import { Schema } from 'rsuite';
import {Redirect} from 'react-router-dom';
import { Button, Form } from 'rsuite';
import './Login.css';
import $ from 'jquery';
import Apis from '../../Helpers/Apis';
import { IsUserLoggedIn } from '../../manager/IsUserLoggedIn';
import { AuthorizationHeader } from '../../manager/AuthorizationHeader';
import './updatePassword.css'
import { AlertHelper } from '../../Helpers/AlertHelper';
const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  loginName: StringType().isRequired('This field is required.').minLength(5,"code must be 4 letters"),
  code: StringType().isRequired('This field is required.').minLength(4,"code must be 4 letters"),
  password: StringType().isRequired('This field is required.').minLength(5,"password must be 5 letters or more"),
  confirmPassword: StringType().isRequired('This field is required.').minLength(5,"password must be 5 letters or more"),
});
export class RestorePassword extends  Component
{
    constructor(props)
    {
        super(props);
        this.state=
        {
            code:"",
            loginName : "",
            password:"",
            confirmPassword : "",
            showInvalidPassword : false,
            errorMessage : "",
            isRedirect : false,
            isLoading : false
        }
        this.formref = React.createRef(null);
    }

    componentDidMount()
    {
        document.getElementsByClassName("side-bar")[0].style.display="none";
        document.getElementsByClassName("header-div")[0].style.display="none";
    }
    onSubmit=async ()=>
    {
        if(!this.formref.current.check())
        {
            return;
        }
        else
        {
            if(this.state.confirmPassword!==this.state.password)
                AlertHelper.ShowErrour("new Password and confirm Password doesn't match");
            else
                this.setState({isLoading:true},await this.login)
        }
    }
    login =async()=>
    {
        const response = await fetch(Apis.RESTORE_PASSWORD,{
                    method : "POST",
                    headers : AuthorizationHeader(),
                    body : JSON.stringify({
                        securityCode: this.state.code,
                        loginName : this.state.loginName,
                        password : this.state.password,
                    })
                });
        if(!response.ok)
        {   
            if(response.status===404)
            {
                var err = await response.json();
                this.setState({showInvalidPassword:true,isLoading:false,errorMessage:err.message});    
            }
            else
            {
                AlertHelper.ShowErrour("Something went wrong, please try again");
            }
            
        }

        else
        {

            this.setState({isRedirect:true});
        }
    }
    render(){
        let errourPassword = this.state.showInvalidPassword===true?(<p style={{color:"red"}}>{this.state.errorMessage}</p>):(<></>);
        if(this.state.isRedirect)
            return (<Redirect to="/appointments" />);
    return (
        <div className='update-password-div'>
            <div className='update-password-div-corners'>
                <img src='left-corner.svg' />
                <img src='right-corner.svg' />
            </div>
            <div className='update-password-form'>
                <Form fluid ref={this.formref} model={model}>
                    <Form.Group>
                        <img src='logo.svg'/>
                    </Form.Group>
                    <Form.Group>
                        <h3>Restore your Password</h3>
                    </Form.Group>
                    <Form.Group controlId='loginName'>
                        <Form.Control name="loginName"  size="md" placeholder='Login Name' value={this.state.loginName} onChange={e=>this.setState({loginName:e})} autoComplete='off' />
                    </Form.Group>
                    <Form.Group controlId='code'>
                        <Form.Control name="code"  size="md" placeholder='Security Code' value={this.state.code} onChange={e=>this.setState({code:e})} autoComplete='off' />
                    </Form.Group>
                    <Form.Group controlId='password'>
                        <Form.Control name="password"  size="md" placeholder='New Password' value={this.state.password} onChange={e=>this.setState({password:e})} type="password" autoComplete='off' />
                    </Form.Group>
                    <Form.Group controlId='confirmPassword'>
                        <Form.Control name="confirmPassword"  size="md" placeholder='Confirm Password' value={this.state.confirmPassword} onChange={e=>this.setState({confirmPassword:e})} type="password" autoComplete='off' />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>{errourPassword}</Form.ControlLabel>
                        <Button color="red" type='submit' size="md" appearance='primary' block onClick={this.onSubmit} loading={this.state.isLoading}>RESTORE</Button>
                    </Form.Group>
                </Form>
            </div>
            <div>

            </div>
        </div>

    )
}
}