import React, {Component} from 'react';
import ToolBar from '../Common/ToolBar';
import FonctionTableDetail from './Components/TableDetail';
import FonctionTableHeader from './Components/TableHeader';
import CreateUpdateFonctionModal from './Modals/CreateUpdateFonctionModal'
import { AuthorizationHeader } from '../../manager/AuthorizationHeader';
import DeleteConfirmation from '../Common/DeleteConfirmation';
// import './FFonction.css'
import Apis from '../../Helpers/Apis';
import { DoEventIfHavingPrivilge } from '../../manager/IsHavingPrivilege';
import { AlertHelper } from '../../Helpers/AlertHelper';
export default class FetchFonctions extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
            fonctionId : 0,
            IsCreateUpdateModalOpen : false,
            isDeleteModalVisible:false,
            fonctions : []
        }
    }
    componentDidMount()
    {
        // document.getElementsByClassName("side-bar")[0].style.display="initial";
        // document.getElementsByClassName("header-div")[0].style.display="initial";
        // document.getElementById("title").innerText = "fonctions";
        // document.getElementById("title").style.color = "#DD0E2D";
        this.fetchFonctions();
    }
    fetchFonctions(){
         fetch(Apis.LIST_FONCTIONS,{headers:AuthorizationHeader()})
        .then(response => response.json())
        .then(data=>{data.forEach(m=>{m.checked=false});this.setState({fonctions:data})});
    }
    onModalCreateFonction=()=>
    {
        DoEventIfHavingPrivilge("FONCTION_ADD",()=>{
        this.setState({fonctionId:0,IsCreateUpdateModalOpen:true});
        },true);
    }
    onModalUpdateFonction=()=>
    {
        DoEventIfHavingPrivilge("FONCTION_EDIT",()=>{
        const all = this.state.fonctions.filter(m=>m.checked===true);
        if(all.length===0)
        {
            AlertHelper.ShowWarning("No Role is Selected");
            return;
        }
        else if(all.length>1)
        {
            AlertHelper.ShowWarning("only one item can be updated");
            return;
        }
        this.setState({fonctionId:all[0].id,IsCreateUpdateModalOpen:true});
        },true);
    }
    onModalDeleteFonction=()=>
    {
        DoEventIfHavingPrivilge("FONCTION_DELETE",()=>{
        const selected = this.state.fonctions.filter(m=>m.checked===true);
        if(selected.length===0)
        {
            AlertHelper.ShowWarning("No Role is Selected");
            return;
        }
        this.setState({isDeleteModalVisible:true});
        },true);
    }
    onDelete=()=>
    {
        const selected = this.state.fonctions.filter(m=>m.checked===true);
        if(selected.length===0)
            return;
        var ids = [];
        for(var i=0 ; i<selected.length;i++)
        {
            ids[i] = selected[i].id;
        }
        fetch(Apis.DELETE_FONCTION,
        {
            method :"DELETE",
            headers : AuthorizationHeader(),    
            body :JSON.stringify(ids)
        })
        .then(response=>{if(!response.ok) throw new Error(response) ; return response})
        .then(data=>this.setState({isDeleteModalVisible:false},this.fetchFonctions))
        .catch(err=>AlertHelper.ShowErrour("Something went wrong"));
    }
    onCloseDeleteModal=()=>
    {
        this.setState({isDeleteModalVisible:false});
    }
    onCloseCreateUpdateModal=()=>
    {
        this.setState({IsCreateUpdateModalOpen : false});
    }
    onSave=()=>
    {
        this.setState({IsCreateUpdateModalOpen : false},this.fetchFonctions);
    }
    onSelectAll=(value)=>
    {
        const all = this.state.fonctions;
        all.forEach(element => {
            element.checked=value
        });
        this.setState({fonctions:all});
    }
    onSelectionChanged =(value,e)=>
    {
        const all = this.state.fonctions;
        var selected = all.filter(m=>m.id===value)[0];
        var index = all.indexOf(selected);
        all[index].checked=e;
        this.setState({fonctions:all});
    }
    renderTableDetails()
    {
        return(
            // <Grid fluid>
                <FonctionTableDetail fonctions = {this.state.fonctions} onSelectionChanged={this.onSelectionChanged} />
            // </Grid>
        )
    }
    onRefrech=()=>
    {
        this.fetchFonctions();
    }
    render()
    {
        let content = this.renderTableDetails();
        return(
        
        <div className='fetch-employe'>
            <ToolBar onRefrechClick={this.onRefrech} onCreateClick = {this.onModalCreateFonction} onUpdateClick={this.onModalUpdateFonction} onDeleteClick={this.onModalDeleteFonction} />
            <FonctionTableHeader onSelectAll = {this.onSelectAll}   />
            {content}
            <CreateUpdateFonctionModal id={this.state.fonctionId} isModalOpen={this.state.IsCreateUpdateModalOpen} onSave={this.onSave} onCloseModal={this.onCloseCreateUpdateModal} />
            <DeleteConfirmation title="Fonction(s)" isDeleteModalVisible = {this.state.isDeleteModalVisible} onClose={this.onCloseDeleteModal} onDelete={this.onDelete} />

        </div>
        )
    }
}