import React  from 'react';
import {Modal,CheckPicker ,Form,Loader, FlexboxGrid, Button, DatePicker, Input,Rate, InputNumber,Badge,Whisper,ButtonGroup,Popover,Dropdown,IconButton, Checkbox} from 'rsuite';
import ArrowDownIcon from '@rsuite/icons/ArrowDown';
import { AlertHelper } from '../../../Helpers/AlertHelper';
import Apis from '../../../Helpers/Apis';
import { AuthorizationHeader } from '../../../manager/AuthorizationHeader';
import ConfirmRollBack from "./ConfirmRollBack";
import { DoEventIfHavingPrivilge } from '../../../manager/IsHavingPrivilege';
const SolvedModal =(props)=>
{
    const elmployeeSelector = React.forwardRef((props, ref) => <CheckPicker  size="md" data={employees}  valueKey="id" labelKey='name'  {...props}  ref={ref}  block />);

    const [group,setGroup]=React.useState([]);
    const [score, setScore] = React.useState(0);
    const [callDate,setCallDate] =React.useState(null);
    const [callTime,setCallTime]=React.useState(null);
    const [mark,setMark] = React.useState("");
    const [scores, setScores] = React.useState([]);
    const [employees,setEmployees] = React.useState([]);
    const [isLoading,setIsLoading] = React.useState(false);
    const [isFinished,setIsFinished] = React.useState(false);
    const [isRollBackModalOpen,setIsRollBackModalOpen] = React.useState(false);
    const [hasErrors,setHasErrors] = React.useState(false);
    const [errors,setErrors] = React.useState([]);
    const [clientDidntAnswer,setClientDidntAnswer] = React.useState(false);
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        width : "100%"
        // color :"#89919B",
        // backgroundColor : "#D2D2D2",
        // fontWeight : "bold"
    }
    const btn =
    {
         backgroundColor : "#F0E4E6",
         color :"#DD0E2D",
         fontWeight : "bold"
    }
    const rollBackAppointment=(e)=>
    {
        DoEventIfHavingPrivilge("APPOINTMENT_TO_ROLLBACK",()=>{
        setIsRollBackModalOpen(true);
    },true);
    }
    const renderMenu = ({ onClose1, left, top, className }, ref) => {

        return (
          <Popover ref={ref} className={className} style={{ left, top }} full>
            <Dropdown.Menu >
              <Dropdown.Item eventKey={1} onClick={()=>onClose()}>Close</Dropdown.Item>
              <Dropdown.Item eventKey={2} onClick={rollBackAppointment}>Rollback to Progress</Dropdown.Item>
              {/* <Dropdown.Item eventKey={2}>Report to another Date</Dropdown.Item> */}
            </Dropdown.Menu>
          </Popover>
        );
      };
      const onCloseRollBackModal=(isValidated,reason)=>
    {
        setIsRollBackModalOpen(false);
        if(isValidated===true)
        {
            setIsLoading(true);
            const data = {
                id:props.id,
                note : reason
            }
            fetch(Apis.ROLLBACK_APPOINTMENT+"?id="+props.id,
            {
                method:"PUT",
                headers : AuthorizationHeader(),
            })
            .then(response=>{if(!response.ok) throw new Error("err");return response})
            .then(data=>props.onSave())
            .catch(err=>{AlertHelper.ShowErrour("Something went wrong");;setIsLoading(false)});
        }
    }
    const onOpen= async ()=>
    {
        setIsFinished(false);
        setIsLoading(false);
        var fetchEmployees = await fetch(Apis.LIST_EMPLOYEES,{headers :AuthorizationHeader()});
        if(fetchEmployees.ok)
        {
            var resultEmployees = await fetchEmployees.json();
            setEmployees(resultEmployees);
        }
        else
        {
            if(fetchEmployees.status.toString().indexOf("403")!==-1)
            {
                setHasErrors(true);
                setErrors([...errors,"You must have the privilege of List Employees to update this content"]);
                AlertHelper.ShowErrour("You must have the privilege of List Employees to update this content");
            }
            else
            {
                AlertHelper.ShowErrour("Something went wrong");
            }
        }

        var fetchScores = await fetch(Apis.LIST_SCORES,{headers :AuthorizationHeader()});
        if(fetchScores.ok)
        {
            var resultScores = await fetchScores.json();
            setScores(resultScores);
        }
        else
        {
            if(fetchScores.status.toString().indexOf("403")!==-1)
            {
                setHasErrors(true);
                setErrors([...errors,"You must have the privilege of List Scores to update this content"]);
                AlertHelper.ShowErrour("You must have the privilege of List Scores to update this content");
            }
            else
            {
                AlertHelper.ShowErrour("Something went wrong");
            }
        }
        


        fetch(Apis.FIND_APPOINTMENT_FINILIZE+"?id="+props.id,{headers:AuthorizationHeader()})
        .then(response=>response.json())
        .then(data=>
        {
            if(data.callDate!==null)
                setCallDate(new Date(data.callDate));
            else 
                setCallDate(null);
            if(data.callTime!==null)
                setCallTime(new Date(data.callTime));
            else
                setCallTime(null);

            setMark(data.mark===null?"":data.mark);
            setScore(data.score);
            setGroup(data.groupMembers);
            setClientDidntAnswer(data.clientDidntAnswer);
        });
        setIsFinished(true);
    }
    const onSave=()=>
    {
        if(hasErrors)
        {
            errors.forEach(element => {
                AlertHelper.ShowErrour(element);
            });
            return ;
        }
        setIsLoading(true);
        if(callDate!==undefined&&callTime===undefined)
        {
            AlertHelper.ShowErrour("Both Date and time must be submitted");
            setIsLoading(false);
            return;
        }
        if(callTime!==undefined&&callDate===undefined)
        {
            AlertHelper.ShowErrour("Both Date and time must be submitted");
            setIsLoading(false);
            return;
        }
        const data =JSON.stringify({
            id:props.id,
            callDate:callDate===undefined||callDate===null?null:new Date(callDate).toExactDate(),
            callTime:callTime===undefined||callTime===null?null:new Date(callTime).toExactDate(),
            groupMembers : group,
            mark:mark,
            score:score,
            clientDidntAnswer : clientDidntAnswer
        });
        var object
        fetch(Apis.UPDATE_APPOINTMENT_TO_FINILISE,{
            method:"PUT",
            headers : AuthorizationHeader(),
            body:data
        }).then(response=>{if(!response.ok) throw new Error(response);return response})
        .then(data=>props.onSave())
        .catch(err=>{AlertHelper.ShowErrour("Something went wrong");setIsLoading(false)});
    }
    const rateStyle = {
        color : "red"
    }
    const drawColor=()=>
    {
        
        var color ="black"
        var name = "Not defined"
        const s = parseInt(score);
        for(let element of scores)
        {
            if(s>=element.minValue&&s<=element.maxValue)
            {   name = element.name            
                color = element.color;
                break;
            }
        };
        return (<div style={{marginLeft:"10px",marginTop:"15px"}}><Badge style={{ background: color,marginRight:"10px" }} /><b>{name}</b></div>)
    }
    const onClose=()=>
    {
        setIsFinished(false);
        props.onCloseModal();
    }
    return(
        <Modal className = "custom-modal" open={props.isSolvedModalOpen} onEntered={onOpen} onClose={onClose} backdrop="static">
            <Modal.Header>
                <h5>Finalization</h5>
            </Modal.Header>
            <Modal.Body>
            {isFinished===false?(<div style={{ textAlign: 'center' }}><Loader size="md" /></div>) : (  
            <Form fluid>               
                    <Form.Group controlId="group-1" block>
                                <Form.ControlLabel>Group</Form.ControlLabel>
                                <CheckPicker name="group" size="md" value={group}  onChange={e=>{setGroup(e);console.log(e)}} placeholder="Group" data={employees}  valueKey="id" labelKey='name' block />
                    </Form.Group>
                    <Form.Group>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={12}>
                                <Form.Group>
                                    <Form.ControlLabel>Group Score</Form.ControlLabel>
                                    <InputNumber size="md" value={score} onChange={e=>setScore(e)} style={{width:"100%"}} block step={1} />
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={12}>
                                <Form.Group>
                                    <Form.ControlLabel>.</Form.ControlLabel>
                                    {drawColor()}
                                </Form.Group>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Form.Group>
                    <Form.Group>
                    <Checkbox checked={clientDidntAnswer} onChange={(e,d)=>setClientDidntAnswer(d)}>Client Didn't Answer?</Checkbox>
                    </Form.Group>
                    <Form.Group>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={15}>
                                <Form.Group controlId="date-1" block>
                                    <Form.ControlLabel>Call Date</Form.ControlLabel>
                                    <DatePicker disabled={clientDidntAnswer} format="dd-MM-yyyy" placement="autoVerticalStart" name="date" size="md" value={callDate}  onChange={e=>setCallDate(e)} placeholder="Date"  block />
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={1}></FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={8}>
                                <Form.Group controlId="time-1" block>
                                        <Form.ControlLabel>Call Time</Form.ControlLabel>
                                        <DatePicker disabled={clientDidntAnswer}  format="hh:mm aa" ranges={[]} placement="autoVerticalStart" name="time" size="md" value={callTime}  onChange={e=>setCallTime(e)} placeholder="Time"  block />
                                </Form.Group>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Form.Group>
                  
                    <Form.Group controlId="mark-1" block>
                                <Form.ControlLabel>Client Mark</Form.ControlLabel>
                                <Input as="textarea" disabled={clientDidntAnswer}  name="mark" size="md" value={mark}  onChange={e=>setMark(e)} placeholder="Mark"  block />
                    </Form.Group>

                </Form>
                 )}
            </Modal.Body>
            <Modal.Footer>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={7} className="to-right">
                                        {/* <Button appearance="default" size="md" block style={cancelButton} disabled={isLoading} onClick={onClose}>BACK</Button>
                                             */}
                                            <ButtonGroup  size="md" style={cancelButton}>
                                                <Button style={btn} >Options</Button>
                                                <Whisper style={btn} placement="bottomStart" trigger="click" speaker={renderMenu} disabled={isLoading}>
                                                    <IconButton style={btn} icon={<ArrowDownIcon />} />
                                                </Whisper>
                                            </ButtonGroup>
                                        </FlexboxGrid.Item>
           
                                        <FlexboxGrid.Item colspan={17}>
                                        <Button color="red" appearance="primary" block size="md" style={saveButton} loading={isLoading} onClick={onSave}>
                                            SAVE
                                        </Button>   
                </FlexboxGrid.Item>
            </FlexboxGrid>
            </Modal.Footer>
            <ConfirmRollBack id={props.id} IsRollBackModalOpen={isRollBackModalOpen} onCloseModal={onCloseRollBackModal} />
        </Modal>
    )
}
export default SolvedModal;