
import React from "react";
import { Form, Col,Grid, Input,InputGroup, Row,Loader, SelectPicker, Modal,DatePicker, FlexboxGrid,ButtonToolbar,Button,ButtonGroup,Dropdown,Popover,IconButton,Whisper} from 'rsuite'
import ArrowDownIcon from '@rsuite/icons/ArrowDown';
import ConfirmCancelation from "./ConfirmCancelation";
import { setDate } from "rsuite/esm/utils/dateUtils";
import { AuthorizationHeader } from "../../../manager/AuthorizationHeader";
import { Schema } from 'rsuite';
import Apis from "../../../Helpers/Apis";
import { AlertHelper } from "../../../Helpers/AlertHelper";
import ConfirmRollBack from "./ConfirmRollBack";
import { DoEventIfHavingPrivilge } from "../../../manager/IsHavingPrivilege";
import EditIcon from '@rsuite/icons/Edit';
import ValidateInvoiceProducts from "../../Invoices/Modals/ValidateInvoiceProducts";
const elmployeeSelector = React.forwardRef((props, ref) => <SelectPicker size="md"   valueKey="id" labelKey='name'  {...props}  ref={ref}  block />);
const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  verificateur: StringType().isRequired("Verificateur is required"),
  bonSortie: StringType().isRequired("N° Bon de sortie is required")
});
const ToFinishModal = (props)=>
{
    const [employees,setEmployees] = React.useState([]);
    const [IsCancelationModalOpen,setIsCancelationModalOpen] = React.useState(false);
    const [isRollBackModalOpen,setIsRollBackModalOpen] = React.useState(false);
    const [verificateurId,setVerificateurId] = React.useState(0);
    const [agentId,setAgentId]=React.useState();
    const [responsableId,setResponsableId] = React.useState();
    const [bonSortie,setBonSortie] = React.useState("");
    const [time,setTime] = React.useState(new Date);
    const [isLoading,setIsLoading] = React.useState(false);
    const [isFinished,setIsFinished] = React.useState(false);
    const formRef = React.useRef();
    const saveRef = React.useRef();
    const [hasErrors,setHasErrors] = React.useState(false);
    const [errors,setErrors] = React.useState([]);
    const [IsValidateInvoiceProductModalOpen,setIsValidateInvoiceProductModalOpen] = React.useState(false);
    const [invoiceId,setInvoiceId] = React.useState();
    const saveButton=
    {
        backgroundColor:"#59B20C",
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton =
    {
        width:"100%",

    }
    const btn =
    {
         backgroundColor : "#F0E4E6",
         color :"#DD0E2D",
         fontWeight : "bold"
    }


    const cancelAppointment =(e)=>
    {
        DoEventIfHavingPrivilge("APPOINTMENT_CANCEL",()=>{
            setIsCancelationModalOpen(true);
        },true)
    }
    const rollBackAppointment=(e)=>
    {
        DoEventIfHavingPrivilge("APPOINTMENT_TO_REPORT",()=>{
            setIsRollBackModalOpen(true);
        },true)
    }
    const renderMenu = ({ onClose, left, top, className }, ref) => {
        const handleSelect = eventKey => {
          onClose();
          console.log(eventKey);
        };
        return (
          <Popover ref={ref} className={className} style={{ left, top }} full>
            <Dropdown.Menu >
              <Dropdown.Item onClick={cancelAppointment} eventKey={1}>Cancel Appointment</Dropdown.Item>
              <Dropdown.Item onClick={rollBackAppointment} eventKey={2}>Rollback to Waiting State</Dropdown.Item>
              {/* <Dropdown.Item eventKey={2}>Report to another Date</Dropdown.Item> */}
            </Dropdown.Menu>
          </Popover>
        );
      };
    const onCloseRollBackModal=(isValidated,reason)=>
    {
        setIsRollBackModalOpen(false);
        if(isValidated===true)
        {
            setIsLoading(true);
            const data = {
                id:props.id,
                note : reason
            }
            fetch(Apis.ROLLBACK_APPOINTMENT+"?id="+props.id,
            {
                method:"PUT",
                headers : AuthorizationHeader(),
            })
            .then(response=>{if(!response.ok) throw new Error("err");return response})
            .then(data=>props.onSave())
            .catch(err=>{AlertHelper.ShowErrour("Something went wrong");;setIsLoading(false)});
        }
    }
    const onCloseModal=(isValidated,reason)=>
    {
        setIsCancelationModalOpen(false);
        if(isValidated===true)
        {
            setIsLoading(true);
            const data = {
                id:props.id,
                note : reason
            }
            fetch(Apis.UPDATE_APPOINTMENT_TO_CANCEL,
            {
                method:"PUT",
                headers : AuthorizationHeader(),
                body : JSON.stringify(data)
            })
            .then(response=>{if(!response.ok) throw new Error("err");return response})
            .then(data=>props.onSave())
            .catch(err=>{AlertHelper.ShowErrour("Something went wrong");;setIsLoading(false)});
        }
    }
    const onSave=()=>
    {
        if(hasErrors)
        {
            errors.forEach(element => {
                AlertHelper.ShowErrour(element);
            });
            return ;
        }
        if(!formRef.current.check())
        {
            return;
        }
        else
        {
            setIsLoading(true);
            const data = {
                id : props.id,
                verificateurId,
                bonSortie
            }
            fetch(Apis.UPDATE_APPOINTMENT_TO_FINISH,
                {
                    method:"PUT",
                    headers : AuthorizationHeader(),
                    body : JSON.stringify(data)
                })
                .then(response=>{if(!response.ok) throw new Error("err");return response})
                .then(data=>{props.onSave();saveRef.current.loading = false;})
                .catch(err=>{AlertHelper.ShowErrour("Something went wrong");setIsLoading(false);});
            
        }
    }
    const onModalOpen=async()=>
    {
        setIsFinished(false);
        setIsLoading(false);
        var fetchEmployees = await fetch(Apis.LIST_EMPLOYEES,{headers:AuthorizationHeader()});
        if(fetchEmployees.ok)
        {
            var resultEmployees = await fetchEmployees.json();
            setEmployees(resultEmployees);
        }
        else
        {
            if(fetchEmployees.status.toString().indexOf("403")!==-1)
            {
                setHasErrors(true);
                setErrors([...errors,"You must have the privilege of List Employees to update this content"]);
                AlertHelper.ShowErrour("You must have the privilege of List Employees to update this content");
            }
            else
            {
                AlertHelper.ShowErrour("Something went wrong");
            }
        }
        var fetchInvoiceId = await fetch(Apis.GET_INVOICE_ID_BY_APPOINTMENT_ID+"?id="+props.id,{headers:AuthorizationHeader()});
        if(fetchInvoiceId.ok)
        {
            var resultInvoiceId = await fetchInvoiceId.json();
            setInvoiceId(resultInvoiceId.invoiceId);
        }
        else
        {
            setHasErrors(true);
                setErrors([...errors,"An Errour while attempting to get invoice details"]);
                AlertHelper.ShowErrour("An Errour while attempting to get invoice details");
        }
        var fetchAppointment = await fetch(Apis.FIND_APPOINTMENT+"?id="+props.id,{headers:AuthorizationHeader()});
        var resultAppointment = await fetchAppointment.json();
        setAgentId(resultAppointment.agentId);
        setResponsableId(resultAppointment.responsableId);
        setTime(new Date());
        setVerificateurId(null);
        setBonSortie("");
        setIsFinished(true);
    }
    //filter employees by agentId
    
    const onCloseM=()=>
    {
        setIsFinished(false);
        props.onCloseModal();
    }
    
    return(
    <>
        <Modal open={props.isFinishModalVisible} onClose={onCloseM} className="custom-modal" onEntered={onModalOpen} backdrop="static">
            <Modal.Header>
                <h5>Bon De Sortie</h5>
            </Modal.Header>
            <Modal.Body>
            {isFinished===false?(<div style={{ textAlign: 'center' }}><Loader size="md" /></div>) : (
                <Form fluid ref={formRef} model={model} >
                    <Form.Group  block>
                                <Form.ControlLabel>Agent</Form.ControlLabel>
                                <SelectPicker size="md" data={employees}  valueKey="id" labelKey='name' value={agentId} readOnly block />
                    </Form.Group>
                    <Form.Group >
                                <Form.ControlLabel>Responsable</Form.ControlLabel>
                                <SelectPicker size="md" data={employees}  valueKey="id" labelKey='name' value={responsableId} readOnly block />
                    </Form.Group>
                    <Form.Group controlId="verificateur-1">
                                <Form.ControlLabel>Verificateur</Form.ControlLabel>
                                <Form.Control accepter={elmployeeSelector} size="md" data={employees} name="verificateur" placeholder="Verificateur" value={verificateurId} onChange={e=>setVerificateurId(e)} block />
                    </Form.Group>
                    <Form.Group controlId="bonSortie-1">
                                <Form.ControlLabel>N° Bon de Livraison (Logiciel Gestion de Stock)</Form.ControlLabel>
                                <InputGroup style={{width:"100%",border:"0px"}}>
                                <Form.Control size="md" name="bonSortie" placeholder="N° Bon de Livraison" value={bonSortie} onChange={setBonSortie} block />
                                <InputGroup.Button disabled={invoiceId===null||invoiceId===undefined?true:false} style={{marginLeft:"5px",fontSize:"25px",borderRadius:"30px",height:"35px",width:"35px",marginTop:"1px",padding:"8px",backgroundColor:invoiceId===null||invoiceId===undefined?"silver":"blueviolet"}} >
                                        <EditIcon style={{color :"white"}} color={"white"} onClick={()=>{if(invoiceId===null||invoiceId===undefined)return;setIsValidateInvoiceProductModalOpen(true)}} />
                                </InputGroup.Button>
                                </InputGroup>
                    </Form.Group>
                    <Form.Group >
                                <Form.ControlLabel>Date and Time</Form.ControlLabel>
                                <DatePicker size="md" placement="autoVerticalStart" value={time} format="yyyy-MM-dd HH:mm" block readonly />
                    </Form.Group>
                </Form>
            )}
            </Modal.Body>
            <Modal.Footer>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={7} className="to-right">
                                    <ButtonGroup  size="md" style={cancelButton}>
                                        <Button style={btn} >Options</Button>
                                        <Whisper style={btn} placement="bottomStart" trigger="click" speaker={renderMenu} disabled={isLoading}>
                                            <IconButton style={btn} icon={<ArrowDownIcon />} />
                                        </Whisper>
                                    </ButtonGroup>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={17}>
                                    <Button color="red" appearance="primary" block size="md" style={saveButton} onClick={onSave} ref={saveRef} loading={isLoading} >
                                        VALIDATE
                                    </Button>   
                </FlexboxGrid.Item>
            </FlexboxGrid>
            </Modal.Footer>
        </Modal>
        <ConfirmCancelation id = {props.id} IsCancelationModalOpen={IsCancelationModalOpen} onCloseModal={onCloseModal}/>
        <ConfirmRollBack id={props.id} IsRollBackModalOpen={isRollBackModalOpen} onCloseModal={onCloseRollBackModal} />
        <ValidateInvoiceProducts IsValidateInvoiceProductModalOpen = {IsValidateInvoiceProductModalOpen} onClose={()=>setIsValidateInvoiceProductModalOpen(false)} id={invoiceId} />
        </>
    )
}
export default ToFinishModal;