import React, { useState,Component } from 'react';
import {Redirect} from 'react-router-dom';
import './Register.css'
import {Form,Button, SelectPicker} from 'rsuite';
import Apis from '../../Helpers/Apis';
import { AuthorizationHeader } from '../../manager/AuthorizationHeader';

import { Schema } from 'rsuite';
import { AlertHelper } from '../../Helpers/AlertHelper';
const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  password: StringType().isRequired('This field is required.').minLength(5,"password must be 5 letters or more"),
  loginName: StringType().isRequired('This field is required.'),
  fullName : StringType().isRequired('This field is required.'),
  phone : StringType().isRequired('This field is required.'),
  password : StringType().isRequired('This field is required.').minLength(5,"password must be 5 letters or more"),
  confirmPassword : StringType().isRequired('This field is required.').minLength(5,"password must be 5 letters or more")
});

export class Register  extends Component{

    constructor(props)
    {
        super(props);
        this.state=
        {
            loginName:"",
            userName:"",
            password :"",
            confirmPassword : "",
            phone : "",
            defaultStore :null,
            stores : [],
            redirectToHome : false,
            isLoading : false
        }
        this.formRef = React.createRef();
    }
    onSubmit=()=>
    {
        if(!this.formRef.current.check())
            return;
        if(this.state.password!=this.state.confirmPassword)
        {
            AlertHelper.ShowErrour("password doesn't match");
            return;
        }
        this.setState({isLoading : true},()=>{
            fetch(Apis.REGISTER,
            {
                method:"POST",
                headers : AuthorizationHeader(),
                body : JSON.stringify({
                    loginName :this.state.loginName,
                    phone : this.state.phone,
                    fullName : this.state.userName,
                    password : this.state.password,
                    defaultStore : this.state.defaultStore
                })
            })
            .then(response=>{if(!response.ok) throw new Error(response.message);return response.json()})
            .then(data=>this.setState({redirectToHome:true}))
            .catch(err=>{AlertHelper.ShowErrour(err);this.setState({isLoading:false})});
        });
    }
    async componentDidMount()
    {
        document.getElementsByClassName("side-bar")[0].style.display="none";
        document.getElementsByClassName("header-div")[0].style.display="none";
        var response = await fetch(Apis.LIST_SECTIONS);
        var result = await response.json();
        this.setState({stores : result});
    }
    render(){
        if(this.state.redirectToHome)
            return <Redirect to="/" />

    return (
        <div className='register-div'>
        <div className='register-div-corners'>
            <img src='left-corner.svg' />
            <img src='right-corner.svg' />
        </div>
        <div className='register-form'>
            <Form fluid model={model} ref={this.formRef}>
                <Form.Group>
                    <img src='logo.svg'/>
                </Form.Group>
                <Form.Group>
                    <h3>Register a new Worker</h3>
                </Form.Group>
                <Form.Group controlId='loginName'>
                    <Form.Control name="loginName"  size="lg" placeholder='Login Name' value={this.state.loginName} onChange={e=>this.setState({loginName:e})} autoComplete='off' />
                </Form.Group>
                <Form.Group controlId='phone'>
                    <Form.Control name="phone"  size="lg" placeholder='Phone' value={this.state.phone} onChange={e=>this.setState({phone:e})} autoComplete='off' />
                </Form.Group>
                <Form.Group controlId='fullName'>
                    <Form.Control name="fullName"  size="lg" placeholder='Full Name' value={this.state.userName} onChange={e=>this.setState({userName:e})} autoComplete='off' />
                </Form.Group>
                <Form.Group>
                    <SelectPicker name="time"  size="lg" placeholder='Default Store' data={this.state.stores} valueKey="id" labelKey='name' value={this.state.defaultStore} onChange={e=>this.setState({defaultStore:e})} autoComplete='off' block/>
                </Form.Group>
                <Form.Group controlId='password'>
                    <Form.Control name="password"  size="lg" placeholder='Password' type="password" value={this.state.password} onChange={e=>this.setState({password:e})} autoComplete='off' />
                </Form.Group>
                <Form.Group controlId='confirmPassword'>
                    <Form.Control name="confirmPassword"  size="lg" placeholder='Confirm Password' type="password" value={this.state.confirmPassword} onChange={e=>this.setState({confirmPassword:e})} autoComplete='off' />
                </Form.Group>
                <Form.Group>
                    <Button color="red" type='submit' size="lg" appearance='primary' block onClick={this.onSubmit} loading={this.state.isLoading}>Register</Button>
                </Form.Group>
            </Form>
        </div>
        <div>

        </div>
    </div>
    )
}}
