import { Row } from 'rsuite';
import { Col } from 'rsuite';
import { Button } from 'rsuite';
import { Checkbox } from 'rsuite';
import React from 'react';
import './TableHeader.css';
const TableHeader=(props)=>
{
  const onSelectAll= (value,e)=>{
    props.onSelectAll(e);
  }
    return (
      <Row className="store-table-header rounded">
        <Col xs={1}><Checkbox onChange={onSelectAll}  /></Col> 
        <Col xs={11}>Store's Name</Col>
        <Col xs={12}>Adresse</Col>
      </Row>
    )
}
export default TableHeader;