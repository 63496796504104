import React,{ Component } from "react";
import { Col, FlexboxGrid, Grid, Modal, Row,SelectPicker,DateRangePicker,Pagination } from "rsuite";
import CreateUpdateAppointmentModal from "./Modals/CreateUpdateAppointmentModal";
import DeleteConfirmation from '../Common/DeleteConfirmation';
import { AuthorizationHeader } from "../../manager/AuthorizationHeader";
import GroupTools from '../Common/GroupTool';
import SolvedTableDetails from "./SolvedTableDetails";
import SolvedTableHeader from "./SolvedTableHeader";
import '../Common/customModal.css'
import SolvedModal from "./Modals/SolvedModal";
import Apis from "../../Helpers/Apis";
import {DoEventIfHavingPrivilge} from '../../manager/IsHavingPrivilege';
import { Placeholder } from "rsuite";
import { AlertHelper } from "../../Helpers/AlertHelper";
import TimeLineModal from "./Modals/TimeLineModal";

export class FSolved extends Component
{
    constructor(props)
    {
        super(props);
        this.onSaveAppointment = this.onSaveAppointment.bind(this);
        this.state={
            mode : this.props.match.params.mode,
            isCreateUpdateModalOpen : false,
            isSolvedModalVisible : false,
            IsTimeLineModalVisible : false,
            appointmentId :0,
            isLoading : true,
            sectionIndex :-1,
            stateIndex :2,
            appointments : [],
            sections : [],
            dateRange : null,
            scores:[],
            scoreIndex : -1,
            callStateIndex : "1",
            word:"",
            callStates :[{id:"-1",label:"All Calls"},{id:"0",label:"Called Only"},{id:"1",label:"Not Called Only"},{id:"2",label:"Not Answered Only"}],
            searchWord : "",
            activePage : 1,
            activePageChanged :false,
            limit : 30,
            total : 0,
        };
        this.fetchSections();
    }
    onOpenTimeLineModal=(e)=>
    {
        this.setState({
        appointmentId : e.target.getAttribute("data-item"),
        IsTimeLineModalVisible : true});
    }

    onCloseTimeLineModal=(e)=>
    {
        this.setState({IsTimeLineModalVisible : false});
    }
    componentDidMount(){
        document.getElementsByClassName("side-bar")[0].style.display="initial";
        document.getElementsByClassName("header-div")[0].style.display="initial";
        document.getElementById("title").innerText = "solved";//#59CC13
        document.getElementById("title").style.color = "#59CC13";
        document.getElementById("searchbox").onchange=(e)=>
        {
            this.setState({word:e.target.value},this.fetchAppointments);
        }
        this.setDefaultSection();
        this.fetchScores();
    }
    sortedData=()=>
    {
        if(this.state.appointments.length===0)
            return this.state.appointments;
        else
        {
            return this.state.appointments.filter(app=>app.clientName.toLowerCase().indexOf(this.state.searchWord.toLowerCase())!==-1);
        }
    }
    //#region FetchSections
    setDefaultSection()
    {
        fetch(Apis.GET_CURRENT_USSER_INFO,{headers:AuthorizationHeader()})
            .then(response=>{if(!response.ok) throw new Error("err");return response.json()})
            .then(data=>this.setState({sectionIndex:data.defaultStoreId},this.fetchAppointments))
            .catch(err=>AlertHelper.ShowErrour("Something went wrong"));
            
    }
    fetchSections()
    {
        fetch(Apis.LIST_SECTIONS,
        {
            headers:AuthorizationHeader()
        })
        .then(response=>{if(!response.ok) throw new Error();return response.json()})
        .then(data=>this.setState({sections:data}))
        .catch(err=>err);    
    }
    fetchScores()
    {
        fetch(Apis.LIST_SCORES,
        {
            headers:AuthorizationHeader()
        })
        .then(response=>{if(!response.ok) throw new Error();return response.json()})
        .then(data=>this.setState({scores:data}))
        .catch(err=>err);    
    }
    //#endregion

    //#region Fetch Appointments
    onSaveAppointment=(e)=>
    {
        this.setState({
            isSolvedModalVisible:false,
            isCreateUpdateModalOpen:false
        },
            this.fetchAppointments)
    }
    async fetchAppointments(word,state)
    {
        if(word==null)
            word = "";
        var search = {}
        if(this.state.dateRange===null)
        {
            search = 
            {
                word:this.state.word,
                stateIndex:this.state.stateIndex,
                sectionId:this.state.sectionIndex,
                scoreId : this.state.scoreIndex,
                callStateIndex : this.state.callStateIndex,
                activePage : this.state.activePageChanged===true?this.state.activePage:1,
                limit : this.state.limit,
            };
        }
        else
        {
            search = 
            {
                word:this.state.word,
                stateIndex:this.state.stateIndex,
                sectionId:this.state.sectionIndex,
                scoreId : this.state.scoreIndex,
                dateRange : {start : this.state.dateRange[0],end : this.state.dateRange[1]},
                callStateIndex : this.state.callStateIndex,
                activePage : this.state.activePageChanged===true?this.state.activePage:1,
                limit : this.state.limit,
            };
        }
        //await fetch("api/appointments/list?word="+word+"&state="+this.state.stateIndex+"&section="+this.state.sectionIndex)
        await fetch(Apis.LIST_APPOINTMENTS,{
        method :"POST",
        headers : AuthorizationHeader(),    
        body :JSON.stringify(search)})
        .then(response => {if(!response.ok)throw new Error("Err");return response.json()})
        .then(data=>{data.dataSource.forEach(m=>{m.checked=false});
        this.setState({
            appointments:data.dataSource,
            isLoading:false,
            total : data.total,
            activePage :this.state.activePageChanged===true?this.state.activePage:1
        });
        this.setState({activePageChanged : false})
    })
        .catch(err=>AlertHelper.ShowErrour("Something went wrong"));
    }
    onChangeStateFilter=(index)=>
    {
        this.setState({stateIndex : index},this.fetchAppointments);
    }
    onChangeSectionFilter=(index)=>
    {
        this.setState({sectionIndex : index},this.fetchAppointments);
    }
    onChangeDateFilter=(range)=>
    {
        this.setState({dateRange : range},this.fetchAppointments);
    }
    onChangeCallFilter=(value)=>
    {
        this.setState({callStateIndex : value},this.fetchAppointments);
    }
    onChangeScore=(value)=>
    {
        this.setState({scoreIndex : value},this.fetchAppointments);
    }
    onShowDeleteModal=(e)=>
    {
        const selected = this.state.appointments.filter(m=>m.checked===true);
        if(selected.length===0)
            return;

        this.setState({isDeleteModalVisible:true});
    }
    onCloseDeleteModal=()=>
    {
        this.setState({isDeleteModalVisible:false});
    }
    onSelectionChanged =(value,e)=>
    {
        const all = this.state.appointments;
        var selected = all.filter(m=>m.id===value)[0];
        var index = all.indexOf(selected);
        all[index].checked=e;
        this.setState({appointments:all});
    }
    onSelectAll=(value)=>
    {
        const all = this.state.appointments;
        all.forEach(element => {
            element.checked=value
        });
        this.setState({appointments:all});
    }
    //#endregion

    //#region Appointment
    onDeleteAppointments=(e)=>
    {
        const selected = this.state.appointments.filter(m=>m.checked===true);
        if(selected.length===0)
            return;
        var ids = [];
        for(var i=0 ; i<selected.length;i++)
        {
            ids[i] = selected[i].id;
        }
        fetch(Apis.DELETE_APPOINTMENT,
        {
            method :"DELETE",
            headers : AuthorizationHeader(),    
            body :JSON.stringify(ids)
        })
        .then(response=>{if(!response.ok) throw new Error(response) ; return response})
        .then(data=>this.setState({isDeleteModalVisible:false},this.fetchAppointments))
        .catch(err=>AlertHelper.ShowErrour("Something went wrong"));
        
    }
    onCreateAppointment=()=>
    {
        this.setState({appointmentId:0,isCreateUpdateModalOpen:true});
    }
    onUpdateAppointment= (e)=>
    {
        DoEventIfHavingPrivilge("APPOINTMENT_EDIT",()=>{
        if(this.state.appointments.filter(m=>m.checked===true).length===0)
        {
            AlertHelper.ShowWarning("No Appintment is Selected")
        }
        else if(this.state.appointments.filter(m=>m.checked===true).length>1)
        {
            AlertHelper.ShowWarning("Only one appintment can be edited");
        }
        else
        {
            const checked = this.state.appointments.filter(m=>m.checked===true)[0];
            this.setState({appointmentId:checked.id,isCreateUpdateModalOpen:true});
        }
    },true);
    }
    onCloseCreateUpdateAppointment=()=>
    {
        this.setState({isCreateUpdateModalOpen:false});
    }
    
    //#endregion
 
    //#region Solved Modal
    onShowSolvedModal = (e)=>{
        DoEventIfHavingPrivilge("APPOINTMENT_TO_FINILIZE",()=>{
        this.setState({
            appointmentId : e.target.getAttribute("data-item"),
            isSolvedModalVisible : true,
        })
    },true);
    }
    onCloseSolvedModal =()=>
    {
        this.setState({isSolvedModalVisible:false})
    }
    //#endregion
    
    //#region Printing
    onPrint=()=>
    {
            const selected = this.state.appointments.filter(m=>m.checked===true);
            if(selected.length===0)
            {
                AlertHelper.ShowWarning("No Appointment is selected");
                return;
            }
            var ids = [];
            for(var i=0 ; i<selected.length;i++)
            {
                ids[i] = selected[i].id;
            }
            localStorage.setItem("AppointmentsToPrinting",JSON.stringify(ids));
            this.props.history.push("/printingAppointment");
    }
    //#endregion
    onRefrech=()=>
    {
        this.fetchAppointments();
    }
    setActivePage=(e)=>
    {
        this.setState({activePageChanged:true,activePage : e},this.fetchAppointments);
    }
    setLimit=(e)=>
    {
        this.setState({limit:e},this.fetchAppointments);
    }
    render(){
        let content =this.state.isLoading?<Placeholder.Grid rows={16} columns={7} active /> : (<SolvedTableDetails onTimeLineClick={this.onOpenTimeLineModal} scores={this.state.scores} onSolvedClicked={this.onShowSolvedModal} onWaitingClicked={this.onCreateWaiting} onPogressClicked={this.onCreateProgress} onSelectionChanged={this.onSelectionChanged} appointments = {this.sortedData()} />);
        return (
            <>
           <Row className="show-grid">
                <Col xs={12}>
                    <GroupTools canPrint={true} canDelete={true} onRefrechClick={this.onRefrech} onUpdateClick = {this.onUpdateAppointment} onDeleteClick ={this.onShowDeleteModal} onPrintClick={this.onPrint}/>
                </Col>
                <Col xs={12} className="to-right">
                    <Row>
                    <Col xs={6} className="">
                        <div className="filter-shops gray-control rounded filter-appointment filter-margin-right" style={{marginTop:"0px"}}>
                        <SelectPicker size="sm" data={this.state.scores} value={this.state.scoreIndex} onChange={this.onChangeScore} valueKey="id" labelKey="name" placeholder="All Scores" block   />
                        </div>
                    </Col>
                    <Col xs={6} className="">
                        <div className="filter-shops gray-control rounded filter-appointment filter-margin-right" style={{marginTop:"0px"}}>
                        <SelectPicker size="sm" data={this.state.callStates} cleanable={false} value={this.state.callStateIndex} onChange={this.onChangeCallFilter} valueKey="id" labelKey="label" placeholder="All Calls" block   />
                        </div>
                    </Col>
                    <Col xs={6} className="">
                        <div className="filter-shops gray-control rounded filter-appointment filter-margin-right" style={{marginTop:"0px"}}>
                        <SelectPicker size="sm" data={this.state.sections} value={this.state.sectionIndex} valueKey="id" labelKey="name" placeholder="All Shops" block onChange={this.onChangeSectionFilter}  />
                        </div>
                    </Col>
                    <Col xs={6} className="">
                        <div className="filter-date gray-control rounded filter-appointment" style={{marginTop:"0px"}}>
                        <DateRangePicker size="sm" placeholder="Select Date Range" block placement="auto" value={this.state.dateRange} onChange={this.onChangeDateFilter} />
                        </div>
                    </Col>
                    </Row>
                </Col>
            </Row>
            <SolvedTableHeader  onSelectAll={this.onSelectAll} />
            {content}
            <div className="to-center" style={{marginTop:"10px"}}>
                <Pagination layout={['total', '-', 'limit', '|', 'pager', 'skip']} next={true} prev={true} total={this.state.total} limit={this.state.limit} activePage={this.state.activePage} onChangePage={(e)=>this.setActivePage(e)} onChangeLimit={(e)=>this.setLimit(e)} />;
           </div>
            <CreateUpdateAppointmentModal id={this.state.appointmentId} isCreateUpdateModalOpen = {this.state.isCreateUpdateModalOpen} onCloseModal={this.onCloseCreateUpdateAppointment} onSave={this.onSaveAppointment} />
            <SolvedModal id={this.state.appointmentId} isSolvedModalOpen = {this.state.isSolvedModalVisible} onCloseModal={this.onCloseSolvedModal} onSave={this.onSaveAppointment} />
            <DeleteConfirmation title="Appointment(s)" onDelete={this.onDeleteAppointments} isDeleteModalVisible={this.state.isDeleteModalVisible} onClose={this.onCloseDeleteModal} />
            <TimeLineModal id={this.state.appointmentId} IsTimeLineModalVisible = {this.state.IsTimeLineModalVisible} onClose={this.onCloseTimeLineModal} />

            </>
        )
    }
}