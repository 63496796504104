import React from "react";
import { Checkbox, Row } from "rsuite";
import { Col } from "rsuite";
import { Button } from "rsuite";
import './TableDetails.css'
const TableDetail =(props)=>
{   
    const onChange=(value,e)=>{
        props.onSelectionChanged(value,e);
    }
    const noPadding = {
        paddingRight:0,
        paddingLeft:0
    }
    return (
        <div className="store-table-container">
                        {props.stores.map(store=>
                <Row className="store-table-detail rounded" style={noPadding}>
                    <Col xs={1} className="store-table-detail-checker"><Checkbox name="ch1" value={store.id} checked={store.checked}  onChange={onChange} /></Col>
                    <Col xs={11} className="store-table-detail-client-name">{store.name}</Col>
                    <Col xs={12}>{store.adresse}</Col>
                </Row>
            )}
        </div>
    )
}
export default TableDetail;