import  configData from '../config.json'
class Apis 
{
    //https://localhost:5001/
    static  SERVER = configData.SERVER_URL;
    
    static LIST_APPOINTMENTS = Apis.SERVER + "api/appointments/list";
    static FIND_APPOINTMENT = Apis.SERVER + "api/appointments/FindById";
    static FIND_APPOINTMENT_VIEWMODEL = Apis.SERVER + "api/appointments/FindViewModalById";
    static CREATE_APPOINTMENT = Apis.SERVER + "api/appointments/create";
    static UPDATE_APPOINTMENT = Apis.SERVER + "api/appointments/update";
    static DELETE_APPOINTMENT = Apis.SERVER + "api/appointments/deleteRange";
    static FIND_APPOINTMENTs_BY_RANGE = Apis.SERVER + "api/appointments/FindByRangeId";
    static FIND_APPOINTMENTs_BY_CLIENT = Apis.SERVER + "api/appointments/GetAppointmentsByClient";
    static FIND_APPOINTMENTs_BY_PERIOD = Apis.SERVER + "api/appointments/GetAppointmentsByPeriod";
    static FIND_APPOINTMENT_FINILIZE  = Apis.SERVER + "api/appointments/FindFinilizeById";
    static UPDATE_APPOINTMENT_TO_REPORTED  = Apis.SERVER + "api/appointments/ToReport";
    static UPDATE_APPOINTMENT_TO_UPDATED  = Apis.SERVER + "api/appointments/ToUpdated";
    static UPDATE_APPOINTMENT_TO_PROGRESS  = Apis.SERVER + "api/appointments/ToProgress";
    static UPDATE_APPOINTMENT_TO_FINISH  = Apis.SERVER + "api/appointments/ToFinish";
    static UPDATE_APPOINTMENT_TO_CANCEL  = Apis.SERVER + "api/appointments/ToCancel";
    static UPDATE_APPOINTMENT_TO_FINILISE  = Apis.SERVER + "api/appointments/Finilize";
    static UPDATE_APPOINTMENT_TO_CANCEL  = Apis.SERVER + "api/appointments/ToCancel";
    static UPDATE_APPOINTMENT_TO_WAITING  = Apis.SERVER + "api/appointments/ToWaiting";
    static ROLLBACK_APPOINTMENT = Apis.SERVER + "api/appointments/RollBack";

    static LIST_SECTIONS = Apis.SERVER + "api/sections/list";
    static FIND_SECTION = Apis.SERVER + "api/sections/findbyid";
    static CREATE_SECTION = Apis.SERVER + "api/sections/create";
    static UPDATE_SECTION = Apis.SERVER + "api/sections/update";
    static DELETE_SECTION = Apis.SERVER + "api/sections/deleteRange";

    static LIST_PRODUCTS = Apis.SERVER + "api/products/list";
    static FIND_PRODUCT = Apis.SERVER + "api/products/findbyid";
    static CREATE_PRODUCT = Apis.SERVER + "api/products/create";
    static UPDATE_PRODUCT = Apis.SERVER + "api/products/update";
    static DELETE_PRODUCT = Apis.SERVER + "api/products/deleteRange";

    static LIST_INVOICES = Apis.SERVER + "api/Invoices/GetAvailableInvoices";
    static GET_INVOICE_DETAILS_VIEWMODEL = Apis.SERVER + "api/Invoices/GetInvoiceDetailsViewModal";
    static GET_INVOICE_ID_BY_APPOINTMENT_ID = Apis.SERVER + "api/Invoices/GetInvoiceIdByAppointmentId";
    static VALIDATE_INVOICE_CHECK = Apis.SERVER + "api/Invoices/ValidateCheck";
    static FIND_INVOICE = Apis.SERVER + "api/Invoices/findbyid";
    static CREATE_INVOICE = Apis.SERVER + "api/Invoices/create";
    static UPDATE_INVOICE = Apis.SERVER + "api/Invoices/update";
    static DELETE_INVOICE = Apis.SERVER + "api/Invoices/delete";

    static LIST_CLIENTS = Apis.SERVER + "api/clients/list";
    static FIND_CLEINT = Apis.SERVER + "api/clients/findbyid";
    static CREATE_CLIENT = Apis.SERVER + "api/clients/create";
    static UPDATE_CLIENT = Apis.SERVER + "api/clients/update";
    static DELETE_CLIENT = Apis.SERVER + "api/clients/deleteRange";
    static DELETE_CLIENT_ONE = Apis.SERVER + "api/clients/delete";

    static LIST_EMPLOYEES = Apis.SERVER + "api/employees/list";
    static FIND_EMPLOYEE = Apis.SERVER + "api/employees/findbyid";
    static CREATE_EMPLOYEE = Apis.SERVER + "api/employees/create";
    static UPDATE_EMPLOYEE = Apis.SERVER + "api/employees/update";
    static DELETE_EMPLOYEE = Apis.SERVER + "api/employees/deleteRange";

    static LIST_SCORES = Apis.SERVER + "api/ScoreSettings/list";
    static FIND_SCORE = Apis.SERVER + "api/ScoreSettings/findbyid";
    static CREATE_SCORE = Apis.SERVER + "api/ScoreSettings/create";
    static UPDATE_SCORE = Apis.SERVER + "api/ScoreSettings/update";
    static DELETE_SCORE = Apis.SERVER + "api/ScoreSettings/deleteRange";

    static LIST_FONCTIONS  = Apis.SERVER + "api/Fonction/list";
    static FIND_FONCTION  = Apis.SERVER + "api/Fonction/findbyid";
    static CREATE_FONCTION  = Apis.SERVER + "api/Fonction/create";
    static UPDATE_FONCTION  = Apis.SERVER + "api/Fonction/update";
    static DELETE_FONCTION  = Apis.SERVER + "api/Fonction/deleteRange";

    static LIST_TYPE_OFFERS  = Apis.SERVER + "api/TypeOffer/list";
    static FIND_TYPE_OFFERS  = Apis.SERVER + "api/TypeOffer/findbyid";
    static CREATE_TYPE_OFFERS  = Apis.SERVER + "api/TypeOffer/create";
    static UPDATE_TYPE_OFFERS  = Apis.SERVER + "api/TypeOffer/update";
    static DELETE_TYPE_OFFERS  = Apis.SERVER + "api/TypeOffer/deleteRange";

    static LIST_TYPE_APPOINTMENTS  = Apis.SERVER + "api/TypeAppointment/list";
    static FIND_TYPE_APPOINTMENTS  = Apis.SERVER + "api/TypeAppointment/findbyid";
    static CREATE_TYPE_APPOINTMENTS  = Apis.SERVER + "api/TypeAppointment/create";
    static UPDATE_TYPE_APPOINTMENTS  = Apis.SERVER + "api/TypeAppointment/update";
    static DELETE_TYPE_APPOINTMENTS  = Apis.SERVER + "api/TypeAppointment/deleteRange";

    static LOGIN = Apis.SERVER +"api/Authenticate/login";
    static REGISTER = Apis.SERVER +"api/Authenticate/register";
    static UPDATE_PASSWORD = Apis.SERVER +"api/Authenticate/UpdatePassword";
    static RESTORE_PASSWORD = Apis.SERVER + "api/Authenticate/RestorePassword";
    static COMMIT_CLAIMS = Apis.SERVER +"api/Authenticate/CommitClaims";
    static GET_USER_INFO = Apis.SERVER +"api/Authenticate/GetUserInfo";
    static UPDATE_USER = Apis.SERVER +"api/Authenticate/UpdateUser";
    static GET_SECURITY_CODE = Apis.SERVER + "api/Authenticate/GetSecurityCode";
    static GET_USER_REQUIRE_LOGIN = Apis.SERVER + "api/Authenticate/UserRequireToLogin"
    static GET_USER_CLAIMS = Apis.SERVER +"api/Authenticate/GetUserPrivileges";
    static GET_CURRENT_USSER_INFO = Apis.SERVER +"api/Authenticate/GetCurrentUserInfo"
    static LIST_USERS = Apis.SERVER +"api/Authenticate/List";

    static LIST_PRIVILEGES = Apis.SERVER + "api/Authenticate/GetPrivilegeList";
    static BACKUP = Apis.SERVER +"api/Maintenance/Backup";
    static RESTORE = Apis.SERVER +"api/Maintenance/Restore";
    static EXPORT_CSV = Apis.SERVER +"api/Maintenance/ExportToCsv";
    static UPDATE_DATABASE = Apis.SERVER +"api/Maintenance/UpdateDatabase";


}
export default Apis;
