import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Row,Col } from "rsuite";
import './ClientHistory.css'
const ClientHistory =(props)=>
{

    const history = useHistory();
    const handleTime=(date)=>
    {
        
        if(date===null)
        {
            return "Not definded yet"
        }
        else
        {
            return new Date(date).toLocaleString();
        }
    }
    const onPrint=()=>
    {
  
        var ids = [];
        props.appointments.forEach(element => {
            ids[ids.length]=element.id
        });
        localStorage.setItem("ClientHistoryToPrinting",JSON.stringify({clientId : props.id,ids:ids}));
        history.push("/printClientHistory");
    }
    return(
        <div className="client-history">
             <Button className="client-history-print" onClick={onPrint}>
                 <img src="print.svg"/>
             </Button>
             {props.appointments.map(app=>
  <div className="client-history-detail">
  <p>{new Date(app.date).getDate()+" "+new Date(app.date).toMonthName()+" "+new Date(app.date).getFullYear()} <b>{new Date(app.date).timeToInput()}</b></p>
  <Row>
  <Col xs={12}>
      <table>
      <tbody>
          <tr>
              <td>
                  Designation :
              </td>
              <td>
                  {app.name}
              </td>
          </tr>
          <tr>
              <td>
                  Responsable :
              </td>
              <td>
                  {app.responsable}
              </td>
          </tr>
          <tr>
              <td>
                  Agent :
              </td>
              <td>
                  {app.agent}
              </td>
          </tr>
          <tr>
              <td>
                  Verificateur :
              </td>
              <td>
                {app.verificateur}
              </td>
          </tr>
          <tr>
              <td>
                  N° Bon :
              </td>
              <td>
                {app.bonSortie}
              </td>
          </tr>
      </tbody>
  </table>
      </Col>
      <Col xs={12}>
      <table>
      <tbody>
          <tr>
              <td>
                  Waiting Date :
              </td>
              <td>
                  {handleTime(app.dateWatiting)}
              </td>
          </tr>
          <tr>
              <td>
                  Progress Date :
              </td>
              <td>
              {handleTime(app.dateProgress)}
              </td>
          </tr>
          <tr>
              <td>
                  Finish Date :
              </td>
              <td>
              {handleTime(app.dateFinish)}
              </td>
          </tr>
          <tr>
              <td>
                  Cancel Date :
              </td>
              <td>
              {handleTime(app.dateCancel)}
              </td>
          </tr>
          <tr>
              <td>
                  Cancel Reason :
              </td>
              <td>
                {app.cancelReason}
              </td>
          </tr>
      </tbody>
  </table>
      </Col>

  </Row>
  
</div>
             )}
           
        </div>
    )
}
export default ClientHistory;