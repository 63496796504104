import React,{ Component } from "react";
import ToolBar from "../Common/ToolBar";
import AppointmentFilter from "./AppointmentFilter";
import CreateUpdateAppointmentModal from "./Modals/CreateUpdateAppointmentModal";
import TableDetail from "./TableDetails";
import TableHeader from "./TableHeader";
import '../Common/customModal.css';
import DeleteConfirmation from '../Common/DeleteConfirmation';
import ToProgressModal from "./Modals/ToProgressModal";
import ToFinishModal from "./Modals/ToFinishModal";
import { AuthorizationHeader } from "../../manager/AuthorizationHeader";
import { DoEventIfHavingPrivilge } from "../../manager/IsHavingPrivilege";
import { Pagination, Placeholder } from "rsuite";
import Apis from '../../Helpers/Apis'
import { AlertHelper } from "../../Helpers/AlertHelper";
import TimeLineModal from "./Modals/TimeLineModal";
export class FetchAppointments extends Component
{
    constructor(props)
    {
        super(props);
        this.onSaveAppointment = this.onSaveAppointment.bind(this);
        this.state={
            mode : this.props.match.params.mode,
            isCreateUpdateModalOpen : false,
            isProgressModalVisible:false,
            isFinishModalVisible : false,
            isDeleteModalVisible : false,
            IsTimeLineModalVisible : false,
            appointmentId : 0,
            isLoading : true,
            stateIndex :-1,
            sectionIndex : -1,
            dateRange : null,
            sections : [],
            appointments : [],
            filtredAppointments : [],
            word:"",
            sort : "none",
            searchWord : "",
            activePage : 1,
            activePageChanged :false,
            limit : 30,
            total : 0,
        };
        this.appModal = React.createRef();
    }
    componentDidMount(){
        document.getElementsByClassName("side-bar")[0].style.display="initial";
        document.getElementsByClassName("header-div")[0].style.display="initial";
        document.getElementById("title").innerText = "appointments";
        document.getElementById("title").style.color = "#DD0E2D";
        document.getElementById("searchbox").onchange=(e)=>
        {
            this.setState({word:e.target.value},this.fetchAppointments);
        }
         this.setDefaultSection();
    
        //this.fetchAppointments();
        //this.forceUpdate();
    }
    changeSort=()=>
    {
        if(this.state.sort==="asc")
        {
            this.setState({sort:"desc"});
            return;
        }

        else if(this.state.sort==="desc")
        {
            this.setState({sort:"none"});
            return;
        }
            
        else if(this.state.sort==="none")
        {
            this.setState({sort : "asc"});
            return;
        }
            
    }
    sortAppointments=(apps)=>
    {
        if(this.state.sort==="asc")
        {
            
            return apps.sort(function(a,b)
            {
                const date_a = a.dateReport===null||a.dateReport===undefined?a.date:a.dateReport; 
                const date_b = b.dateReport===null||b.dateReport===undefined?b.date:b.dateReport; 
                return new Date(date_a) - new Date(date_b);
            })
        }
        else if(this.state.sort==="desc")
        {
            return apps.sort(function(a,b)
            {
                const date_a = a.dateReport===null||a.dateReport===undefined?a.date:a.dateReport; 
                const date_b = b.dateReport===null||b.dateReport===undefined?b.date:b.dateReport; 
                return new Date(date_b) - new Date(date_a);
            })
        }
        else
            return apps.sort(function(a,b)
            {
                return b.id-a.id;
            })
    }
    sortedData=()=>
    {
        if(this.state.appointments.length===0)
            return this.state.appointments;
        else
        {
            var all = [];
            if(this.state.searchWord===""||this.state.searchWord===null||this.state.searchWord===undefined)
                all = this.state.appointments;
            else
            {
                all = this.state.appointments.filter(app=>
                app.clientName.toLowerCase().indexOf(this.state.searchWord.toLowerCase())!==-1
                    );
            }
            if(this.state.sort==="asc")
            {
                
                return all.sort(function(a,b)
                {
                    const date_a = a.dateReport===null||a.dateReport===undefined?a.date:a.dateReport; 
                    const date_b = b.dateReport===null||b.dateReport===undefined?b.date:b.dateReport; 
                    return new Date(date_a) - new Date(date_b);
                })
            }
            else if(this.state.sort==="desc")
            {
                return all.sort(function(a,b)
                {
                    const date_a = a.dateReport===null||a.dateReport===undefined?a.date:a.dateReport; 
                    const date_b = b.dateReport===null||b.dateReport===undefined?b.date:b.dateReport; 
                    return new Date(date_b) - new Date(date_a);
                })
            }
            else
            return all.sort(function(a,b)
            {
                return b.id-a.id;
            })
        }
    }
    //#region Sections.
    setDefaultSection()
    {
        fetch(Apis.GET_CURRENT_USSER_INFO,{headers:AuthorizationHeader()})
            .then(response=>{if(!response.ok) throw new Error("err");return response.json()})
            .then(data=>this.setState({sectionIndex:data.defaultStoreId},this.fetchAppointments))
            .catch(err=>AlertHelper.ShowErrour("Something went wrong"));
            
    }
    //#endregion

    //#region  Fetch Appointments
    onSaveAppointment=(e)=>
    {
        this.setState({
            isCreateUpdateModalOpen : false,
            isProgressModalVisible:false,
            isFinishModalVisible : false,

            IsCancelationModalOpen : false,
            isProgressModalOpen : false,
            isDeleteModalVisible : false,
        },this.fetchAppointments)
    }
    async fetchAppointments(word,state)
    {
        if(word==null)
            word = "";
        var search = {}
        if(this.state.dateRange===null)
        {
            search = 
            {
                word:this.state.word,
                stateIndex:this.state.stateIndex,
                sectionId:this.state.sectionIndex,
                activePage : this.state.activePageChanged===true?this.state.activePage:1,
                limit : this.state.limit
            };
        }
        else
        {
            search = 
            {
                word:this.state.word,
                stateIndex:this.state.stateIndex,
                sectionId:this.state.sectionIndex,
                activePage : this.state.activePageChanged===true?this.state.activePage:1,
                limit : this.state.limit,
                dateRange : {start : this.state.dateRange[0].toExactDate(),end : this.state.dateRange[1].toExactDate()}
            };
        }
        //await fetch("api/appointments/list?word="+word+"&state="+this.state.stateIndex+"&section="+this.state.sectionIndex)
        await fetch(Apis.LIST_APPOINTMENTS,{
        method :"POST",
        headers : AuthorizationHeader(),    
        body :JSON.stringify(search),
        })
        .then(response => {if(!response.ok)throw new Error("Err");return response.json()})
        .then(data=>{
            data.dataSource.forEach(m=>{m.checked=false});
            this.setState({
                appointments:data.dataSource,
                isLoading:false,
                total : data.total,
                activePage :this.state.activePageChanged===true?this.state.activePage:1
            });
            this.setState({activePageChanged : false})
        })
            
        .catch(err=>AlertHelper.ShowErrour("Something went wrong"));
    }
    onShowDeleteModal=(e)=>
    {
        DoEventIfHavingPrivilge("APPOINTMENT_DELETE",()=>{
        const selected = this.state.appointments.filter(m=>m.checked===true);
        if(selected.length===0)
        {
            AlertHelper.ShowWarning("No Appointment is selected");
            return;
        }

        this.setState({isDeleteModalVisible:true});},true);
    }
    onCloseDeleteModal=()=>
    {
        this.setState({isDeleteModalVisible:false});
    }
    onOpenTimeLineModal=(e)=>
    {
        this.setState({
        appointmentId : e.target.getAttribute("data-item"),
        IsTimeLineModalVisible : true});
    }
    onCloseTimeLineModal=(e)=>
    {
        this.setState({IsTimeLineModalVisible : false});
    }
    onChangeStateFilter=(index)=>
    {
        this.setState({stateIndex : index},this.fetchAppointments);
    }
    onChangeSectionFilter=(index)=>
    {
        this.setState({sectionIndex : index},this.fetchAppointments);
    }
    onChangeDateFilter=(range)=>
    {
        this.setState({dateRange : range},this.fetchAppointments);
    }
    onSelectionChanged =(value,e)=>
    {
        const all = this.state.appointments;
        var selected = all.filter(m=>m.id===value)[0];
        var index = all.indexOf(selected);
        all[index].checked=e;
        this.setState({appointments:all});
    }
    onSelectAll=(value)=>
    {
        const all = this.state.appointments;
        all.forEach(element => {
            element.checked=value
        });
        this.setState({appointments:all});
    }
    //#endregion

    //#region Appointment Modal
    onDeleteAppointments=(e)=>
    {
        const selected = this.state.appointments.filter(m=>m.checked===true);
        if(selected.length===0)
            return;
        var ids = [];
        for(var i=0 ; i<selected.length;i++)
        {
            ids[i] = selected[i].id;
        }
        fetch(Apis.DELETE_APPOINTMENT,
        {
            method :"DELETE",
            headers : AuthorizationHeader(),    
            body :JSON.stringify(ids)
        })
        .then(response=>{if(!response.ok) throw new Error(response) ; return response})
        .then(data=>this.setState({isDeleteModalVisible:false},this.fetchAppointments))
        .catch(err=>AlertHelper.ShowErrour("Something went wrong"));
        
    }
    onCreateAppointment=()=>
    {
        DoEventIfHavingPrivilge("APPOINTMENT_ADD",()=>{
        this.setState({appointmentId:0,isCreateUpdateModalOpen:true});},true);
    }
    onUpdateAppointment= (e)=>
    {
        DoEventIfHavingPrivilge("APPOINTMENT_EDIT",()=>{
        if(this.state.appointments.filter(m=>m.checked===true).length===0)
        {
            AlertHelper.ShowWarning("No appointment is selected");
            return;
        }
        else if(this.state.appointments.filter(m=>m.checked===true).length>1)
        {
            AlertHelper.ShowWarning("Only one item can be edited");
        }
        else
        {
            const checked = this.state.appointments.filter(m=>m.checked===true)[0];
            this.setState({appointmentId:checked.id,isCreateUpdateModalOpen:true});
        }},true);
    }
    onCloseCreateUpdateAppointment=()=>
    {
        this.setState({isCreateUpdateModalOpen:false});
    }
    //#endregion

    //#region Printing
    onPrint=()=>
    {
        const selected = this.state.appointments.filter(m=>m.checked===true);
        if(selected.length===0)
        {
            AlertHelper.ShowWarning("No Appointment is selected");
            return;
        }
        const all = this.sortAppointments(selected);
        var ids = [];
        for(var i=0 ; i<all.length;i++)
        {
            ids[i] = all[i].id;
        }
        localStorage.setItem("AppointmentsToPrinting",JSON.stringify(ids));
        this.props.history.push("/printingAppointment");
    }
    //#endregion

    //#region Progress State
    onOpenProgressModal=(e)=>
    {
        DoEventIfHavingPrivilge("APPOINTMENT_TO_PROGRESS",()=>{
        this.setState({
            appointmentId : e.target.getAttribute("data-item"),
            isProgressModalVisible : true,
        })},true);
    }
    onCloseProgressModal=()=>
    {
        this.setState({isProgressModalVisible : false});
    }
    //#endregion

    //#region FinishModal
    onOpenFinishModal=(e)=>
    {
        DoEventIfHavingPrivilge("APPOINTMENT_TO_SOLVED",()=>{
        this.setState({
            appointmentId : e.target.getAttribute("data-item"),
            isFinishModalVisible : true,
        })},true);
    }
    onCloseFinishModal=(e)=>
    {
        this.setState({isFinishModalVisible : false});
    }
    onRefrech=()=>
    {
        this.fetchAppointments();
    }
    //#endregion
    setActivePage=(e)=>
    {
        this.setState({activePageChanged:true,activePage : e},this.fetchAppointments);
    }
    setLimit=(e)=>
    {
        this.setState({limit:e},this.fetchAppointments);
    }
    
    render(){
        let content =this.state.isLoading? <Placeholder.Grid rows={14} columns={7} active />: <TableDetail onTimeLineClick={this.onOpenTimeLineModal}  onWaitingClicked={this.onOpenProgressModal} onPogressClicked={this.onOpenFinishModal} onSelectionChanged={this.onSelectionChanged} appointments = {this.sortedData()} />
        ;
        return (
            <>
            <ToolBar canPrint={true} canDelete={true} onRefrechClick={this.onRefrech} onCreateClick = {this.onCreateAppointment} onUpdateClick={this.onUpdateAppointment} onDeleteClick={this.onShowDeleteModal} onPrintClick={this.onPrint} />
            <AppointmentFilter onChangeDateFilter={this.onChangeDateFilter} onChangeStateFilter={this.onChangeStateFilter} onChangeSectionFilter={this.onChangeSectionFilter} stateIndex={this.state.stateIndex} sectionIndex={this.state.sectionIndex} dateRange={this.dateRange} />
            <TableHeader  onSelectAll={this.onSelectAll} onDateSort={this.changeSort} dateSort={this.state.sort} />
           {content}
           <div className="to-center" style={{marginTop:"10px"}}>
           <Pagination layout={['total', '-', 'limit', '|', 'pager', 'skip']} next={true} prev={true} total={this.state.total} limit={this.state.limit} activePage={this.state.activePage} onChangePage={(e)=>this.setActivePage(e)} onChangeLimit={(e)=>this.setLimit(e)} />;
           </div>
           <CreateUpdateAppointmentModal defaultStartUp={"0"} ref={this.appModal} id={this.state.appointmentId} isCreateUpdateModalOpen = {this.state.isCreateUpdateModalOpen} onCloseModal={this.onCloseCreateUpdateAppointment} onSave={this.onSaveAppointment} />
           <ToProgressModal id={this.state.appointmentId}  isProgressModalVisible = {this.state.isProgressModalVisible} onSave={this.onSaveAppointment} onCloseModal={this.onCloseProgressModal} />
            <ToFinishModal id={this.state.appointmentId} isFinishModalVisible ={this.state.isFinishModalVisible} onSave = {this.onSaveAppointment} onCloseModal={this.onCloseFinishModal} />
            <DeleteConfirmation title="Appointment(s)" onDelete={this.onDeleteAppointments} isDeleteModalVisible={this.state.isDeleteModalVisible} onClose={this.onCloseDeleteModal} />
            <TimeLineModal id={this.state.appointmentId} IsTimeLineModalVisible = {this.state.IsTimeLineModalVisible} onClose={this.onCloseTimeLineModal} />
            </>
        )
    }
}