import React,{ Component } from "react";
import './SideNav.css'
export class SideNav extends Component
{
    render()
    {
        return (
            <>
            <div className="side-nav">
                <div className="side-nav-header">
                    <img src="logo.svg" />
                </div>
                <div className="side-nav-body">
                    <button className="btn btn-primary btn-block">
                            <img src="appointment.svg"/>
                            <span>Appointment</span>
                    </button>
                    <button className="btn btn-primary btn-block">
                            <img src="appointment.svg"/>
                            <span>Calander</span>
                    </button>
                    <button className="btn btn-primary btn-block">
                            <img src="appointment.svg"/>
                            <span>Solved</span>
                    </button>
                    <button className="btn btn-primary btn-block">
                            <img src="appointment.svg"/>
                            <span>Clients</span>
                    </button>
                </div>
                <div className="side-nave-logo">
                    
                </div>
            </div>
            
            </>
        )
    }
}