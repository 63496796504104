import React,{ Component } from "react";
import { Redirect, Route } from "react-router-dom"
import { IsUserLoggedIn } from "../../manager/IsUserLoggedIn";
export default class PrivateRoute extends Component
{   constructor(props)
    {
        super(props);
    }
    componentDidMount(){
        
    }
    render()
    {
        let Content=IsUserLoggedIn()===true?
            (<Route path={this.props.path} component={this.props.component} />)
            :(<Redirect to="/Login" />)
        return (<>{Content}</>)
    }
}