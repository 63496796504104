import React from "react";
import ButtonGroup from "reactstrap/lib/ButtonGroup";
import { Checkbox } from "rsuite";
import { IconButton } from "rsuite";
import './GroupTool.css';
import ReloadIcon from '@rsuite/icons/Reload';
const GroupTools=(props)=>{
    const renderDeleteButton=()=>
    {
        if(props.canDelete!==false)
            return (<IconButton icon={<img src="trash.svg" />} onClick={props.onDeleteClick}/>);
    }
    const renderPrintButton=()=>
    {
        if(props.canPrint===true)
            return (<IconButton icon={<img src="print.svg"  />} onClick={props.onPrintClick} />)
    }
    return(
        <div className="group-tool">
            <div className="group-tool-body">
                <ButtonGroup>
                    <IconButton icon={<ReloadIcon /> } onClick={props.onRefrechClick} />
                    {renderPrintButton()}
                    {renderDeleteButton()}
                    <IconButton icon={<img src="edit.svg"  />} onClick={props.onUpdateClick} />
                </ButtonGroup>
            </div>
        </div>
    )
}
export default GroupTools;