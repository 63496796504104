import React, {Component} from 'react';
import ToolBar from '../Common/ToolBar';
import UserTableDetail from './Components/UserTableDetail';
import UserTableHeader from './Components/UserTableHeader';
import { AuthorizationHeader } from '../../manager/AuthorizationHeader';
import DeleteConfirmation from '../Common/DeleteConfirmation';
import UpdateUserClaimsModal from './Modals/UpdateUserClaimsModal';
import Apis from '../../Helpers/Apis';
import { DoEventIfHavingPrivilge } from '../../manager/IsHavingPrivilege';
import CreateUpdateUser from './Modals/CreateUpdateUser';
import { AlertHelper } from '../../Helpers/AlertHelper';

export default class FetchUsers extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
            isDeleteModalVisible:false,
            IsUpdateUserClaimsModalVisible : false,
            IsCreateUpdateUserModalVisible : false,
            userId : "",
            users : []
        }
    }
    componentDidMount()
    {
        // document.getElementsByClassName("side-bar")[0].style.display="initial";
        // document.getElementsByClassName("header-div")[0].style.display="initial";
        // document.getElementById("title").innerText = "users";
        // document.getElementById("title").style.color = "#DD0E2D";
        this.fetchUsers();
    }
    fetchUsers(){
         fetch(Apis.LIST_USERS,{headers:AuthorizationHeader()})
        .then(response => response.json())
        .then(data=>{data.forEach(m=>{m.checked=false});this.setState({users:data})});
    }
    onModalCreateUser=()=>
    {
        DoEventIfHavingPrivilge("USERS_ADD",()=>{
            this.setState({userId:null,IsCreateUpdateUserModalVisible:true});
        },true)
        //this.setState({userId:0,IsCreateUpdateModalOpen:true});
    }
    onModalUpdateUser=()=>
    {
        // DoEventIfHavingPrivilge("CONTROL_CLAIMS",()=>{
        // const all = this.state.users.filter(m=>m.checked===true);
        // if(all.length===0)
        // {
        //     AlertHelper.ShowWarning("No user is selected");
        //     return;
        // }
        // else if(all.length>1)
        // {
        //     AlertHelper.ShowWarning("only one item can be updated");
        //     return;
        // }
        // this.setState({userId:all[0].id,IsUpdateUserClaimsModalVisible:true});
        // },true)
        
        DoEventIfHavingPrivilge("USERS_ADD",()=>{
        const all = this.state.users.filter(m=>m.checked===true);
        if(all.length===0)
        {
            AlertHelper.ShowWarning("No user is selected");
            return;
        }
        else if(all.length>1)
        {
            AlertHelper.ShowWarning("only one item can be updated");
            return;
        }
        this.setState({userId:all[0].id,IsCreateUpdateUserModalVisible:true});
        },true)
    }
    onModalDeleteUser=()=>
    {
        const selected = this.state.users.filter(m=>m.checked===true);
        if(selected.length===0)
        {
            AlertHelper.ShowWarning("No user is selected");
            return;
        }
        this.setState({isDeleteModalVisible:true});
    }
    onDelete=()=>
    {
        // const selected = this.state.users.filter(m=>m.checked===true);
        // if(selected.length===0)
        //     return;
        // var ids = [];
        // for(var i=0 ; i<selected.length;i++)
        // {
        //     ids[i] = selected[i].id;
        // }
        // fetch("api/Authenticate/list",
        // {
        //     method :"DELETE",
        //     headers : AuthorizationHeader(),    
        //     body :JSON.stringify(ids)
        // })
        // .then(response=>{if(!response.ok) throw new Error(response) ; return response})
        // .then(data=>this.setState({isDeleteModalVisible:false},this.fetchUsers))
        // .catch(err=>alert(err));
    }
    onCloseDeleteModal=()=>
    {
        this.setState({isDeleteModalVisible:false});
    }
    onCloseUpdateModal=()=>
    {
        this.setState({IsUpdateUserClaimsModalVisible : false,IsCreateUpdateUserModalVisible : false});
    }
    onSave=()=>
    {
        this.setState({IsUpdateUserClaimsModalVisible : false,IsCreateUpdateUserModalVisible : false},this.fetchUsers);
    }
    onSelectAll=(value)=>
    {
        const all = this.state.users;
        all.forEach(element => {
            element.checked=value
        });
        this.setState({users:all});
    }
    onSelectionChanged =(value,e)=>
    {
        const all = this.state.users;
        var selected = all.filter(m=>m.id===value)[0];
        var index = all.indexOf(selected);
        all[index].checked=e;
        this.setState({users:all});
    }
    renderTableDetails()
    {
        return(
            // <Grid fluid>
                <UserTableDetail users = {this.state.users} onSelectionChanged={this.onSelectionChanged} />
            // </Grid>
        )
    }
    onRefrech =()=>
    {
        this.fetchUsers();
    }
    render()
    {
        let content = this.renderTableDetails();
        return(
        
        <>
            <ToolBar onRefrechClick={this.onRefrech} onCreateClick = {this.onModalCreateUser} onUpdateClick={this.onModalUpdateUser} onDeleteClick={this.onModalDeleteUser} />
            <UserTableHeader onSelectAll = {this.onSelectAll}   />
            {content}
            {/* <UpdateUserClaimsModal id={this.state.userId} IsUpdateUserClaimsModalVisible = {this.state.IsUpdateUserClaimsModalVisible} onSave={this.onSave} onClose={this.onCloseUpdateModal} /> */}
            <CreateUpdateUser id={this.state.userId} IsCreateUpdateUserModalVisible ={this.state.IsCreateUpdateUserModalVisible} onSave={this.onSave} onClose = {this.onCloseUpdateModal} />
            {/* <CreateUpdateUserModal id={this.state.userId} isModalOpen={this.state.IsCreateUpdateModalOpen} onSave={this.onSave} onCloseModal={this.onCloseCreateUpdateModal} /> */}
            {/* <DeleteConfirmation title="User(s)" isDeleteModalVisible = {this.state.isDeleteModalVisible} onClose={this.onCloseDeleteModal} onDelete={this.onDelete} /> */}

        </>
        )
    }
}