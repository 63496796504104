import React from 'react';
import { FlexboxGrid, Form, Modal,Button,DatePicker } from 'rsuite';
import { Schema } from 'rsuite';
import Apis from '../../../Helpers/Apis';
import {AlertHelper} from '../../../Helpers/AlertHelper'
import {AuthorizationHeader} from '../../../manager/AuthorizationHeader';
const dateSelector = React.forwardRef((props, ref) => <DatePicker size="md" format="dd-MM-yyyy"    {...props}  ref={ref}  block />);
const timeSelector = React.forwardRef((props, ref) => <DatePicker size="md" format="hh:mm aa"   {...props}  ref={ref}  block />);

const { StringType, NumberType,DateType } = Schema.Types;
const ToWaitingModal = (props) => {
    const model = Schema.Model({
        date: DateType().isRequired("Date is required"),
        time: DateType().isRequired("Time is required"),
        });
    const [date,setDate]=React.useState(null);
    const [time,setTime] = React.useState(null);
    const [isLoading,setIsLoading] = React.useState(false);
    const formRef = React.useRef();
    const formValue =
    {
        date : date,
        time : time,
    }
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        color :"#89919B",
        backgroundColor : "#D2D2D2",
        fontWeight : "bold"
    }
    const onClose=()=>
    {
        props.onClose(false);
    }
    const onSave=()=>
    {
        if(!formRef.current.check())
            return;
            setIsLoading(true);
        const data= 
        {
            id : props.id,
            date:date.toExactDate(),
            time:time.toExactDate(),
        };
        fetch(Apis.UPDATE_APPOINTMENT_TO_WAITING,{
            headers:AuthorizationHeader(),
            method:"PUT",
            body : JSON.stringify(data)
        })
        .then(response=>{if(!response.ok)throw new Error(); return response;})
        .then(data=>{setIsLoading(false);props.onClose(true);})
        .catch(err=>{AlertHelper.Error("Something went wrong");setIsLoading(false);})

    }
    return(
        <Modal open={props.IsWaitingModalVisible} onClose={props.onClose} className="custom-modal" backdrop="static">
            <Modal.Header>
                Transfer to Waiting
            </Modal.Header>
            <Modal.Body>
                <Form fluid model={model} ref={formRef} checkTrigger="change" formValue={formValue}>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={12}>
                        <Form.Group>
                            <Form.ControlLabel id='date'>Date</Form.ControlLabel>
                            <Form.Control name="date" size="md" accepter={dateSelector} placeholder="Date" value={date} onChange={e=>setDate(e)} block />
                        </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={1}>
                        
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={11}>
                    <Form.Group>
                            <Form.ControlLabel id='time'>Time</Form.ControlLabel>
                            <Form.Control name="time" size="md" accepter={timeSelector} placeholder="Time" value={time} onChange={e=>setTime(e)} block />
                        </Form.Group>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                </Form>  
            </Modal.Body>
            <Modal.Footer>
            <FlexboxGrid>
            <FlexboxGrid.Item colspan={12} className="to-right">
                                    <Button appearance="default" size="md" style={cancelButton} disabled={isLoading} onClick={onClose}>CANCEL</Button>    
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={1} > 

                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={11}>
                                    <Button color="red" appearance="primary" block size="md" style={saveButton} onClick={onSave} loading={isLoading}>
                                        TRANSFERT
                                    </Button>   
                </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    )
}
export default ToWaitingModal;