import React, { Component, useState } from 'react';
import { Schema } from 'rsuite';
import {Redirect} from 'react-router-dom';
import { Button, Form,Checkbox } from 'rsuite';
import './Login.css';
import $ from 'jquery';
import Apis from '../../Helpers/Apis';
import { IsUserLoggedIn } from '../../manager/IsUserLoggedIn';

const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  password: StringType().isRequired('This field is required.').minLength(5,"password must be 5 letters or more"),
  loginName: StringType()
    .isRequired('This field is required.')
});
export class Login extends  Component
{
    constructor(props)
    {
        super(props);
        this.state=
        {
            username:"",
            password:"",
            showInvalidPassword : false,
            isRedirect : false,
            isLoading : false,
            rememberMe : false,
        }
        this.formref = React.createRef(null);
    }

    componentDidMount()
    {
        document.getElementsByClassName("side-bar")[0].style.display="none";
        document.getElementsByClassName("header-div")[0].style.display="none";
    }
    onSubmit=async ()=>
    {
        if(!this.formref.current.check())
        {
            return;
        }
        else
        {
            this.setState({isLoading:true},await this.login)
        }
    }
    login =async()=>
    {
        // alert(Apis.LOGIN);
        const response = await fetch(Apis.LOGIN,{
                    method : "POST",
                    headers : {'Content-Type':'application/json'},
                    body : JSON.stringify({
                        loginName : this.state.username,
                        password : this.state.password
                    })
                });
        if(!response.ok)
                this.setState({showInvalidPassword:true,isLoading:false})
        else
        {
            const result = await response.json();
            localStorage.setItem("token",result.token);
            localStorage.setItem("claims",JSON.stringify(result.claims));
            // if(this.state.rememberMe===false)
            // {
            //     // window.onunload = function(){
            //     //     alert("The window is closing now!");
            //     // }
            //     window.addEventListener("beforeunload", () => { localStorage.clear();});
            // }
            var a = document.createElement("a");
            a.href="/appointments";
            a.click();
            //window.open("/appointments");
            // this.setState({isRedirect:true});
        }
    }
    render(){
        let errourPassword = this.state.showInvalidPassword===true?(<p style={{color:"red"}}>Incorrect Password or Login Name</p>):(<></>);
        if(IsUserLoggedIn())
            return (<Redirect to="/appointments" />);

    return (
        <div className='login-div'>
            <div className='login-div-corners'>
                <img src='left-corner.svg' />
                <img src='right-corner.svg' />
            </div>
            <div className='login-form'>
                <Form fluid ref={this.formref} model={model}>
                    <Form.Group>
                        <img src='logo.svg'/>
                    </Form.Group>
                    <Form.Group>
                        <h3>Login</h3>
                    </Form.Group>
                    <Form.Group controlId='loginName'>
                        <Form.Control  name="loginName"  size="lg" placeholder='User Name' value={this.state.username} onChange={e=>this.setState({username:e})} />
                    </Form.Group>
                    <Form.Group controlId='password'>
                        <Form.Control name="password"  size="lg" placeholder='Password' value={this.state.password} onChange={e=>this.setState({password:e})} type="password" autoComplete='off' />
                    </Form.Group>
                    <Form.Group>
                        <Checkbox checked={this.state.rememberMe} onChange={(e,c)=>{this.setState({rememberMe : c})}}>Remember me</Checkbox>
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>{errourPassword}</Form.ControlLabel>
                        <Button color="red" type='submit' size="lg" appearance='primary' block onClick={this.onSubmit} loading={this.state.isLoading}>LOGIN</Button>
                    </Form.Group>
                    <Form.Group>
                        <a href="/restorePassword">Restore your password</a>
                    </Form.Group>
                </Form>
            </div>
            <div>

            </div>
        </div>
        // <div className='login-form'>
        //     <form onSubmit={submit}>
        //     <div className="form-group">
        //         <label htmlFor="exampleInputEmail1">Email address</label>
        //         <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" onChange={e=>SetEmail(e.target.value)} required />
        //     </div>
        //     <div className="form-group">
        //         <label htmlFor="exampleInputPassword1">Password</label>
        //         <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" onChange={e=>SetPassword(e.target.value)} required  />
        //     </div>
        //     <div className="form-group form-check">
        //         <input type="checkbox" className="form-check-input" id="exampleCheck1" />
        //         <label className="form-check-label" for="exampleCheck1">Check me out</label>
        //     </div>
        //     <button type="submit" className="btn btn-primary">Submit</button>
        // </form>
      //</div>
    )
}
}