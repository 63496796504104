import React from "react";
import { Modal,Form,FlexboxGrid,Button,Input,Loader } from "rsuite";
import {AuthorizationHeader} from '../../../manager/AuthorizationHeader';
import Apis from '../../../Helpers/Apis';

import { Schema } from 'rsuite';
import { AlertHelper } from "../../../Helpers/AlertHelper";

const textArea = React.forwardRef((props, ref) => <Input as="textarea" size="md"    {...props}  ref={ref}  block />);
const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  name: StringType().isRequired('This field is required.'),
  adresse: StringType().isRequired('This field is required.'),
});

const CreateUpdateStoreModal =(props)=>
{
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        color :"#89919B",
        backgroundColor : "#D2D2D2",
        fontWeight : "bold"
    }
    const [name,setName] = React.useState("");
    const [adresse,setAdresse] = React.useState("");
    const [isLoading,setIsLoading] = React.useState("");
    const [isFinished,setIsFinished] = React.useState(false);
    const formRef = React.useRef();
    const formValue = 
    {
        name : name,
        adresse : adresse
    };

    const initiaNewStore =()=>
    {
        setName("");
        setAdresse("");
    }
    const findSelectedStore=()=>
    {
        fetch(Apis.FIND_SECTION+"?id="+props.id,{headers:AuthorizationHeader()})
        .then(response=>response.json())
        .then(data=>{
            setName(data.name);
            setAdresse(data.adresse);
        });
    }
    const onModalOpen=()=>
    {
        setIsFinished(false);
        setIsLoading(false);
        if(props.id===0)
        {
            initiaNewStore();
        }
        else
        {
            findSelectedStore();
        }
        setIsFinished(true);
    }
    const onSaveStore=()=>
    {
        if(!formRef.current.check())
            return
        setIsLoading(true);
        const api = props.id===0? Apis.CREATE_SECTION : Apis.UPDATE_SECTION;
        const method = props.id===0?"POST" : "PUT";
        const data = JSON.stringify({
            id:props.id,
            name,
            adresse
        })
        fetch(api,{
            method:method,
            headers : AuthorizationHeader(),
            body:data
        })
        .then(response=>{if(!response.ok) throw new Error(response) ;return response;})
        .then(data =>{
            props.onSave();
        })
        .catch(err=>{AlertHelper.ShowErrour("Something went wrong");setIsLoading(false);})
    }
    const onClose=()=>
    {
        setIsFinished(false);
        props.onCloseModal();
    }
    return(
        <Modal open={props.isModalOpen} onEntered={onModalOpen} onClose={onClose} backdrop="static" className="custom-modal">
            <Modal.Header>
                <h5>{props.id===0?"Add an Store": "Update Store"}</h5>
            </Modal.Header>
            <Modal.Body>
                {isFinished===false?(<div style={{ textAlign: 'center' }}><Loader size="md" /></div>) : (  
                <Form fluid model={model} formValue={formValue} ref={formRef}>
                            <Form.Group controlId="name" block>
                                        <Form.ControlLabel>Name</Form.ControlLabel>
                                        <Form.Control name="name" size="md" placeholder="Name" value={name} onChange={e=>setName(e)} block autoComplete="off" />
                            </Form.Group>
                            <Form.Group controlId="adresse">
                                        <Form.ControlLabel>Adresse</Form.ControlLabel>
                                        <Form.Control name="adresse" accepter={textArea} placeholder="Adresse" value={adresse}  onChange={e=>setAdresse(e)} autoComplete="off" />
                            </Form.Group>
                </Form>
                )}
            </Modal.Body>
            <Modal.Footer>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={11} className="to-right">
                                        <Button appearance="default" size="md" block style={cancelButton} disabled={isLoading} onClick={onClose}>BACK</Button>    
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={1} > 

                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={12}>
                                        <Button color="red" appearance="primary" block size="md" style={saveButton} loading={isLoading} onClick={onSaveStore}>
                                            {props.id===0?"ADD":"SAVE"}
                                        </Button>   
                </FlexboxGrid.Item>
            </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    )
}
export default CreateUpdateStoreModal;