import { Form, Modal,FlexboxGrid,Button,Input } from "rsuite";
import React, { useState } from "react";
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
const ConfirmRollBack =(props)=>
{
    const [reason,setReason] = useState("");
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        color :"#89919B",
        backgroundColor : "#D2D2D2",
        fontWeight : "bold"
    }
    const note={
        height:150
    }
    const header=
    {
        width:"100%",
        textAlign : "center"
    }
    const icon =
    {
        width :"30px",
        height :"30px",
        marginLeft : "260px",
        marginBottom : "15px",
        textAlign : "center"
    }
    const onRollBack =(e)=>
    {
        onClose(true,reason);
    }
    const onOpen=()=>
    {
        setReason("");
    }
    const onClose=(isValidated,cancelReason)=>
    {
        props.onCloseModal(isValidated,cancelReason);
    }
    return(
        <Modal open={props.IsRollBackModalOpen} className="custom-modal" onClose={onClose} onOpen={onOpen} backdrop="static">
            <Modal.Header>
                <h5>Rolling back</h5>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to rollback this appointment?</p>
            </Modal.Body>
            <Modal.Footer>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={11} className="to-right">
                                    <Button appearance="default" size="lg" block style={cancelButton} onClick={onClose}>CANCEL</Button>    
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={2} > 

                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={11}>
                                    <Button color="red" appearance="primary" block size="lg" style={saveButton} onClick={onRollBack}>
                                        ROLLBACK
                                    </Button>   
                </FlexboxGrid.Item>
            </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    )
}
export default ConfirmRollBack;