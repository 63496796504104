import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import  {Login}  from './components/Authentification/Login'
import './custom.css'
import {Register} from './components/Authentification/Register';
import FetchSections from './components/Stores/FetchSections';
import {FetchAppointments} from './components/Appointments/FetchAppointments';
import { TechLayout } from './components/Layout/TechLayout';
import Calendar from './components/Calendars/Calendar';
import {FClients} from './components/Clients/FCllients';
import { FSolved } from './components/Appointments/fSolved';
import FEmployee from './components/Employees/FEmployee';
import PrivateRoute from './components/Layout/PrivateRoute';
import FetchScores from './components/Scores/FetchScores';
import FetchUsers from './components/Users/FetchUsers';
import PrintAppointments from './components/Printing/PrintAppointments';
import { PrintClientHistory } from './components/Printing/PrintClientHistory';
import PrivilegeRoute from './components/Layout/PrivilegeRoute';
import { updatePassword } from './components/Authentification/updatePassword';
import Apis from './Helpers/Apis';
import { IsUserLoggedIn } from './manager/IsUserLoggedIn';
import {NotFound} from './components/Common/NotFound';
import PrintPreviewInvoice from './components/Printing/PrintPreviewInvoice';
import { RestorePassword } from './components/Authentification/RestorePassword';
import AccessDenied from './components/Common/AccessDenied';
import { IsAccessDenied}  from './manager/IsAccessDenied';
import { FetchOffers } from './components/Appointments/FetchOffers';
export default class App extends Component {
  static displayName = App.name;
  timer() 
  {
    try
    {
      if(IsUserLoggedIn())
      {
        fetch(Apis.GET_USER_REQUIRE_LOGIN,{headers :{'Authorization' :'Bearer  '+localStorage.getItem("token")}})
        .then(reponse=>{return reponse.text()})
        .then(text=>{
          if(text==="true")
          {
            localStorage.setItem("token",null);
            localStorage.setItem("claims",null);
            var a = document.createElement("a");
            a.href="/login";
            a.click();
          }
        })
        .catch(err=>{});
      }
    }
    catch
    {

    }
  }
  
  componentDidMount() 
  {
    this.intervalId = setInterval(this.timer.bind(this), 5000);
  }
  componentWillUnmount(){
    clearInterval(this.intervalId);
  }
  render () {
    return (
      <TechLayout>
        <Route>
        <Switch>
          <PrivateRoute path="/counter" component={Counter} />
          <PrivateRoute exact path='/' component={IsAccessDenied("APPOINTMENT_LIST",FetchAppointments)} />
          {/* <PrivateRoute path='/counter' component={Counter} /> */}
          <PrivateRoute path='/clients' component={IsAccessDenied("CLIENT_LIST",FClients)} />
          {/* <PrivateRoute path='/stores' component={FetchSections} /> */}
          <PrivateRoute path='/appointments' component={IsAccessDenied("APPOINTMENT_LIST",FetchAppointments)} />
          <PrivateRoute path='/offers' component={IsAccessDenied("APPOINTMENT_LIST",FetchOffers)} />
          <PrivateRoute path='/solved' component={IsAccessDenied("APPOINTMENT_LIST",FSolved)} />
          <PrivateRoute path='/calendar' component={IsAccessDenied("APPOINTMENT_IN_CALENDAR",Calendar)} />
          {/* <PrivateRoute path='/employees' component={FEmployee} /> */}
          {/* <PrivateRoute path='/scores' component={FetchScores} /> */}
          <PrivateRoute path='/users' component={FetchUsers} />
          <PrivateRoute path="/printingAppointment" component={PrintAppointments} />
          <PrivateRoute path="/printClientHistory" component={PrintClientHistory} />
          <PrivateRoute path="/updatePassword" component={updatePassword} />
          <PrivateRoute path="/printInvoice/:id" component={PrintPreviewInvoice} />
          <Route path="/login" component={Login}/>
          
          <Route path="/restorePassword" component={RestorePassword} />
          <Route component={NotFound} />
          {/* <PrivilegeRoute path="/register" component={Register}/> */}
        </Switch>
        </Route>
        {/* <Route path='/account/login' component={Login} />
        <Route path='/account/Register' component={Register} /> */}
      </TechLayout>
    );
  }
}
