import React,{ Component } from "react";
import { Col, Row } from "rsuite";
import CalendarColumn from "./CalendarColumn";
import CalendarNavigation from './CalendarNavigation';
import './Calendar.css'
import { AuthorizationHeader } from "../../manager/AuthorizationHeader";
import Apis from "../../Helpers/Apis";
export default class Calendar extends Component
{
    constructor(props)
    {
        super(props);
        this.state ={
            storeId : -1,
            stores : [],
            currentDay : new Date(),
            day1:{date:new Date(),appointments:[]},
            day2:{date:new Date(),appointments:[]},
            day3:{date:new Date(),appointments:[]},
            day4:{date:new Date(),appointments:[]},
            day5:{date:new Date(),appointments:[]},
            day6:{date:new Date(),appointments:[]},
        }
        this.fetchStores();
        this.setDefaultStore();
        //this.effectAppointments();
        
    }
    componentDidMount()
    {
        document.getElementsByClassName("side-bar")[0].style.display="initial";
        document.getElementsByClassName("header-div")[0].style.display="initial";
        document.getElementById("title").innerText = "calendar";
        document.getElementById("title").style.color = "#DD0E2D";
    }
    setDefaultStore=()=>
    {
        fetch(Apis.GET_CURRENT_USSER_INFO,{headers:AuthorizationHeader()})
        .then(response=>{if(!response.ok) throw new Error("err");return response.json()})
        .then(data=>this.setState({storeId :data.defaultStoreId},this.effectAppointments))
        .catch(err=>alert(err));
    }
    fetchStores(){
        fetch(Apis.LIST_SECTIONS,{headers : AuthorizationHeader()})
        .then(response=>response.json())
        .then(data=>this.setState({stores:data}));
    }
    effectAppointments=async ()=>
    {
        const current = this.state.currentDay;
        const month = current.getMonth()+1;
        const year = current.getFullYear();
        const d1= new Date(current);
        const d2= new Date(current);;
        const d3= new Date(current);;
        const d4= new Date(current);
        const d5= new Date(current);;
        const d6= new Date(current);;

        d1.setDate(d1.getDate()+0);
        d2.setDate(d2.getDate()+1);
        d3.setDate(d3.getDate()+2);
        d4.setDate(d4.getDate()+3);
        d5.setDate(d5.getDate()+4);
        d6.setDate(d6.getDate()+5);

        const dataD1 = await this.getAppointments(d1.getDate(),d1.getMonth()+1,d1.getFullYear());
        const dataD2 = await this.getAppointments(d2.getDate(),d2.getMonth()+1,d2.getFullYear());
        const dataD3 = await this.getAppointments(d3.getDate(),d3.getMonth()+1,d3.getFullYear());
        const dataD4 = await this.getAppointments(d4.getDate(),d4.getMonth()+1,d4.getFullYear());
        const dataD5 = await this.getAppointments(d5.getDate(),d5.getMonth()+1,d5.getFullYear());
        const dataD6 = await this.getAppointments(d6.getDate(),d6.getMonth()+1,d6.getFullYear());
      
        this.setState({
            day1 : {date : d1,appointments : dataD1},
            day2 : {date : d2,appointments : dataD2},
            day3 : {date : d3,appointments : dataD3},
            day4 : {date : d4,appointments : dataD4},
            day5 : {date : d5,appointments : dataD5},
            day6 : {date : d6,appointments : dataD6},
        })
    }
    onStoreChanged=(e)=>
    {
        this.setState({storeId:e},this.effectAppointments);
    }
    async getAppointments (day,month,year)
    {
        const store = this.state.storeId===null?-1:this.state.storeId;
        var resultFetch = await fetch(Apis.FIND_APPOINTMENTs_BY_PERIOD+"?day="+day+"&month="+month+"&year="+year+"&sectionId="+store,{headers:AuthorizationHeader()});
        var resultAppointments = await resultFetch.json();
        return  resultAppointments;
    }
    next = async()=>
    {
        var current = this.state.currentDay;
        current = current.setDate(current.getDate()+6);
        current =new Date(current);
        this.setState({currentDay : current}, this.effectAppointments);
    }
    previous =async ()=>
    {
        var current = this.state.currentDay;
        current = current.setDate(current.getDate()-6);
        current =new Date(current);
        this.setState({currentDay : current}, this.effectAppointments);
    }
    read=()=>{
        
      this.state.day1.appointments.forEach(element => {
        alert("s");
      });
    }
    onRefrech=()=>
    {
        this.effectAppointments();
    }
    render(){
    return (
        <>
            <div className='column-navigation'>
                <CalendarNavigation storeId={this.state.storeId} stores={this.state.stores} onStoreChanged={this.onStoreChanged} date={this.state.currentDay} next={this.next} previous = {this.previous} />
            </div>
            <Col xs={4}>
                <CalendarColumn onRefrech={this.onRefrech} store={this.state.storeId} day={this.state.day1} />
            </Col>
            <Col xs={4}>
                <CalendarColumn onRefrech={this.onRefrech} store={this.state.storeId} day={this.state.day2} />
            </Col>
             <Col xs={4}>
                <CalendarColumn onRefrech={this.onRefrech} store={this.state.storeId} day={this.state.day3} />
            </Col>
            
            <Col xs={4}>
                <CalendarColumn onRefrech={this.onRefrech} store={this.state.storeId} day={this.state.day4} />
            </Col>
            <Col xs={4}>
                <CalendarColumn onRefrech={this.onRefrech} store={this.state.storeId} day={this.state.day5} />
            </Col>
            <Col xs={4}>
                <CalendarColumn onRefrech={this.onRefrech} store={this.state.storeId} day={this.state.day6} />
            </Col>
        </>
    )
    }
}
