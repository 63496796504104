import React from 'react';
const AccessDenied=()=>
{
    return (
        <div style={{
            width:"90%",
            height:"100%",
            backgroundImage:"url('AccessDeniedError.png')",
            backgroundPosition:"center",
            backgroundRepeat:"no-repeat"}}>
        </div>
    )
}
export default AccessDenied;