import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {Modal,Button,FlexboxGrid,Uploader} from 'rsuite'
import Apis from '../../Helpers/Apis';
import { AuthorizationHeader } from '../../manager/AuthorizationHeader';
const RestoreModal =(props)=>
{
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        color :"#89919B",
        backgroundColor : "#D2D2D2",
        fontWeight : "bold"
    }
    const history = useHistory();
    const [isLoading,setIsLoading] = useState(false);
    const [fileSelected, setFileSelected] = useState();
    
    const saveFileSelected= (e) => {
        setFileSelected(e.target.files[0]);
      };
    const onRestore = () => {
        setIsLoading(true);
        var data = new FormData();
        data.append("file",fileSelected);
        fetch(Apis.RESTORE,
        {
            headers:{'Authorization' :'Bearer  '+localStorage.getItem("token")},
            body : data,
            method : "POST"
        })
        .then(response=>{if(!response.ok) throw new Error("err"); return response;})
        .then(data=>window.location.reload())
        .catch(err=>{setIsLoading(false); alert("Something went wring, please try again")});
    };
    const onOpen=()=>
    {
        setIsLoading(false);
    }
    const onClose=()=>
    {
        if(isLoading!==true)
            props.onClose();
        else
            alert("Backing up is in process");
    }
  
    return(
        <Modal open={props.IsRestoreModalVisible} onClose={onClose} onOpen={onOpen} backdrop="static">
            <Modal.Header>
                <p>Restore your data</p>
            </Modal.Header>
            <Modal.Body>
                <p>
                To restore your data, select a Backup and click on "Restore" and wait for the process to finish.
                </p>
                <br/>
                <p>Once it starts Restoring, you cant cancel the operation</p>
                <hr />
                <input type="file" onChange={saveFileSelected} />

            </Modal.Body>
            <Modal.Footer>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={11} className="to-right">
                                        <Button appearance="default" size="lg" block style={cancelButton} onClick={onClose} disabled={isLoading}>BACK</Button>    
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={1} > 

                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={12}>
                                        <Button color="red" appearance="primary" block size="lg" style={saveButton} onClick={onRestore} disabled={fileSelected===undefined} loading={isLoading}>
                                            RESTORE
                                        </Button>   
                </FlexboxGrid.Item>
            </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    )
}
export default RestoreModal;