import React, { useImperativeHandle, useState } from 'react';
import {Button, FlexboxGrid, Form, InputNumber, Modal,Panel,SelectPicker,Drawer, DatePicker,Loader} from 'rsuite';
import TableHeader from '../Components/TableHeader';
import Apis from '../../../Helpers/Apis';
import { AuthorizationHeader } from '../../../manager/AuthorizationHeader';
import {AlertHelper} from '../../../Helpers/AlertHelper'
import TableDetail from '../Components/TableDetail';
import { Schema } from 'rsuite';
import DeleteConfirmation from '../../Common/DeleteConfirmation';

const { StringType, NumberType,DateType } = Schema.Types;
const model = Schema.Model({
code: StringType().isRequired("code is required"),
date: DateType().isRequired("Date is required"),
});
const productSelector = React.forwardRef((props, ref) => <SelectPicker size="md" valueKey='id' labelKey='name'    {...props}  ref={ref}  block />);
const datePicker = React.forwardRef((props,ref)=><DatePicker size="md" placement="autoVerticalStart"  format="yyyy-MM-dd HH:mm" block {...props} />);
const CreateUpdateInvoiceModal =React.forwardRef((props,ref)=>
{
    useImperativeHandle(ref,()=>({
        initDataSource : initDataSource
    }),[])
    const [id,setId] = React.useState(0);
    const [code,setCode] = React.useState("");
    const [date,setDate] = React.useState(new Date());
    const [time,setTime] = React.useState(null);
    const [details,setDetails] = React.useState([]);
    const [totalTheorique,setTotalTheorique] = React.useState(0);
    const [totalReal,setTotalReal] = React.useState(0);
    const [allProducts,setAllProducts] = React.useState([]);
    const [selectedPrice,setSelectedPrice] = React.useState(0);
    const [selectedQuantity,setSelectedQuantity] = React.useState(0);
    const [selectedProduct,setSelectedProduct] = React.useState(null);
    const [selectedRef,setSelectedRef] = React.useState("");
    const [isOpen,setIsOpen] = React.useState(false);
    const [isLoading,setIsLoading] = React.useState(false);
    const [isUpdateMode,setIsUpdateMode] = React.useState(false);
    const [canPrint,setCanPrint] = React.useState(false);
    const [isFetching,setIsFetching] = React.useState(false);
    const [hasErrors,setHasErrors] = React.useState(false);
    const [errors,setErrors] = React.useState("");
    const [isDeleteModalVisible,setIsDeleteModalVisible] = React.useState(false);
    const refQuantity = React.useRef();
    const formRef = React.useRef();
    const formValue={
        code :code,
        date : date
    }
    const productsRef = React.useRef();

    const initDataSource=(invoiceId)=>
    {
        setIsLoading(true);
        setIsUpdateMode(false);
        fetch(Apis.LIST_PRODUCTS,{headers : AuthorizationHeader()})
        .then(response=>{if(!response.ok) throw new Error(response.status); return response.json()})
        .then(data=>setAllProducts(data))
        .catch(err=>{
        if(err.toString().indexOf("403")!==-1)
        {
            setHasErrors(true);
            setErrors("You must have the privilege of List Products to update this content");
            AlertHelper.ShowErrour("You must have the privilege of List Products to update this content");
        }
        else    
            AlertHelper.ShowErrour("Something went wrong");
        });

        if(invoiceId===null||invoiceId===undefined||invoiceId===0||invoiceId==="0")
        {
            setCode("");
            setId(0);
            setDate(new Date());
            setDetails([]);
            setSelectedPrice(0);
            setSelectedProduct(null);
            setSelectedQuantity(1);
            setCanPrint(false);
            
        }
        else
        {
            fetch(Apis.FIND_INVOICE+"?id="+invoiceId,{headers:AuthorizationHeader()})
            .then(response=>{if(!response.ok) throw new Error();return response.json()})
            .then(data=>
                {
                    setId(data.id);
                    setCode(data.code);
                    setDate(new Date(data.date));
                    setDetails(data.details);
                    setSelectedPrice(0);
                    setSelectedProduct(null);
                    setSelectedQuantity(1);
                    setCanPrint(true);
                })
            .catch(err=>AlertHelper.ShowErrour("Something went wrong"))
        }
        if(!hasErrors){
            setIsOpen(true);
            setIsLoading(false);
        }
     
    }
    const onAddProuct =()=>
    {
        if(hasErrors)
        {
            AlertHelper.ShowErrour(errors);
            return;
        }
        if(isUpdateMode)
        {
            const frech=[];
            details.forEach(element=>
            {
                if(element.productId===selectedProduct)
                {
                        element.qteSortie = selectedQuantity;
                        element.price = selectedPrice;
                }
                frech.push(element);
            })
            setDetails(frech);
            setSelectedProduct(null);
            setSelectedPrice(0);
            setSelectedQuantity(1);
            setSelectedRef("");
            setIsUpdateMode(false);
            setCanPrint(false);
            document.getElementById("refCode").focus();
            return;
        }
        if(selectedProduct===null||selectedProduct===undefined)
        {
            AlertHelper.ShowWarning("Please select a product first");
            return ;
        }
        if(details.filter(m=>m.productId===selectedProduct).length>0)
        {
            if(details.filter(m=>m.productId===selectedProduct)[0].isDeleted===true)
            {
                const frech=[];
                details.forEach(element=>
                {
                    if(element.productId===selectedProduct)
                    {
                        element.isDeleted = false;
                        element.quantity = 1;
                        element.price = allProducts.filter(m=>m.id===selectedProduct)[0].price;
                    }
                    frech.push(element);
                })
                setDetails(frech);
                setSelectedProduct(null);
                setSelectedPrice(0);
                setSelectedQuantity(1);
                setSelectedRef("");
                setCanPrint(false);
                document.getElementById("refCode").focus();
                return;
            }
            else
            {
                AlertHelper.ShowWarning("Product already exists");
                return ;
            }
           
        }
        const all = details;
        all.splice(0,0,{
            invoiceId : 0,
            productId : selectedProduct,
            price : selectedPrice,
            qteSortie : selectedQuantity,
            isDeleted : false,
        })
        setDetails(all);
        setSelectedProduct(null);
        setSelectedPrice(0);
        setSelectedRef("");
        setSelectedQuantity(1);
        setCanPrint(false);
        document.getElementById("refCode").focus();
    }
    const onSelectedProductChanged=(value)=>
    {
        setSelectedProduct(value);

        if(allProducts.filter(m=>m.id===value).length>0)
            setSelectedPrice(allProducts.filter(m=>m.id.toString()===value.toString())[0].price);
        else setSelectedPrice(0);
    }
    const onDeleteProduct=(e,productId)=>
    {
        if(hasErrors)
        {
            AlertHelper.ShowErrour(errors);
            return;
        }
        e.preventDefault();
        if(isUpdateMode)
            return;
        const frech = [];
        details.forEach(element => {
            
            if(element.productId===productId)
                element.isDeleted = true;
            frech.push(element);
        });
        setDetails(frech);
        setCanPrint(false);
    }
    const onUpdateProduct=(e,detail)=>
    {
        if(hasErrors)
        {
            AlertHelper.ShowErrour(errors);
            return;
        }
        e.preventDefault();
        setIsUpdateMode(true);
        setSelectedProduct(detail.productId);
        setSelectedQuantity(detail.qteSortie);
        setSelectedPrice(detail.price);
        setCanPrint(false);
    }
    const onOpen =()=>
    {
        fetch(Apis.LIST_PRODUCTS,{headers : AuthorizationHeader()})
        .then(response=>{if(!response.ok) throw new Error(); return response.json()})
        .then(data=>setAllProducts(data))
        .catch(err=>AlertHelper.ShowErrour("Something went wrong"));
    }
    const onSave =()=>
    {
        if(hasErrors)
        {
            AlertHelper.ShowErrour(errors);
            return;
        }
        if(!formRef.current.check())
            return;
        if(details.filter(m=>m.isDeleted===false).length===0)
        {
            AlertHelper.ShowWarning("There is no product in this invoice, please add one or close the window");
            return;
        }
        setIsFetching(true);
        var data ={
            id : id,
            code : code,
            date : new Date(date).toExactDate(),
            details : details
        };
        const api = id===0?Apis.CREATE_INVOICE :Apis.UPDATE_INVOICE;
        const method = id===0?"POST" : "PUT";
        fetch(api,{
            headers : AuthorizationHeader(),
            method : method,
            body : JSON.stringify(data)
        })
        .then(response=>{if(!response.ok) throw new Error();return response.json()})
        .then(data=>{AlertHelper.ShowSuccess(id===0?"Invoice created successfully":"Invoice Updated successfully");setCanPrint(true);initDataSource(data.id);props.onInvoiceUpdated(data.id);setIsFetching(false)})
        .catch(err=>{AlertHelper.ShowErrour("Something went wrong");setIsFetching(false)});
    }
    const deleteAppointment=()=>
    {
        fetch(Apis.DELETE_INVOICE+"?id="+id,
        {
            headers : AuthorizationHeader(),
            method : "DELETE"
        })
        .then(response=>{if(!response.ok) throw new Error();return response})
        .then(data=>{AlertHelper.ShowSuccess("Invoice Deleted");setIsDeleteModalVisible(false);props.onInvoiceUpdated(null);setIsOpen(false)})
        .catch(err=>{AlertHelper.ShowErrour("Something went wrong")})
    }
    const onRefChanged=(e)=>
    {
        if (e.key === "Enter"&&selectedRef!==""&&selectedRef!==undefined&&selectedRef!==null) 
        {
            if(allProducts.filter(m=>m.reference.toLowerCase()===selectedRef.toLowerCase()).length>0)
            {
                setSelectedProduct(allProducts.filter(m=>m.reference.toLowerCase()===selectedRef.toLowerCase())[0].id);
                setSelectedPrice(allProducts.filter(m=>m.reference.toLowerCase()===selectedRef.toLowerCase())[0].price);
                document.getElementById("refQuantity").focus();
                return;
            }
            if(allProducts.filter(m=>m.codeBare===selectedRef).length>0)
            {
                setSelectedProduct(allProducts.filter(m=>m.codeBare.toLowerCase()===selectedRef.toLowerCase())[0].id);
                setSelectedPrice(allProducts.filter(m=>m.codeBare.toLowerCase()===selectedRef.toLowerCase())[0].price);
                document.getElementById("refQuantity").focus();
                return;
            }
            setSelectedProduct(null);
        }
    }
    return(
        <Drawer size="full" placement='right' open={isOpen} onClose={()=>setIsOpen(false)} backdrop="static">
        <Drawer.Header>
          <Drawer.Title>{id===0?"Create a new Invoice" : "Update Invoice"}</Drawer.Title>
          <Drawer.Actions>
            <Button appearance="ghost" color='red' disabled={!canPrint} onClick={()=>setIsDeleteModalVisible(true)} >Delete Document</Button>
            <Button appearance="ghost" onClick={()=>setIsOpen(false)}>Close</Button>
            <Button onClick={onSave} appearance="primary" className='gradian' loading={isFetching} >
              Save Changes
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
        {isLoading===true?(<div style={{ textAlign: 'center' }}><Loader size="md" /></div>) : (              
        <Form fluid formValue={formValue} ref={formRef} model={model}>
                    <Form.Group>
                    <Panel header="Invoice Details" bordered>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={6}>
                                <Form.Group controlId='code'>
                                    <Form.ControlLabel>Code :</Form.ControlLabel>
                                    <Form.Control name="code" placeholder="Code" value={code} onChange={setCode} />
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={1}></FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={6}>
                                <Form.Group controlId='date'>
                                <Form.ControlLabel>Date and Time</Form.ControlLabel>
                                <Form.Control name="date" accepter={datePicker} value={date} onChange={setDate} />
                                {/* <DatePicker size="md" placement="autoVerticalStart" value={date} onChange={setDate} format="yyyy-MM-dd HH:mm" block  /> */}
                                </Form.Group>
                                </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={1}></FlexboxGrid.Item>
                        </FlexboxGrid>
                        </Panel>
                    </Form.Group>
                    <Form.Group>
                        <Panel header="Inser a product" bordered style={{height:"calc(100vh - 310px)"}}>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={4}>
                                    <Form.Group>
                                    <Form.ControlLabel>Product :</Form.ControlLabel>
                                    <SelectPicker disabled={isUpdateMode} style={{width:"100%"}} size="md" valueKey='id' ref={productsRef} labelKey='name' prop value={selectedProduct} onChange={onSelectedProductChanged} data={allProducts}   />
                                    </Form.Group>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={1}></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={4}>
                                    <Form.Group>
                                    <Form.ControlLabel>Code / Ref :</Form.ControlLabel>
                                    <Form.Control name="ref" placeholder="Code / Ref" id="refCode" value={selectedRef} onChange={setSelectedRef} onKeyDown={(e)=>onRefChanged(e)} />
                                    {/* <SelectPicker disabled={isUpdateMode} style={{width:"100%"}} size="md" valueKey='id' ref={productsRef} labelKey='name' prop value={selectedProduct} onChange={onSelectedProductChanged} data={allProducts}   /> */}
                                    </Form.Group>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={1}></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={4}>
                                    <Form.Group>
                                    <Form.ControlLabel>Quantity :</Form.ControlLabel>
                                    <InputNumber ref={refQuantity} id="refQuantity" onKeyDownCapture={(e)=>{if(e.key==="Enter")document.getElementById("refPrice").focus();}} onKeyDown={(e)=>alert(e.key)} value={selectedQuantity} min={1} onChange={setSelectedQuantity} size="md" style={{width:"100%"}}  />
                                    </Form.Group>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={1}></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={4}>
                                <Form.Group>
                                <Form.ControlLabel>Price :</Form.ControlLabel>
                                    <InputNumber value={selectedPrice} id="refPrice" onKeyDownCapture={(e)=>{if(e.key==="Enter")onAddProuct();}}  onChange={setSelectedPrice} size="md" style={{width:"100%"}} />
                                    </Form.Group>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={1}></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={3}>
                                <Form.Group>
                                <Form.ControlLabel>.</Form.ControlLabel>
                                    <Button onClick={onAddProuct} id="refAdd" block style={{background:"#77C66E",color:"white"}}>{isUpdateMode?"UPDATE":"Add To List"}</Button>
                                </Form.Group>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        
                        <TableHeader />
                        <TableDetail allProducts={allProducts} details={details.filter(m=>m.isDeleted===false)} onDeleteProduct={onDeleteProduct} onUpdateProduct={onUpdateProduct} />
                        </Panel>
                    </Form.Group>
                </Form>
                )}
                <DeleteConfirmation title="Document" isDeleteModalVisible={isDeleteModalVisible} onClose={()=>setIsDeleteModalVisible(false)} onDelete={deleteAppointment} />
        </Drawer.Body>
        </Drawer>
    )
});

export default CreateUpdateInvoiceModal;