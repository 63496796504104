import React, {Component} from 'react';
import ToolBar from '../Common/ToolBar';
import { AuthorizationHeader } from '../../manager/AuthorizationHeader';
import DeleteConfirmation from '../Common/DeleteConfirmation';
import Apis from '../../Helpers/Apis';
import { DoEventIfHavingPrivilge } from '../../manager/IsHavingPrivilege';
import { AlertHelper } from '../../Helpers/AlertHelper';
import TypeOfferTableDetail from './Components/TableDetail';
import TypeOfferTableHeader from './Components/TableHeader';
import CreateUpdateTypeOfferModal from './Modals/CreateUpdateTypeOfferModal';
export default class FetchTypeOffers extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
            typeOfferId : 0,
            IsCreateUpdateModalOpen : false,
            isDeleteModalVisible:false,
            typeOffers : []
        }
    }
    componentDidMount()
    {
        this.fetchTypeOffers();
    }
    fetchTypeOffers(){
         fetch(Apis.LIST_TYPE_OFFERS,{headers:AuthorizationHeader()})
        .then(response => response.json())
        .then(data=>{data.forEach(m=>{m.checked=false});this.setState({typeOffers:data})});
    }
    onModalCreateTypeOffer=()=>
    {
        DoEventIfHavingPrivilge("TYPE_OFFER_ADD",()=>{
        this.setState({typeOfferId:0,IsCreateUpdateModalOpen:true});
        },true);
    }
    onModalUpdateTypeOffer=()=>
    {
        DoEventIfHavingPrivilge("TYPE_OFFER_EDIT",()=>{
        const all = this.state.typeOffers.filter(m=>m.checked===true);
        if(all.length===0)
        {
            AlertHelper.ShowWarning("No Type Offer is Selected");
            return;
        }
        else if(all.length>1)
        {
            AlertHelper.ShowWarning("only one item can be updated");
            return;
        }
        this.setState({typeOfferId:all[0].id,IsCreateUpdateModalOpen:true});
        },true);
    }
    onModalDeleteTypeOffer=()=>
    {
        DoEventIfHavingPrivilge("TYPE_OFFER_DELETE",()=>{
        const selected = this.state.typeOffers.filter(m=>m.checked===true);
        if(selected.length===0)
        {
            AlertHelper.ShowWarning("No Type Offer is Selected");
            return;
        }
        this.setState({isDeleteModalVisible:true});
        },true);
    }
    onDelete=()=>
    {
        const selected = this.state.typeOffers.filter(m=>m.checked===true);
        if(selected.length===0)
            return;
        var ids = [];
        for(var i=0 ; i<selected.length;i++)
        {
            ids[i] = selected[i].id;
        }
        fetch(Apis.DELETE_TYPE_OFFERS,
        {
            method :"DELETE",
            headers : AuthorizationHeader(),    
            body :JSON.stringify(ids)
        })
        .then(response=>{if(!response.ok) throw new Error(response) ; return response})
        .then(data=>this.setState({isDeleteModalVisible:false},this.fetchTypeOffers))
        .catch(err=>AlertHelper.ShowErrour("Something went wrong"));
    }
    onCloseDeleteModal=()=>
    {
        this.setState({isDeleteModalVisible:false});
    }
    onCloseCreateUpdateModal=()=>
    {
        this.setState({IsCreateUpdateModalOpen : false});
    }
    onSave=()=>
    {
        this.setState({IsCreateUpdateModalOpen : false},this.fetchTypeOffers);
    }
    onSelectAll=(value)=>
    {
        const all = this.state.typeOffers;
        all.forEach(element => {
            element.checked=value
        });
        this.setState({typeOffers:all});
    }
    onSelectionChanged =(value,e)=>
    {
        const all = this.state.typeOffers;
        var selected = all.filter(m=>m.id===value)[0];
        var index = all.indexOf(selected);
        all[index].checked=e;
        this.setState({typeOffers:all});
    }
    renderTableDetails()
    {
        return(
            // <Grid fluid>
                <TypeOfferTableDetail typeOffers = {this.state.typeOffers} onSelectionChanged={this.onSelectionChanged} />
            // </Grid>
        )
    }
    onRefrech=()=>
    {
        this.fetchTypeOffers();
    }
    render()
    {
        let content = this.renderTableDetails();
        return(
        
        <div className='fetch-employe'>
            <ToolBar onRefrechClick={this.onRefrech} onCreateClick = {this.onModalCreateTypeOffer} onUpdateClick={this.onModalUpdateTypeOffer} onDeleteClick={this.onModalDeleteTypeOffer} />
            <TypeOfferTableHeader onSelectAll = {this.onSelectAll}   />
            {content}
            <CreateUpdateTypeOfferModal id={this.state.typeOfferId} isModalOpen={this.state.IsCreateUpdateModalOpen} onSave={this.onSave} onCloseModal={this.onCloseCreateUpdateModal} />
            <DeleteConfirmation title="Type Offer(s)" isDeleteModalVisible = {this.state.isDeleteModalVisible} onClose={this.onCloseDeleteModal} onDelete={this.onDelete} />

        </div>
        )
    }
}