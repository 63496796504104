import React, { useState } from 'react';
import './CalendarColumn.css';
import { Row,Col, Avatar, Button } from 'rsuite';
import { useHistory, withRouter  } from 'react-router-dom';
import CreateUpdateAppointmentModal from '../Appointments/Modals/CreateUpdateAppointmentModal';
import {DoEventIfHavingPrivilge} from '../../manager/IsHavingPrivilege';
const CalendarColumn =(props)=>
{
    const history = useHistory();
    const [appointmentVisible,setAppointmentVisible] = useState(false);
    const onAddClick=(e)=>
    {
        e.preventDefault();
        DoEventIfHavingPrivilge("APPOINTMENT_ADD",()=>{
        setAppointmentVisible(true);
        },true);
    }
    const onClose=()=>
    {
        setAppointmentVisible(false);
    }
    const onSave=()=>
    {
        setAppointmentVisible(false);
        props.onRefrech();
    }
    const row = 
    {
        marginRight : "5px",
        width : "100%",
        borderRight : "2px solde gray"
    }
    const dayName=(day)=>
    {
        if(day===0)
            return "Sunday";
        else if(day===1)
            return "Monday"
        else if(day===2)
            return "Tuesday"
        else if(day===3)
            return "Wednesday"
        else if(day===4)
            return "Thursday"
        else if(day===5)
            return "Friday"
        else if(day===6)
            return "Saturday"

    }   
 
    const renderRow =()=>
    {
        let dd = props.day.appointments.length>0?
        (props.day.appointments.map(app=>
                
            <div style={row} key={app.id}>
                <p>{app.dateReport===null?new Date(app.time).timeToInput():new Date(app.dateReport).timeToInput()}</p>
                <h6>{app.clientName}</h6>
                <h6>{app.clientPhone}</h6>
                <p>{app.typeAppointmentName}</p>
            </div>))
            :(<span></span>)
        return (
            <>  
                {
                dd}
             </>
        )
    }
    
    const goToPrinting=()=>
    {
        const ids = [];
        props.day.appointments.forEach(element => {
            ids[ids.length] = element.id
        });
        if(ids.length>0)
        {
            localStorage.setItem("AppointmentsToPrinting",JSON.stringify(ids));
            
            history.push("/printingAppointment");
        }
    }

    const renderAvatar=()=>
    {

        if(props.day.appointments.length<4)
            return <a href="" onClick={onAddClick}><Avatar style={{ background: '#59B20C',width:"20px",height:"18px",maring:"18px" }}>+</Avatar></a>
        else if(props.day.appointments.length>=4&&props.day.appointments.length<7)
            return <a href="" onClick={onAddClick}><Avatar style={{ background: '#D8A311',width:"20px",height:"18px",maring:"18px" }}>+</Avatar></a>
        else
            return <a href="" onClick={onAddClick}><Avatar style={{ background: '#DD0E2D',width:"20px",height:"18px",maring:"18px" }}>+</Avatar></a>
    }
    return (
        <>
           
            <div className="column-day">
                <h5>{dayName(new Date(props.day.date).getDay())}</h5>
                
                <Row className="show-grid">
                    <Col xs={20}>
                    <p>{props.day.date.getDate()+ " "+ props.day.date.toMonthName()}</p>
                    </Col>
                    <Col xs={4}>
                        {renderAvatar()}
                    </Col>
                </Row>
            </div>
            <div className='column-header'>
                <Row>
                    <Col xs={20}>
                        <p>Appointments</p>
                    </Col>
                    <Col xs={4}>
                        <Button onClick={goToPrinting} >
                        <img src='print.svg' />
                        </Button>
                        
                    </Col>
                </Row>
            </div>
            <div className="column-body">
                
                {renderRow()}
            </div>
            <CreateUpdateAppointmentModal defaultStartUp={"5"} defaultStore={props.store} defaultDate={props.day.date} id={0} isCreateUpdateModalOpen = {appointmentVisible} onCloseModal={onClose} onSave={onSave} />
        </>
    )
}
export default CalendarColumn;