import React from 'react';
import { Modal,Form, Input,Button,FlexboxGrid,Loader } from 'rsuite';
import { AuthorizationHeader } from '../../../manager/AuthorizationHeader';
import Apis from '../../../Helpers/Apis';
import {AlertHelper} from '../../../Helpers/AlertHelper'
import { Schema } from 'rsuite';

const textArea = React.forwardRef((props, ref) => <Input as="textarea" size="md"    {...props}  ref={ref}  block />);
const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  name: StringType().isRequired('This field is required.'),
  phone: StringType().isRequired('This field is required.'),
  adresse: StringType().isRequired('This field is required.'),
});
const CreateUpdateClientModal =(props)=>
{
    const formRef = React.useRef();
    const [name,setName]=React.useState("");
    const [phone,setPhone]=React.useState("");
    const [adresse,setAdresse]=React.useState("");
    const [isLoading,setIsLoading] = React.useState(false);
    const [isFinished,setIsFinished] = React.useState(false);

    const formValue = 
    {
        name : name,
        phone : phone,
        adresse : adresse
    };
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        color :"#89919B",
        backgroundColor : "#D2D2D2",
        fontWeight : "bold"
    }
    const onSave=()=>
    {
        if(!formRef.current.check())
            return;
        setIsLoading(true);
        const data = {
            id:props.id,
            name,
            phone,
            adresse
        }
        const url = props.id===0? Apis.CREATE_CLIENT : Apis.UPDATE_CLIENT;
        const method = props.id===0? "POST" : "PUT"
        fetch(url, {
        method : method,
        headers : AuthorizationHeader(),
        body : JSON.stringify(data)
        })

        .then(response => {if(!response.ok) throw new Error("err");return response.json()})
        .then(data=>props.onSaveClient(data.id))
        .catch(err=>{AlertHelper.ShowErrour("Something went wrong");setIsLoading(false)});
    }
    const onClose=()=>
    {       
        props.onCloseModal();
        setIsFinished(false);
    }
    const onOpen= ()=>
    {
        setIsFinished(false);
        if(props.id===0)
        {
            setName("");
            setAdresse("");
            setPhone("");
        }
        else
        {
            fetch(Apis.FIND_CLEINT+"?id="+props.id,{headers:AuthorizationHeader()})
            .then(response=>response.json())
            .then(data=>{
                setName(data.name);
                setAdresse(data.adresse);
                setPhone(data.phone);   
            });
        }
        setIsLoading(false);
        setIsFinished(true);
    }
    const Textarea = React.forwardRef((props, ref) => <Input size="md" {...props} as="textarea" ref={ref} />);
    return (
        <Modal open={props.isUpdateCreateClientModalOpen} className="custom-modal" onClose={onClose} onEntered={onOpen} backdrop="static">
            <Modal.Header>
                <h5>{props.id===0?"Add a ":"Update "}Client</h5>
            </Modal.Header>
            <Modal.Body>
            {isFinished===false?(<div style={{ textAlign: 'center' }}><Loader size="md" /></div>) : (  
                <Form fluid model={model} formValue={formValue} ref={formRef}>
                        <Form.Group controlId="name" block>
                                    <Form.ControlLabel>Name</Form.ControlLabel>
                                    <Form.Control name="name" size="md" placeholder="Name" value={name} onChange={setName} block autoComplete='off' />
                        </Form.Group>
                        <Form.Group controlId="phone">
                                    <Form.ControlLabel>Phone</Form.ControlLabel>
                                    <Form.Control name="phone" size="md" placeholder="Phone" value={phone} onChange={setPhone}  block autoComplete='off'/>
                        </Form.Group>
                        <Form.Group controlId="adresse">
                                    <Form.ControlLabel>Adresse</Form.ControlLabel>
                                    <Form.Control name="adresse" placeholder='Adresse' accepter={textArea} value={adresse} onChange={e=>setAdresse(e)} block autoComplete='off' />
                        </Form.Group>
                </Form>
            )}
            </Modal.Body>
            <Modal.Footer>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={11} className="to-right">
                                        <Button appearance="default" size="md" block style={cancelButton} disabled={isLoading} onClick={onClose}>BACK</Button>    
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={1} > 

                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={12}>
                                        <Button color="red" appearance="primary" block size="md" style={saveButton} loading={isLoading} onClick={onSave}>
                                            {props.id===0?"ADD":"SAVE"}
                                        </Button>   
                </FlexboxGrid.Item>
            </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    )
}
export default CreateUpdateClientModal;