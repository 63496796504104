import React, { useRef } from 'react'
import { Form, Col,Grid,InputGroup, Row,Loader, SelectPicker ,DatePicker,Input, FlexboxGrid,ButtonToolbar,Button,ButtonGroup,Dropdown,Popover,ArrowDownIcon,IconButton,Whisper} from 'rsuite'
import { Modal } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import CreateUpdateClientModal from '../../Clients/Modals/CreateUpdateClientModal';
import {AuthorizationHeader} from '../../../manager/AuthorizationHeader';
import { Schema } from 'rsuite';
import Apis from '../../../Helpers/Apis';
import {AlertHelper} from '../../../Helpers/AlertHelper'
import { DoEventIfHavingPrivilge } from '../../../manager/IsHavingPrivilege';
const clientSelector = React.forwardRef((props, ref) => <SelectPicker size="md" valueKey='id' labelKey='nameWithPhone'    {...props}  ref={ref}  block />);
const sectionSelector = React.forwardRef((props, ref) => <SelectPicker size="md" valueKey='id' labelKey='name'    {...props}  ref={ref}  block />);
const typeSelector = React.forwardRef((props, ref) => <SelectPicker size="md" valueKey='id' labelKey='id'    {...props}  ref={ref}  block />);
const typeAppointmentSelector = React.forwardRef((props, ref) => <SelectPicker size="md" valueKey='id' labelKey='name'    {...props}  ref={ref}  block />);
const dateSelector = React.forwardRef((props, ref) => <DatePicker size="md" format="dd-MM-yyyy"    {...props}  ref={ref}  block />);
const timeSelector = React.forwardRef((props, ref) => <DatePicker size="md" format="hh:mm aa"   {...props}  ref={ref}  block />);
const descriptionArea = React.forwardRef((props, ref) => <Input as="textarea" size="md"   {...props}  ref={ref}  block />);
const CreateUpdateAppointmentModal =(props)=>
{
    const { StringType, NumberType,DateType } = Schema.Types;
    const model = Schema.Model({
    client: StringType().isRequired("client is required"),
    section: StringType().isRequired("Store de sortie is required"),
    typeId: StringType().isRequired("Type is required"),
    description: StringType().isRequired("Designation is required"),
    date: DateType().isRequired("Date is required"),
    time: DateType().isRequired("Time is required"),
    });
    
    const [sections,setSections] = React.useState([]);
    const [clients,setClients] = React.useState([]);
    const [selectedClient,setSelectedClient] = React.useState({});
    const [createClientModalVisible,setCreateClientModalVisible] = React.useState(false);
    const [priorities, setPriorities] = React.useState([{id:"0",name:"Normal"},{id:"1",name:"Urgent"},{id:"2",name:"Super Urgent"}])
    const [clientAdresse, setClientAdresse] = React.useState("");
    const [clientId,setClientId] = React.useState();
    const [sectionId,setSectionId] = React.useState();
    const [typeId,setTypeId] = React.useState(null);
    const [description,setDescription] = React.useState("");
    const [priority,setPriority] = React.useState("0");
    const [note,setNote] = React.useState("");
    const [type,setType] = React.useState();
    const [date,setDate]=React.useState(null);
    const [time,setTime] = React.useState(null);
    const formRef = React.useRef();
    const [isLoading,setIsLoading] = React.useState(false);
    const [isFinished,setIsFinished] = React.useState(false);
    const allTypes = [{id:"Installation"},{id:"Reparation"},{id:"Consultation"}]
    const [typeAppointments,setTypeAppointments] = React.useState([]);
    const [hasErrors,setHasErrors] = React.useState(false);
    const [erros,setErros] = React.useState([]);
    const [startType,setStartType] = React.useState([{id:"0",name:"Waiting"},{id:"5",name:"Offer"}]);
    const [startAs,setStartAs] = React.useState(0);

    const formValue =
    {
        client : clientId,
        section : sectionId,
        description : description,
        priority : priority,
        typeId : typeId,
        date : date,
        time : time

    }
    const ref1 = useRef();
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        color :"#89919B",
        backgroundColor : "#D2D2D2",
        fontWeight : "bold"
    }

    const initDataSource = ()=>
    {
        alert("s");
    }
    const onModalOpen= async()=>
    {
        setIsFinished(false);
        if(props.id===0)
        {
            setClientId(null);
            
            setDescription("");
            setNote("");
            setType();
            setPriority("0");
            setClientAdresse("");
            setStartAs(props.defaultStartUp);
            setSelectedClient({id:0,name:""});
            setTypeId(null);
            if(props.defaultDate!==null)
                setDate(props.defaultDate);
            else
                setDate(null);
            setTime(null);
            if(props.defaultStore!==null)
                setSectionId(props.defaultStore);
            else
                fetch(Apis.GET_CURRENT_USSER_INFO,{headers:AuthorizationHeader()})
                .then(response=>{if(!response.ok) throw new Error("err");return response.json()})
                .then(data=>setSectionId(data.defaultStoreId))
                .catch(err=>AlertHelper.ShowErrour("Something went wrong"));
            
        }
        else
        {
            var fetchAppointment = await fetch(Apis.FIND_APPOINTMENT+"?id="+props.id,{headers:AuthorizationHeader()});
            var resultAppointment = await fetchAppointment.json();
            onClientChanged(resultAppointment.clientId);
            setClientId(resultAppointment.clientId);
            setSectionId(resultAppointment.sectionId);
            setDescription(resultAppointment.description);
            setTypeId(resultAppointment.typeId);
            setNote();
            setStartAs(resultAppointment.startedAs);
            setType(resultAppointment.name);
            setPriority(resultAppointment.priority);
            setDate(new Date(resultAppointment.date));
            setTime(new Date(resultAppointment.time));
        }
        var fetchSections = await fetch(Apis.LIST_SECTIONS,{headers:AuthorizationHeader()});
        var resultSections = await fetchSections.json();
        setSections(resultSections);

        var fetchClients = await fetch(Apis.LIST_CLIENTS,{headers:AuthorizationHeader()});
        if(fetchClients.ok)
        {
            var resultClients =await fetchClients.json();
            setClients(resultClients);
        }
        else
        {
            if(fetchClients.status.toString().indexOf("403")!==-1)
            {
                setHasErrors(true);
                setErros([...erros,"You must have the privilege of Client List to update this content"])
                AlertHelper.ShowErrour("You must have the privilege of Client List to update this content");
            }
            else
                AlertHelper.ShowErrour("Something went wrong");
        }

        var fetchTypes = await fetch(Apis.LIST_TYPE_APPOINTMENTS,{headers:AuthorizationHeader()});
        if(fetchTypes.ok)
        {
            var resultTypes =await fetchTypes.json();
            setTypeAppointments(resultTypes);
        }
        else
        {
            if(fetchTypes.status.toString().indexOf("403")!==-1)
            {
                setHasErrors(true);
                setErros([...erros,"You must have the privilege of Type Appointment List List to update this content"])
                AlertHelper.ShowErrour("You must have the privilege of Type Appointment List to update this content");
            }
            else
                AlertHelper.ShowErrour("Something went wrong");
        }


        setIsFinished(true);
        setIsLoading(false);
    }
    const onClientChanged =async (e)=>
    {
        var fetchClient = await fetch(Apis.FIND_CLEINT+"?id="+e,{headers:AuthorizationHeader()});
        var resultClient= await fetchClient.json();
        setSelectedClient(resultClient);
        setClientAdresse(resultClient.adresse);
        setClientId(e);
    }
    const saveClient=()=>
    {
        const data =
        {
            id : clientId,
            name : selectedClient.name,
            phone : selectedClient.phone,
            adresse : clientAdresse
        };
        fetch(Apis.UPDATE_CLIENT,{headers:AuthorizationHeader(),method:"PUT",body:JSON.stringify(data)})
        .then(response=>response)
        .then(data=>data);
    }
    const onSave =(e)=>
    {
        if(hasErrors)
        {
            erros.forEach(e=>{
                AlertHelper.ShowErrour(e);
            });
            return;
        }
        if(!formRef.current.check())
        {
            return;
        }
        else
        {
            if(!isLoading)
            {
                setIsLoading(true);
                const data = {
                    id:props.id,
                    clientId,
                    sectionId,
                    description,
                    typeId,
                    note,
                    priority,
                    startedAs : startAs,
                    date:date.toExactDate(),
                    time:time.toExactDate(),
                    name:type
                };
                const url = props.id===0? Apis.CREATE_APPOINTMENT: Apis.UPDATE_APPOINTMENT;
                const method = props.id===0? "POST" : "PUT"
                fetch(url, {
                method : method,
                headers : AuthorizationHeader(),
                body : JSON.stringify(data)
                })
    
                .then(response => {if(!response.ok) throw new Error("err");saveClient();props.onSave()})
                .then(data=>data)
                .catch(err=>{AlertHelper.ShowErrour("Something went wrong");setIsLoading(false);});
            }
            
        }
    }
    const onSaveClient = async (e)=>{
        var fetchClients = await fetch(Apis.LIST_CLIENTS,{headers:AuthorizationHeader()});
        var resultClients =await fetchClients.json();
        setClients(resultClients);
        setCreateClientModalVisible(false);
        setClientId(e);
        onClientChanged(e);
        
    }
    const onCloseClientModal=()=>
    {
        setCreateClientModalVisible(false);
    }
    const onCreateClient = ()=>
    {
        DoEventIfHavingPrivilge("CLIENT_ADD",()=>{
        setCreateClientModalVisible(true);
        },true);
    }
    const onClose=()=>
    {
        props.onCloseModal();
        setIsFinished(false);
    }
    return (
        <>
        <Modal open={props.isCreateUpdateModalOpen} className="custom-modal" onEntering={onModalOpen} onClose={onClose} size="md" backdrop="static">
            <Modal.Header>
               <h5>{props.id===0?"Add An Appointment":"Update An Appointment"}</h5>
            </Modal.Header>
            <Modal.Body>
                {isFinished===false?(<div style={{ textAlign: 'center' }}><Loader size="md" /></div>) : (              
                <Form fluid model={model} ref={formRef} checkTrigger="change" formValue={formValue}>
                <div className="show-grid">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={11}>
                            <Form.Group controlId="client-1">
                                <Form.ControlLabel>Client's Phone / Name</Form.ControlLabel>
                                    <InputGroup style={{width:"100%",border:"0px"}}>
                                        <Form.Control name="client" accepter={clientSelector} style={{width :"95%"}} data={clients} ref={ref1}  placeholder="Phone Number" onChange={e=>onClientChanged(e)} value={clientId} block/>
                                        <InputGroup.Button style={{borderRadius:"30px",height:"35px",width:"35px",marginTop:"3px",backgroundColor:"rgb(210 210 210)"}} onClick={onCreateClient}>
                                        <PlusIcon style={{color :"white"}} color={"white"} />
                                    </InputGroup.Button>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Clients' Name</Form.ControlLabel>
                                <Input name="clientName" autoComplete="off" value={selectedClient.name} size="md" placeholder='Name' readOnly />
                            </Form.Group>
                            <Form.Group controlId="clientAdresse-1">
                                <Form.ControlLabel>Client's Adresse</Form.ControlLabel>
                                <Input size="md" as="textarea" value={clientAdresse} onChange={e=>setClientAdresse(e)}  style={{height:"117px"}} autoComplete="off" />
                            </Form.Group>
                            {props.id===0?<></>:
                            <Form.Group controlId="note-1">
                                <Form.ControlLabel>Note</Form.ControlLabel>
                                <Input name="note" placeholder='Drop a note' onChange={e=>setNote(e)} value={note} size="md" autoComplete="off" />
                            </Form.Group>
                            }
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={1}></FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <Form.Group controlId="startAs">
                                <Form.ControlLabel>Create As</Form.ControlLabel>
                                <Form.Control disabled={props.id!==0} name="startAs" accepter={sectionSelector}  data={startType} cleanable={false}  value={startAs}  autoComplete="off" onChange={e=>setStartAs(e)} block/>
                            </Form.Group>
                            <Form.Group controlId="section">
                                <Form.ControlLabel>Shop</Form.ControlLabel>
                                <Form.Control accepter={sectionSelector} name="section"  data={sections}  placeholder="Shop" onChange={e=>setSectionId(e)} value={sectionId} block />
                            </Form.Group>
                            {/* <Form.Group controlId="type">
                                <Form.ControlLabel>Type</Form.ControlLabel>
                                <Form.Control accepter={typeSelector} name="type" data={allTypes} placeholder='Type' onChange={e=>setType(e)} value={type} size="md" autoComplete="off" />
                            </Form.Group> */}
                            <Form.Group controlId="typeId">
                                <Form.ControlLabel>Type</Form.ControlLabel>
                                <Form.Control accepter={typeAppointmentSelector} name="typeId" data={typeAppointments} placeholder='Type' onChange={e=>setTypeId(e)} value={typeId} size="md" autoComplete="off" />
                            </Form.Group>
                            <Form.Group controlId="description">
                                <Form.ControlLabel>Description</Form.ControlLabel>
                                <Form.Control name="description" placeholder='Designation' accepter={descriptionArea} onChange={e=>setDescription(e)} value={description} size="md" autoComplete="off" />
                            </Form.Group>
                            <Form.Group>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={15}>
                                    
                                <Form.Group controlId="date">
                                    <Form.ControlLabel>Date</Form.ControlLabel>
                                    <Form.Control accepter={dateSelector} name="date" placement="autoVerticalStart" placeholder="Date"  value={date} autoComplete="off" onChange={e=>setDate(e)} block />
                                 </Form.Group>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={1}></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={8}>
                                <Form.Group controlId="time-1">
                                    <Form.ControlLabel>Time</Form.ControlLabel>
                                    <Form.Control accepter={timeSelector} name="time" placement="autoVerticalStart"  placeholder="Time"  value={time} autoComplete="off" onChange={e=>setTime(e)} block />
                                 </Form.Group>
                                </FlexboxGrid.Item>
                                
                            </FlexboxGrid>
                            </Form.Group>
                        
                            <Form.Group controlId="priority">
                                <Form.ControlLabel>Priority</Form.ControlLabel>
                                <Form.Control name="priority" accepter={sectionSelector}  data={priorities} cleanable={false}  value={priority}  autoComplete="off" onChange={e=>setPriority(e)} block/>
                            </Form.Group>
                            
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <Form.Group controlId="priority-1">
                               
                    </Form.Group>
            </div>
                </Form>
                )}
            </Modal.Body>
            <Modal.Footer>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={11} className="to-right">
                                    <Button appearance="default" size="md" style={cancelButton} disabled={isLoading} onClick={onClose}>CANCEL</Button>    
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={1} > 

                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={12}>
                                    <Button color="red" appearance="primary" block size="md" style={saveButton} onClick={onSave} loading={isLoading}>
                                        {props.id===0?"ADD":"SAVE"}
                                    </Button>   
                </FlexboxGrid.Item>
            </FlexboxGrid>
            </Modal.Footer>
        </Modal>
        <CreateUpdateClientModal id={0} isUpdateCreateClientModalOpen={createClientModalVisible} onSaveClient = {onSaveClient} onCloseModal={onCloseClientModal} />
        </>
    )
}
export default CreateUpdateAppointmentModal;