import React, { useImperativeHandle } from 'react';
import {Modal,Button} from 'rsuite'
import Apis from '../../../Helpers/Apis';
const PrintInvoiceModal=React.forwardRef((props,ref)=>
{
    useImperativeHandle(ref,()=>({
        initDataSource : initDataSource
    }))
    const [isOpen, setIsOpen] = React.useState(false);
    const [appointmentId,setAppointmentId] = React.useState(0);
    const initDataSource=(id)=>
    {
        setAppointmentId(id);
        setIsOpen(true);
    }
    const onClose=()=>
    {
        props.onClose();
        setIsOpen(false);
    }
    const onPrint=()=>
    {
        window.open(Apis.SERVER+"/printInvoice/"+appointmentId, '_blank').focus();
        props.onClose();
        setIsOpen(false);
    }
    return (
        <Modal backdrop="static" role="alertdialog" open={isOpen} onClose={onClose}  size="xs">
        <Modal.Body>
          {/* <RemindIcon
            style={{
              color: '#ffb300',
              fontSize: 24
            }}
          /> */}
          Appointment is progressed successfully, do you want to print the invoice?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onPrint} appearance="primary">
            Yes Print
          </Button>
          <Button onClick={onClose} appearance="subtle">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
});
export default PrintInvoiceModal;