import React  from 'react';
import {Modal,Input,FlexboxGrid,Button,Loader, Form} from 'rsuite';
import { AlertHelper } from '../../../Helpers/AlertHelper';
import Apis from '../../../Helpers/Apis';
import { AuthorizationHeader } from '../../../manager/AuthorizationHeader';
const observationInput = React.forwardRef((props,ref)=><Input as="textarea" placeholder="Observation" {...props}  ref={ref} />);
const ValidateInvoiceProducts=(props)=>
{
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        color :"#89919B",
        backgroundColor : "#D2D2D2",
        fontWeight : "bold"
    }
    const [details,setDetails] = React.useState([]);
    const [code,setCode] = React.useState();
    const [observation,setObservation] = React.useState();
    const [nBonDécalage,setnBonDécalage] = React.useState();
    const [products,setProducts] = React.useState([]);
    const [isLoading,setIsLoading] = React.useState(false);
    const [isFinished,setIsFinished] = React.useState(false);
    const data=()=>
    {
        const d = [];
        for(var i=0;i<2;i++)
        {
            d.push({
                code:"12345",
                reference:"5521",
                productName:"Product Name",
                qteTheorique:5,
                qteReel:0
            });
        }
        setDetails(d);
    }
    const onOpen=()=>
    {
        setIsFinished(false);
        setIsLoading(false);
        fetch(Apis.GET_INVOICE_DETAILS_VIEWMODEL+"?id="+props.id,{headers:AuthorizationHeader()})
        .then(response=>{if(!response.ok) throw new Error();return response.json()})
        .then(data=>
        {
            setDetails(data);
            setIsFinished(true);
        })
        fetch(Apis.FIND_INVOICE+"?id="+props.id,{headers:AuthorizationHeader()})
        .then(response=>{if(!response.ok) throw new Error();return response.json()})
        .then(data=>
        {
            setObservation(data.observation);
            setnBonDécalage(data.nBonDécalage);
        })
    }
    const onSave=()=>
    {
        setIsLoading(true);
        const data =
        {
            id : props.id,
            observation,
            nBonDécalage,
            details : details
        };
        fetch(Apis.VALIDATE_INVOICE_CHECK,{
            headers:AuthorizationHeader(),
            method:"POST",
            body : JSON.stringify(data)
        })
        .then(response=>{if(!response.ok) throw new Error();return response})
        .then(data=>{props.onClose();setIsFinished(false);})
        .catch(err=>{AlertHelper.ShowErrour("Something went wrong");setIsLoading(false);});
    }
    return (
        <Modal open={props.IsValidateInvoiceProductModalOpen} onEntered={onOpen} className="custom-modal" size="lg" onClose={props.onClose} backdrop="static">
            <Modal.Header>Validate Invoice</Modal.Header>
            <Modal.Body>
            {isFinished===false?(<div style={{ textAlign: 'center' }}><Loader size="md" /></div>) : (
                <>
                <Form fluid>
                    <Form.Group style={{width:"50%"}}>
                        <Form.ControlLabel>N° Bon Décalage</Form.ControlLabel>
                        <Form.Control value={nBonDécalage} onChange={setnBonDécalage} placeholder="N° Bon Décalage" />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Observation</Form.ControlLabel>
                        <Form.Control accepter={observationInput} value = {observation} onChange={setObservation} placeholder="Observation" />
                    </Form.Group>
                </Form>
                <table style={{width:"100%",marginTop:"15px"}}>
                    <thead>
                        <tr style={{height:"45px",borderBottom:"1px solid #e2dfdf",fontWeight:"bold"}}>
                            <td>Code Barre</td>
                            <td>Reference</td>
                            <td>Product's Name</td>
                            {/* <td style={{width : "90px", textAlign:"center"}}>Qty Theo</td> */}
                            <td style={{width : "60px"}}>Qty Entre</td>
                            <td style={{width : "280px",paddingLeft:"15px"}}>Observation</td>
                        </tr>
                    </thead>
                   
                    <tbody>
                    
                        {details.map((dt,index)=>
                            <tr style={{height:"45px",borderBottom:"1px solid #e2dfdf"}}>
                                <td>{dt.productCodeBarre}</td>
                                <td>{dt.productReference}</td>
                                <td>{dt.productName}</td>
                                {/* <td style={{width : "90px", textAlign:"center"}}>{dt.qteSortie - dt.qteConsomee}</td> */}
                                <td><Input value={dt.qteValide} onChange={(e)=>{dt.qteValide=e;setDetails([...details])}} /></td>
                                <td style={{width : "280px",paddingLeft:"15px"}}><Input value={dt.observation} placeholder="Observation" onChange={(e)=>{dt.observation=e;setDetails([...details])}}/></td>
                            </tr>
                        )}
                    </tbody>
                     
                </table>
                </>
                )}
                
            </Modal.Body>
            <Modal.Footer>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={11} className="to-right">
                                    <Button appearance="default" size="md" style={cancelButton} onClick={props.onClose} disabled={isLoading}>CANCEL</Button>    
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={1} > 

                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={12}>
                                    <Button color="red" appearance="primary" block size="md" style={saveButton} onClick={onSave} loading={isLoading}>
                                        VALIDATE
                                    </Button>   
                </FlexboxGrid.Item>
            </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    )
}
export default ValidateInvoiceProducts;