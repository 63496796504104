Date.prototype.timeToInput = function(){
    return  ('0' + (this.getHours())).substr(-2,2) + ':' + ('0' + this.getMinutes()).substr(-2,2);
}
Date.prototype.dateToInput = function(){
    return this.getFullYear() + '-' + ('0' + (this.getMonth() + 1)).substr(-2,2) + '-' + ('0' + this.getDate()).substr(-2,2);
}
Date.prototype.toExactDate=function(){
    const offset = this.getTimezoneOffset(); 
    const date = new Date(this.getTime() - (offset*60*1000)); 
    return date.toISOString();
}
   // convertToDay(date)
    // {

    //     const offset = date.getTimezoneOffset(); 
    //     date = new Date(date.getTime() - (offset*60*1000)); 
    //     return date.toISOString().split('T')[0];
    // }
Date.prototype.addDays = function(days)
{
    var date =new Date(this);
    date = date.setDate(date.getDate()+days);
    return date;
}
Date.prototype.addMonths = function(months)
{
    var date =new Date(this);
    date = date.setMonth(date.getMonth()+months);
    return date;
}
Date.prototype.toMonthName = function()
{
    const month = this.getMonth()+1;
    switch(month)
        {
            case 1 : return "January";
            case 2 : return "February";
            case 3 : return "March";
            case 4 : return "April";
            case 5 : return "May";
            case 6 : return "June";
            case 7 : return "July";
            case 8 : return "August";
            case 9 : return "September";
            case 10: return "October";
            case 11: return "November";
            case 12: return "December";
        }
}

