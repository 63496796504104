import { Row } from 'rsuite';
import { Col } from 'rsuite';
import { Button } from 'rsuite';
import { Checkbox } from 'rsuite';
import React from 'react';
import './TableHeader.css';
const TableHeader=(props)=>
{
  const onSelectAll= (value,e)=>{
    props.onSelectAll(e);
  }
    return (
      <Row className="employee-table-header rounded">
        <Col xs={1}><Checkbox onChange={onSelectAll}  /></Col> 
        <Col xs={8}>Employee's Name</Col>
        <Col xs={15}>Role</Col>
        {/* <Col xs={11}>Adresse</Col> */}
      </Row>
    )
}
export default TableHeader;