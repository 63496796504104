import React from "react";
import { Modal,Form,FlexboxGrid,Button,Input, SelectPicker,Loader } from "rsuite";
import {AuthorizationHeader} from '../../../manager/AuthorizationHeader';
import Apis from '../../../Helpers/Apis';
import {AlertHelper} from '../../../Helpers/AlertHelper';
import { Schema } from 'rsuite';

const textArea = React.forwardRef((props, ref) => <Input as="textarea" size="md"    {...props}  ref={ref}  block />);
const fonctionPicker = React.forwardRef((props, ref) => <SelectPicker valueKey="id" labelKey="name" size="md"    {...props}  ref={ref}  block />);
const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
    name: StringType().isRequired('This field is required.'),
    role: StringType().isRequired('This field is required.'),
  });
  
const CreateUpdateEmployeeModal =(props)=>
{
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        color :"#89919B",
        backgroundColor : "#D2D2D2",
        fontWeight : "bold"
    }
    const formRef = React.useRef();
    const [name,setName] = React.useState("");
    const [phone,setPhone] = React.useState("");
    const [adresse,setAdresse] = React.useState("");
    const [fonctionId,setFonctionId] = React.useState();
    const [isLoading,setIsLoading] = React.useState(false);
    const [fonctions,setFonctions]  = React.useState([]);
    const [isFinished,setIsFinished] = React.useState(false);
    const [hasErrors,setHasErrors] = React.useState(false);
    const [errors,setErrors] = React.useState("");
    const formValue = 
    {
        name : name,
        role : fonctionId
    };

    const initiaNewEmployee =()=>
    {
        setName("");
        setPhone("");
        setAdresse("");
        setFonctionId();
    }
    const findSelectedEmployee=()=>
    {
        fetch(Apis.FIND_EMPLOYEE+"?id="+props.id,{headers:AuthorizationHeader()})
        .then(response=>response.json())
        .then(data=>{
            setName(data.name);
            setPhone(data.phone);
            setAdresse(data.adresse);
            setFonctionId(data.fonctionId);
        });
    }
    const onModalOpen=()=>
    {
        setIsFinished(false);
        fetch(Apis.LIST_FONCTIONS,{headers:AuthorizationHeader()})
        .then(response=>{if(!response.ok) throw new Error(response.status); return response.json()})
        .then(data=>setFonctions(data))
        .catch(err=>{
            if(err.toString().indexOf("403")!==-1)
            {
                setHasErrors(true);
                setErrors("You must have the privilege of List Roles to update this content");
                AlertHelper.ShowErrour("You must have the privilege of List Roles to update this content");
            }
            else
            {
                AlertHelper.ShowErrour("Something went wrong");
            }
        })
        setIsLoading(false);
        if(props.id===0)
        {
            initiaNewEmployee();
        }
        else
        {
            findSelectedEmployee();
        }
        setIsFinished(true);
    }
    const onSaveEmployee=()=>
    {
        if(hasErrors)
        {
            AlertHelper.ShowErrour(errors);
            return;
        }
        if(!formRef.current.check())
            return;
        setIsLoading(true);
        const api = props.id===0? Apis.CREATE_EMPLOYEE : Apis.UPDATE_EMPLOYEE;
        const method = props.id===0?"POST" : "PUT";
        const data = JSON.stringify({
            id:props.id,
            name,
            phone,
            adresse,
            fonctionId : fonctionId
        })
        fetch(api,{
            method:method,
            headers : AuthorizationHeader(),
            body:data
        })
        .then(response=>{if(!response.ok) throw new Error("err") ;return response;})
        .then(data =>{
            props.onSave();
        })
        .catch(err=>{console.log(err);AlertHelper.ShowErrour("Something went wrong");setIsLoading(false)});
    }
    const onClose=()=>
    {
        setIsFinished(false);
        props.onCloseModal();
    }
    return(
        <Modal open={props.isModalOpen} onEntered={onModalOpen} onClose={onClose} backdrop="static" className="custom-modal">
            <Modal.Header>
                <h5>{props.id===0?"Add an Employee": "Update Employee"}</h5>
            </Modal.Header>
            <Modal.Body>
            {isFinished===false?(<div style={{ textAlign: 'center' }}><Loader size="md" /></div>) : (  
                <Form fluid model={model} formValue={formValue} ref={formRef}>
                        <Form.Group controlId="name" block>
                                    <Form.ControlLabel>Name</Form.ControlLabel>
                                    <Form.Control name="name" size="md" placeholder="Name" value={name} onChange={setName} block autoComplete='off' />
                        </Form.Group>
                        {/* <Form.Group controlId="phone">
                                    <Form.ControlLabel>Phone</Form.ControlLabel>
                                    <Form.Control name="phone" size="md" placeholder="Phone" value={phone} onChange={setPhone}  block autoComplete='off'/>
                        </Form.Group>
                        <Form.Group controlId="adresse">
                                    <Form.ControlLabel>Adresse</Form.ControlLabel>
                                    <Form.Control name="adresse" placeholder='Adresse' accepter={textArea} value={adresse} onChange={e=>setAdresse(e)} block autoComplete='off' />
                        </Form.Group> */}
                        <Form.Group controlId="role">
                                    <Form.ControlLabel>Role</Form.ControlLabel>
                                    <Form.Control name="role" data={fonctions} placeholder='Role' accepter={fonctionPicker} value={fonctionId} onChange={e=>setFonctionId(e)} block autoComplete='off' />
                        </Form.Group>
                </Form>
            )}
            </Modal.Body>
            <Modal.Footer>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={11} className="to-right">
                                        <Button appearance="default" size="md" block style={cancelButton} disabled = {isLoading} onClick={onClose}>BACK</Button>    
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={1} > 

                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={12}>
                                        <Button color="red" appearance="primary" block size="md" style={saveButton} loading={isLoading} onClick={onSaveEmployee}>
                                            {props.id===0?"ADD":"SAVE"}
                                        </Button>   
                </FlexboxGrid.Item>
            </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    )
}
export default CreateUpdateEmployeeModal;