import React, { useState } from 'react';
import {Modal,Button,FlexboxGrid} from 'rsuite'
import Apis from '../../Helpers/Apis';
import { AuthorizationHeader } from '../../manager/AuthorizationHeader';
const BackupModal =(props)=>
{
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        color :"#89919B",
        backgroundColor : "#D2D2D2",
        fontWeight : "bold"
    }
    const [isLoading,setIsLoading] = useState(false);
    const onOpen=()=>
    {
        setIsLoading(false);
    }
    const onClose=()=>
    {
        if(isLoading!==true)
            props.onClose();
        else
            alert("Backing up is in process");
    }
    const onBackup=()=>
    {
        setIsLoading(true);
        var a = document.createElement('a');
        fetch(Apis.BACKUP,
        {
            headers:AuthorizationHeader(),
            method:"POST"
        })
        .then(response=>{if(!response.ok) throw new Error("Error while backing up, please try again");return response.blob()})
        .then(blob=>{
            const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = "TECH-POS-BACKUP.bak";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                props.onClose();
        })
        .catch(err=>{
            alert(err);
            setIsLoading(false);
        })
    }
    return(
        <Modal open={props.IsBackupModalVisible} onClose={onClose} onOpen={onOpen} backdrop="static">
            <Modal.Header>
                <h5>Backup your data</h5>
            </Modal.Header>
            <Modal.Body>
                <h5>
                To get your backup, click on "Backup" button and waite for the file download.
                </h5>
                <br/>
                <h5>Once it starts backing, you cant cancel the operation</h5>
            </Modal.Body>
            <Modal.Footer>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={11} className="to-right">
                                        <Button appearance="default" size="lg" block style={cancelButton} onClick={onClose} disabled={isLoading}>BACK</Button>    
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={1} > 

                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={12}>
                                        <Button color="red" appearance="primary" block size="lg" style={saveButton} onClick={onBackup} loading={isLoading}>
                                            BACKUP
                                        </Button>   
                </FlexboxGrid.Item>
            </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    )
}
export default BackupModal;