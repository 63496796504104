import React from "react";
import { Button, Checkbox, Col, Row,Grid } from "rsuite";
import './ClientDetail.css'
import HistoryIcon from '@rsuite/icons/History';
const ClientDetail = (props)=>
{
    const checker =
    {
        paddingTop : "15px",
        paddingLeft : "10px",
    }
    const onchange=(value,e)=>
    {
        props.onSelectionChanged(value,e);
    }
    return (
        <div className="client-detail">
        <Row>
            <Col xs={3}>
                <Checkbox style={checker} value = {props.client.id} checked={props.client.checked} onChange={onchange}  />
            </Col>
            <Col xs={17}>       
                    <p className="cn">{props.client.name}</p>
                    <p className="ph">{props.client.phone}</p>
                    <p className="ad">{props.client.adresse}</p>
            </Col>
            <Col xs={4} fluid>
                    <div>
                    <Button onClick={props.onUpdateClient} >
                        <img data-item={props.client.id} src="edit.svg" />
                    </Button>
                    </div>
                    <div>
                    <Button onClick={props.onDeleteClient}>
                        <img data-item={props.client.id} src="trash.svg"/>
                    </Button>
                    </div>
            </Col>
        </Row>
        </div>
    )
}
export default ClientDetail;