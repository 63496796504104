import React from "react";
import { Checkbox, FlexboxGrid, Row } from "rsuite";
import { Col } from "rsuite";
import { Button } from "rsuite";
import './TableDetails.css'
const OfferTableDetails =(props)=>
{
    const flexButton =(primary,info)=>
    {
        return (
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={20}>
                    {primary}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={4}>
                    {info}
                </FlexboxGrid.Item>
            </FlexboxGrid>
        )
    }
    const renderStateButton=(app)=>{
        if(app.typeOfferId===null||app.typeOfferId===undefined)
            return (<Button className="table-detail-state blured" style={{color:"#D8A311"}} size="sm" data-item={app.id} onClick={props.onUpdateClicked} block>Offer</Button>)
        else return (<Button className="table-detail-state blured" style={{color:app.typeOfferColor}} size="sm" data-item={app.id} onClick={props.onUpdateClicked} block>{app.typeOfferName}</Button>)
        // else if(value==="1")
        //     return (<Button className="table-detail-state blured" style={{color:"#46C6FF"}} size="sm" data-item={id} onClick={props.onPogressClicked} block>In Progress</Button>)
        // else if(value==="2")
        //     return (<Button className="table-detail-state blured" style={{color:"#59B20C"}} size="sm" data-item={id} onClick={props.onSolvedClicked} block>Solved</Button>)
        // else if(value==="3")
        //     return (<Button className="table-detail-state blured" style={{color:"#DD0E2D"}} size="sm" data-item={id} block>Canceled</Button>)
        // else if(value==="4")
        //     return (<Button className="table-detail-state blured" style={{color:"gray",background:"#ff6b5452"}} size="sm" data-item={id} onClick={props.onWaitingClicked} block>Reported</Button>)
        // else if(value==="5")
        //     return (<Button className="table-detail-state blured" style={{color:"#D8A311"}} size="sm" data-item={id} onClick={props.onUpdateClicked} block>Offer</Button>)
        // else if(value==="6")
        //     return (<Button className="table-detail-state blured" style={{color:"#46C6FF"}} size="sm" data-item={id} onClick={props.onUpdateClicked} block>Updated</Button>)
        }
   const renderAppDate=(app)=>
   {
        if(app.dateUpdate===null||app.dateUpdate===undefined)
            return (<><b >{new Date(app.date).timeToInput()}</b><p>{new Date(app.date).dateToInput()}</p></>)
        else
            return (<><b>{new Date(app.dateUpdate).timeToInput()}</b><p>{new Date(app.dateUpdate).dateToInput()}</p></>)

   }
    const onChange=(value,e)=>{
        props.onSelectionChanged(value,e);
    }
    const noPadding = {
        paddingRight:0,
        paddingLeft:0
    }
    return (
        <div className="table-container">
                        {props.appointments.map(app=>
                <Row className="table-detail rounded" style={noPadding}>
                    <Col xs={1} className="table-detail-checker"><Checkbox name="ch1" value={app.id} checked={app.checked}  onChange={onChange} /></Col>
                    <Col xs={4} className="table-detail-client-name to-ellipsis">{app.clientName}</Col>
                    <Col xs={3} className="to-ellipsis">{app.priority}</Col>
                    <Col xs={4} className='table-dtail-time to-ellipsis'>{renderAppDate(app)}</Col>
                    <Col xs={4} className="to-ellipsis">{app.clientAdresse}</Col>
                    <Col xs={3} className="table-detail-phone to-ellipsis">{app.clientPhone}</Col>
                    <Col xs={2} className="to-ellipsis">{app.sectionName}</Col>
                    <Col xs={2}>{renderStateButton(app)}</Col>
                    <Col xs={1} style={{maxWidth:"50px"}}><Button  size="sm" className="table-detail-state blured" data-item={app.id} onClick={props.onTimeLineClick}>i</Button></Col>
                </Row>
            )}
        </div>
    )
}
export default OfferTableDetails;