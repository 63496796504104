import jwt_decode from "jwt-decode";
export function IsUserLoggedIn()
{
    try
    {
        var token = localStorage.getItem("token");
        var decodedToken = jwt_decode(token);
        var currentDate = new Date();
        if (decodedToken.exp * 1000 < currentDate.getTime()) 
        {
            console.log("tokenExpired");
        }
        else 
        {
            console.log("token is alive");  
            return true;
        }
    }
    catch(ex)
    {
        
        return false;
    }
}