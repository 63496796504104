import { AlertHelper } from "../Helpers/AlertHelper";

export function IsHavingPrivilge(privilegeName)
{
    try
    {
        const claims = JSON.parse(localStorage.getItem("claims"));
        var filter = claims.filter(m=>m.name===privilegeName);
        if(filter.length===0)
        {
            return false;
        }
        else
        {
            if(filter[0].value==="true")
                return true;
            else
                return false;
        }
    }
    catch
    {
        return false;
    }
   
}

export function DoEventIfHavingPrivilge(privilegeName,action,showErrour)
{
    if(IsHavingPrivilge(privilegeName))
        action();
    else
    {
        if(showErrour===true)
        {
            AlertHelper.ShowErrour("Acccess Denied");
        }
    }
}
