import {Message, toaster} from 'rsuite';
import React from 'react';
export class  AlertHelper
{
    static ShowErrour(message)
    {
        var message = (<Message showIcon type='error' >{message} </Message>);
        toaster.push(message);
    }
    static ShowInfo(message)
    {
        var message = (<Message showIcon type='info' >{message} </Message>);
        toaster.push(message);
    }
    static ShowSuccess(message)
    {
        var message = (<Message showIcon type='success' >{message} </Message>);
        toaster.push(message);
    }
    static ShowWarning(message)
    {
        var message = (<Message showIcon type='warning' >{message} </Message>);
        toaster.push(message);
    }
}