import React, { Component } from 'react'
import { FlexboxGrid } from 'rsuite';
import Apis from '../../Helpers/Apis';
import { AuthorizationHeader } from '../../manager/AuthorizationHeader';
import './PrintAppointments.css'
export default class PrintAppointments extends Component
{
    constructor(props)
    {
        
        super(props);
        this.state={
            ids:null,
            appointments:[]
        }
        
    }
    readAppointments()
    {
        try
        {
            var items = JSON.parse(localStorage.getItem("AppointmentsToPrinting"));
            const newIds =[];
            items.forEach(element => {
                newIds[newIds.length] = element;
            });
            this.setState({ids:newIds},this.fetchData);
        }
        catch
        {
            this.props.history.push("/appointments");
        }
    }
    fetchData=()=>
    {
        fetch(Apis.FIND_APPOINTMENTs_BY_RANGE,
        {
            headers:AuthorizationHeader(),
            method:"POST",
            body:JSON.stringify(this.state.ids)
        })
        .then(reponse=>{if(!reponse.ok) throw new Error("err"); return reponse.json()})
        .then(data=>this.setState({appointments:data}))
        .catch(err=>alert(err));
    }
    componentDidMount()
    {
        document.getElementsByClassName("side-bar")[0].style.display="none";
        document.getElementsByClassName("header-div")[0].style.display="none";
        this.readAppointments();
        
     
    }
    componentDidUpdate()
    {
        if(this.state.appointments.length>0)
            window.print();
    }
    onBack=(e)=>
    {
        e.preventDefault();
        this.props.history.push("/appointments")
    }
    render()
    {
        return (
            <>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={12}>
                    <a href="/appointments" onClick={this.onBack}>
                    <img src="logo.svg" style={{height : "40px",marginBottom : "15px"}} />
                    </a>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                    <p style={{width:"100%", textAlign:"right",marginTop:"20px"}}>{new Date().dateToInput()}</p>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            
            <table className='printing-table'>
                <thead>
                    <tr>
                        <td></td>
                        <td>Client</td>
                        <td>Phone</td>
                        <td>Adresse</td>
                        <td>Date</td>
                        <td>Type</td>
                        <td>Description</td>
                        <td>Store</td>
                        <td>Observation</td>
                    </tr>
                </thead>
                <tbody>
                    {this.state.appointments.map(app=>
                    <tr key={app.id}>
                         <td><input type="checkbox" /></td>
                         <td>{app.clientName}</td>
                         <td>{app.clientPhone}</td>
                         <td>{app.clientAdresse}</td>
                         
                         <td className='printing-table-date'>{app.dateReport===null||app.dateReport===undefined? new Date(app.date).dateToInput() +" - "+ new Date(app.date).timeToInput():new Date(app.dateReport).dateToInput() +" - "+ new Date(app.dateReport).timeToInput()}</td>
                         <td>{app.typeAppointmentName}</td>
                         <td>{app.description}</td>
                         <td>{app.sectionName}</td>
                         <td></td>
                    </tr>
                        )}
                   
                </tbody>
            </table>
            </>
        )
    }
}