import React from "react";
import { Checkbox, Row } from "rsuite";
import { Col } from "rsuite";
import { Button } from "rsuite";
import './TableDetails.css'
const TableDetail =(props)=>
{   
    const onChange=(value,e)=>{
        props.onSelectionChanged(value,e);
    }
    const noPadding = {
        paddingRight:0,
        paddingLeft:0
    }
    return (
        <div className="employee-table-container">
                        {props.employees.map(emp=>
                <Row className="employee-table-detail rounded" style={noPadding}>
                    <Col xs={1} className="employee-table-detail-checker"><Checkbox name="ch1" value={emp.id} checked={emp.checked}  onChange={onChange} /></Col>
                    <Col xs={8} className="employee-table-detail-client-name">{emp.name}</Col>
                    <Col xs={15} className="employee-table-detail-phone">{emp.fonctionName}</Col>
                    
                </Row>
            )}
        </div>
    )
}
export default TableDetail;