import React, { Component } from "react";
import Apis from '../../Helpers/Apis';
import { AuthorizationHeader } from '../../manager/AuthorizationHeader';
import { Button, Row,Col,FlexboxGrid } from "rsuite";
import './PrintClientHistory.css'
export class PrintClientHistory extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
            clientId:null,
            ids:null,
            client :{},
            appointments : []
        }
    }
    componentDidMount()
    {
        document.getElementsByClassName("side-bar")[0].style.display="none";
        document.getElementsByClassName("header-div")[0].style.display="none";
        this.readAppointments();
    }
    componentDidUpdate()
    {
        if(this.state.appointments.length>0)
            window.print();
    }
    readAppointments=()=>
    {
        try
        {
            const item = JSON.parse(localStorage.getItem("ClientHistoryToPrinting"));
            this.setState({
                clientId:item.clientId,
                ids:item.ids
            },this.fetchData);
        }
        catch
        {
            this.props.history.push("/clients");
        }
    }
    fetchData=()=>
    {
        fetch(Apis.FIND_CLEINT+"?id="+this.state.clientId,{headers:AuthorizationHeader()})
        .then(reponse=>{if(!reponse.ok) throw new Error("err"); return reponse.json()})
        .then(data=>this.setState({client:data}))
        .catch(err=>alert(err));

        fetch(Apis.FIND_APPOINTMENTs_BY_RANGE,
        {
            headers:AuthorizationHeader(),
            method:"POST",
            body:JSON.stringify(this.state.ids)
        })
        .then(reponse=>{if(!reponse.ok) throw new Error("err"); return reponse.json()})
        .then(data=>this.setState({appointments:data}))
        .catch(err=>alert(err));
    }
    handleTime=(date)=>
    {
        
        if(date===null)
        {
            return "Not definded yet"
        }
        else
        {
            return new Date(date).toLocaleString();
        }
    }
    render(){
    return(
        <div className="print-client-history">
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={16}>
                    <a href="/clients" onClick={this.onBack}>
                    <img src="logo.svg" style={{height : "40px",marginBottom : "15px"}} />
                    </a>
                    <table style={{marginTop:"10px",marginBottom:"10px"}}>
                        <tbody>
                            <tr>
                                <td style={{width:"100px"}}>Client's Name</td>
                                <td>{this.state.client.name}</td>
                            </tr>
                            <tr>
                                <td style={{width:"100px"}}>Phone</td>
                                <td>{this.state.client.phone}</td>
                            </tr>
                            <tr>
                                <td style={{width:"100px"}}>Adresse</td>
                                <td>{this.state.client.adresse}</td>
                            </tr>
                       
                            <tr>
                                <td style={{width:"100px"}}>Date Creation</td>
                                <td>{new Date(this.state.client.dateCreated).dateToInput()}</td>
                            </tr>
                        </tbody>
                    </table>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={8}>
                   <p className="to-right">Date : {new Date().dateToInput()}</p>
                </FlexboxGrid.Item>
            </FlexboxGrid>
             {this.state.appointments.map(app=>
        <div className="print-client-history-detail">
            <p>{new Date(app.date).getDate()+" "+new Date(app.date).toMonthName()+" "+new Date(app.date).getFullYear()} <b>{new Date(app.date).timeToInput()}</b></p>
            <Row>
            <Col xs={12}>
                <table>
                <tbody>
                    <tr>
                        <td>
                            Designation :
                        </td>
                        <td>
                            {app.name}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Responsable :
                        </td>
                        <td>
                            {app.responsable}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Agent :
                        </td>
                        <td>
                            {app.agent}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Verificateur :
                        </td>
                        <td>
                            {app.verificateur}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            N° Bon :
                        </td>
                        <td>
                            {app.bonSortie}
                        </td>
                    </tr>
                </tbody>
            </table>
                </Col>
                <Col xs={12}>
                <table>
                <tbody>
                    <tr>
                        <td>
                            Waiting Date :
                        </td>
                        <td>
                            {this.handleTime(app.dateWatiting)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Progress Date :
                        </td>
                        <td>
                        {this.handleTime(app.dateProgress)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Finish Date :
                        </td>
                        <td>
                        {this.handleTime(app.dateFinish)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Cancel Date :
                        </td>
                        <td>
                        {this.handleTime(app.dateCancel)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Cancel Reason :
                        </td>
                        <td>
                            {app.cancelReason}
                        </td>
                    </tr>
                </tbody>
            </table>
                </Col>

  </Row>
  
        </div>
             )}
           
        </div>
    )
}
}
