import React from 'react';
import {Button, FlexboxGrid, Modal} from 'rsuite';
const DeleteConfirmation =(props)=>
{
    const saveButton=
    {
        color: "white",
        fontsize:16,
        fontWeight : "bold"
    }
    const cancelButton=
    {
        color :"#89919B",
        backgroundColor : "#D2D2D2",
        fontWeight : "bold"
    }
    return(
        <Modal open={props.isDeleteModalVisible} onClose={props.onClose} className="custom-modal" backdrop="static">
            <Modal.Header>
                <h5>Conrimation</h5>
            </Modal.Header>
            <Modal.Body>
                <h4>Are you sure you want to delete this {props.title}</h4>
            </Modal.Body>
            <Modal.Footer>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={11}>
                        <Button style={cancelButton} block color="gray" size="lg" onClick={props.onClose}>Back</Button>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={1}>
                        
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={12}>
                        <Button style={saveButton} block color="red" size="lg" appearance='primary' onClick={props.onDelete}>Confirm</Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    )
}
export default DeleteConfirmation;