import React,{ Component, useEffect } from "react";
import { IconButton,Drawer } from "rsuite";
import Button from 'rsuite/Button';
import { useHistory, withRouter  } from 'react-router-dom';
import SettingsModal from '../../Settings/SettingsModal';
import { DoEventIfHavingPrivilge, IsHavingPrivilge } from "../../../manager/IsHavingPrivilege";
import UpdateUserClaimsModal from "../../Users/Modals/UpdateUserClaimsModal";
import BackupModal from "../../Maintenances/BackupModal";
import RestoreModal from "../../Maintenances/RestoreModal";
import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import PageNextIcon from '@rsuite/icons/PageNext';
import {ExpandHelper} from '../../../Helpers/ExpandHelper';
import {ReactComponent as Logo} from '../../svgs/logo.svg';
import {ReactComponent as TechPos} from '../../svgs/techpos.svg';
import { AlertHelper } from "../../../Helpers/AlertHelper";
import CreateUpdateInvoiceModal from "../../Invoices/Modals/CreateUpdateInvoiceModal";
import ValidateInvoiceProducts from "../../Invoices/Modals/ValidateInvoiceProducts";
import ToWaitingModal from "../../Appointments/Modals/ToWaitingModal";
const SidebarDiv =(props)=>
{
    // constructor(props)
    // {
    //     super(props);
    //     this.onButtonClick = this.onButtonClick.bind(this);
    // }
    const boolConver =(value)=>
    {
        return 
    }
    useEffect(()=>{
        
        var aside = document.getElementsByTagName("aside")[0];
        aside.style.width = "69px";
        if(isExpended===false)
        {
            aside.style.width = "69px";
        }
        else 
        {
            aside.style.width = "260px"
        }
    })
  
    const [isSettingsOpen,setIsSettingsOpen] = React.useState(false);
    const [isExpended,setIsExpended] = React.useState(JSON.parse(ExpandHelper.Read()));
    const invoiceRef = React.useRef();
    
    const changeExpanded=(e)=>
    {
        setIsExpended(e);
        ExpandHelper.Write(e);
    }
    const onSettingsClose=()=>
    {
        setIsSettingsOpen(false);
    }
    const toggleSideNav=()=>
    {
        changeExpanded(!isExpended);
        var aside = document.getElementsByTagName("aside")[0];
        if(isExpended===false)
            aside.style.width = "69px";
        else 
            aside.style.width = "260px"
    }
    const history = useHistory();
    const onButtonClick=(e)=>
    {
        var elements = document.getElementsByName("bnav");
        elements.forEach(m=>m.className = m.className.replace("gradian white-text",""));
        e.target.className=e.target.className+" gradian white-text";
        if(e.target.id==="ap")
        {
            history.push("/appointments");
        }
        else if(e.target.id==="cal")
            history.push("/calendar");
        else if(e.target.id==="cl")
            history.push("/clients");
        else if(e.target.id==="sv")
            history.push("/solved");
        else if(e.target.id==="em")
            history.push("/employees");
        else if(e.target.id==="st")
            history.push("/stores");
        else if(e.target.id==="sc")
            history.push("/scores");
        else if(e.target.id==="us")
            history.push("/users");
            else if(e.target.id==="of")
            history.push("/offers");
        else if(e.target.id=="s")
        {

        }
    }
    
    const logo = <Logo />
    const techPos = <TechPos style={{maxHeight:"50px"}} />
    const icon = <svg src="alert.svg"/>
 
        return (
           <>
           <div className="slider-div">
                <IconButton icon={isExpended===true? (<PagePreviousIcon />):(<PageNextIcon />)}  color="orange" appearance="primary" circle onClick={toggleSideNav}/>
           </div>
           {IsHavingPrivilge("CLIENT_ADD")}
           <div className="side-bar-logo">
               {isExpended===true?logo:techPos}
               {/* <img src={isExpended===true?  "logo.svg":"techpos.svg"} /> */}
           </div>
           <div className="side-bar-body">
                <Button color="red" appearance="subtle" block className="blured" id="of" name="bnav" onClick={onButtonClick}>
                    <img src="appointment.svg" />
                    Offers
                </Button>
                <Button color="red" appearance="subtle" block className="blured" id="ap" name="bnav" onClick={onButtonClick}>
                    <img src="appointment.svg" />
                    Appointments
                </Button>
                <Button color="red"  appearance="subtle" block className="blured" id="cal" name="bnav" onClick={onButtonClick}>
                    <img src="calendar.svg" />
                    Calendar
                </Button>
                <Button color="red" appearance="subtle" block className="blured" id="sv" name="bnav" onClick={onButtonClick}>
                    <img src="solved.svg" />
                    Solved
                </Button>
                <Button color="red" appearance="subtle" block className="blured" id="cl" name="bnav" onClick={onButtonClick}>
                    <img src="clients.svg" id="cl" />
                    Clients
                </Button>
           </div>
           <div className="side-bar-footer">
               <Button color="red"  appearance="subtle" block onClick={(e)=>setIsSettingsOpen(true)}>
                    <img src="Settings.svg" onClick={(e)=>setIsSettingsOpen(true)}/>
                    Settings
               </Button>
           </div>
           <SettingsModal isSettingsOpen={isSettingsOpen} onClose = {onSettingsClose} />
           {/* <ToWaitingModal /> */}
            <ValidateInvoiceProducts />
           {/* <CreateUpdateInvoiceModal ref={invoiceRef}/> */}
        
           </>
        )
    
}
export default SidebarDiv;