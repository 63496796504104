import React, {Component} from 'react';
import ToolBar from '../Common/ToolBar';
import TableDetail from './Components/TableDetail';
import TableHeader from './Components/TableHeader';
import CreateUpdateScoreModal from './Modals/CreateUpdateScoreModal'
import { AuthorizationHeader } from '../../manager/AuthorizationHeader';
import DeleteConfirmation from '../Common/DeleteConfirmation';
import Apis from '../../Helpers/Apis';
import { DoEventIfHavingPrivilge } from '../../manager/IsHavingPrivilege';
import { AlertHelper } from '../../Helpers/AlertHelper';
export default class FetchScores extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
            scoreSettingId : 0,
            IsCreateUpdateModalOpen : false,
            isDeleteModalVisible : false,
            scoreSettings : []
        }
    }
    componentDidMount()
    {
        // document.getElementsByClassName("side-bar")[0].style.display="initial";
        // document.getElementsByClassName("header-div")[0].style.display="initial";
        // document.getElementById("title").innerText = "Score Settings";
        // document.getElementById("title").style.color = "#DD0E2D";
        this.fetchScoreSettings();
    }
    fetchScoreSettings(){
         fetch(Apis.LIST_SCORES,{headers:AuthorizationHeader()})
        .then(response => response.json())
        .then(data=>{data.forEach(m=>{m.checked=false});this.setState({scoreSettings:data})});
    }
    on
    onModalCreateScoreSetting=()=>
    {
        DoEventIfHavingPrivilge("SCORE_ADD",()=>{
        this.setState({scoreSettingId:0,IsCreateUpdateModalOpen:true});
        },true)
    }
    onModalUpdateScoreSetting=()=>
    {
        DoEventIfHavingPrivilge("SCORE_EDIT",()=>{
        const all = this.state.scoreSettings.filter(m=>m.checked===true);
        if(all.length===0)
        {
            AlertHelper.ShowWarning("No Score is selected");
            return;
        }
        else if(all.length>1)
        {
            AlertHelper.ShowWarning("only one item can be updated");
            return;
        }
        this.setState({scoreSettingId:all[0].id,IsCreateUpdateModalOpen:true});
        },true)
    }
    onModalDeleteScoreSetting=()=>
    {
        DoEventIfHavingPrivilge("SCORE_DELETE",()=>{
        const selected = this.state.scoreSettings.filter(m=>m.checked===true);
        if(selected.length===0)
        {
            AlertHelper.ShowWarning("No Score is selected");
            return ;
        }

        this.setState({isDeleteModalVisible:true});
        },true)
    }
    onCloseDeleteModal=()=>
    {
        this.setState({isDeleteModalVisible:false});
    }
    onDelete=()=>
    {
        const selected = this.state.scoreSettings.filter(m=>m.checked===true);
        if(selected.length===0)
            return;
        var ids = [];
        for(var i=0 ; i<selected.length;i++)
        {
            ids[i] = selected[i].id;
        }
        fetch(Apis.DELETE_SCORE,
        {
            method :"DELETE",
            headers : AuthorizationHeader(),    
            body :JSON.stringify(ids)
        })
        .then(response=>{if(!response.ok) throw new Error(response) ; return response})
        .then(data=>this.setState({isDeleteModalVisible:false},this.fetchScoreSettings))
        .catch(err=>AlertHelper.ShowErrour("Something went wrong"));
    }
    onCloseCreateUpdateModal=()=>
    {
        this.setState({IsCreateUpdateModalOpen : false});
    }
    onSave=()=>
    {
        this.setState({IsCreateUpdateModalOpen : false},this.fetchScoreSettings);
    }
    onSelectAll=(value)=>
    {
        const all = this.state.scoreSettings;
        all.forEach(element => {
            element.checked=value
        });
        this.setState({scoreSettings:all});
    }
    onSelectionChanged =(value,e)=>
    {
        const all = this.state.scoreSettings;
        var selected = all.filter(m=>m.id===value)[0];
        var index = all.indexOf(selected);
        all[index].checked=e;
        this.setState({scoreSettings:all});
    }
    renderTableDetails()
    {
        return(
            // <Grid fluid>
                <TableDetail scoreSettings = {this.state.scoreSettings} onSelectionChanged={this.onSelectionChanged} />
            // </Grid>
        )
    }
    onRefrech=()=>{
        this.fetchScoreSettings();
    }
    render()
    {
        let content = this.renderTableDetails();
        return(
        
        <>
            <ToolBar onRefrechClick={this.onRefrech} onCreateClick = {this.onModalCreateScoreSetting} onUpdateClick={this.onModalUpdateScoreSetting} onDeleteClick={this.onModalDeleteScoreSetting} />
            <TableHeader onSelectAll = {this.onSelectAll}   />
            {content}
            <CreateUpdateScoreModal id={this.state.scoreSettingId} isModalOpen={this.state.IsCreateUpdateModalOpen} onSave={this.onSave} onCloseModal={this.onCloseCreateUpdateModal} />
            <DeleteConfirmation title="ScoreSetting" isDeleteModalVisible = {this.state.isDeleteModalVisible} onClose={this.onCloseDeleteModal} onDelete={this.onDelete} />
        </>
        )
    }
}